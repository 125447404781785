import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
  ListItemText,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import FormHelperText from '@mui/material/FormHelperText';

import { Controller } from "react-hook-form"
import {useForm} from './FormProvider';

import {listLabel} from '../../utils/lists';
import { useState } from "react";


/*
requires the value to be an array on initialization
*/

export default function FormMultiSelectChips({
  name,
  enableSelectAll,
  enableSelectNone,
  label,
  list,
  required,
  rules = {},
  fullWidth,
  ...props
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [noOptionsSelected, setNoOptionsSelected] = useState(false)
  const { control, errors, defaultRules, setValue } = useForm();

  const requiredDefaultRule = required ? { required: defaultRules['required'] } : {};
  rules = { ...requiredDefaultRule, ...rules };

  fullWidth = fullWidth === undefined ? true : fullWidth;

  const labelId = name + "-label";

  return (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => {
        return (
          <FormControl fullWidth error={errors[name] ? true : false}>
            <InputLabel id={labelId} required={required}>
              {label}
            </InputLabel>
            <Select
              multiple
              labelId={labelId}
              onBlur={onBlur}
              value={value}
              onChange={(e) => {
                setNoOptionsSelected(false);
                if(e.target.value.includes('All')){
                  if (!selectAll) {
                    const allValues = list.map((cur) => cur.value);
                    onChange(allValues);
                  } else {
                    onChange([]);
                  }
                  setSelectAll(!selectAll);
                }
                else{
                  if(e.target.value.includes('All')){
                    onChange([]);
                    setSelectAll(!selectAll);
                  }
                  if (enableSelectAll && e.target.value.length === list.length) {
                    setSelectAll(true);
                  }
                  console.log(e.target.value)
                  if(e.target.value.includes('None')){
                    setNoOptionsSelected(true)
                    onChange(['None']);
                  }
                  else{
                    onChange(e.target.value);
                  }
                }
              }}
              input={<OutlinedInput label={label} />}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((cur) => (
                    <Chip
                      key={cur}
                      label={listLabel(cur, list)}
                      onDelete={() =>{
                        setNoOptionsSelected(false);
                        setValue(
                          name,
                          value.filter((item) => item !== cur)
                          )
                        }
                      }
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Stack>
              )}
            >
              {enableSelectAll && (
                <MenuItem  key={'All'}
                value={'All'} 
                disabled={noOptionsSelected}
                >
                  <ListItemText
                    primary={value.length===list.length?"Unselect All":"Select all"}
                  />
                </MenuItem>
              )}
              {enableSelectNone && (
                <MenuItem  key={'None'}
                value={'None'} 
                >
                  <ListItemText
                    primary={"No Elephants Nearby"}
                  />
                </MenuItem>
              )}
              {list.map((cur) => (
                <MenuItem
                  disabled={noOptionsSelected}
                  key={cur.value}
                  value={cur.value}
                  sx={{ justifyContent: "space-between" }}
                >
                  {cur.label}
                  {value.includes(cur.value) ? <CheckIcon color="info" /> : null}
                </MenuItem>
              ))}
            </Select>
            {errors[name] && (
              <FormHelperText>{errors[name]?.message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
}
