import * as React from 'react';
import {useForm} from './FormProvider';

import {useWatch} from "react-hook-form"

import _ from 'lodash';

export default function FormOnChange({children,...props}) {
    const {handleSubmit,onSubmit,onChange, control} = useForm();

    const [formValues, setFormValues] = React.useState({});

    // allows each change of a value to be handled by the onChange handler function
    const currentValues = useWatch({
        control,
        name: undefined
    });

    if (_.isEqual(currentValues,formValues) === false) {
        setFormValues(currentValues);
        console.log("form changed");
        onChange(currentValues);
    }

    const contextFunc = (onSubmit) ? handleSubmit(onSubmit) : null;

    return (
        <form onSubmit={contextFunc} noValidate {...props}>
            {children}
        </form>
    )
}