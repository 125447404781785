const allOpenChanges = (changes) => {
    const list = changes.filter((cur)=>{
		return cur.is_resolved === false;
    });
    return list;
}

const allElephantChanges = (elephant,changes) => {
    const changesForElephant = changes.filter((cur)=>{
        return cur.elephants.includes(elephant.id);
    });
    return changesForElephant;
}

const allOpenElephantChanges = (elephant,changes) => {
    const openChangesForElephant = changes.filter((cur)=>{
        return cur.is_resolved === false && cur.elephants.includes(elephant.id);
    });
    return openChangesForElephant;
}

const sortDescStart = (a,b) => {
    return b.start.valueOf() - a.start.valueOf();
}

const sortAscStart = (a,b) => {
    return a.start.valueOf() - b.start.valueOf();
}

export {allOpenChanges, allElephantChanges, allOpenElephantChanges, sortDescStart, sortAscStart};