import * as React from 'react';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDropDown from '../components/form/FormDropDown';
import FormDatePicker from '../components/form/FormDatePicker';
import FormConditionCheck from '../components/form/FormConditionCheck';
import FormMultiSelectChips from '../components/form/FormMultiSelectChips';

import { useQuery } from '@tanstack/react-query'
import {useAuth} from '../context/AuthProvider';
import {useMessage} from '../context/MessageProvider';

import {categoryList, typeList, statusList} from '../models/categories';
import { listElephants } from '../models/elephants';
import { newChange, saveChange } from '../models/changes';

import { useConnectivity } from '../context/ConnectivityProvider';

import Header from './Header';
import { useRevalidator } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 12,
  borderRadius: "4px",
  p: 2,
};

export default function ChangeModal({open,dispatch,defaults}) {
    const handleClose = () => dispatch(false);
    const revalidator = useRevalidator();
    const {setSuccess } = useMessage();
    const {facilityId, user} = useAuth();
    const {isOnline} = useConnectivity();

    const {isLoading, isError, data, error} = useQuery({
        queryKey: ['changes'],
        queryFn: async() => {
            return {
                categories: await categoryList(),
                types: await typeList(),
                statuses: await statusList(),
                elephants: await listElephants(),
            };
        },
        networkMode: "offlineFirst",
    });


    const onSubmit = async(data) => {
        console.log(data);
        const results = await saveChange(data,facilityId,user.id,isOnline);
        console.log("save results: ", results);
        setSuccess("Change Saved");
        revalidator.revalidate();
    }

    const onSubmitAndClose = async(data) => {
        await onSubmit(data);
        handleClose();
    }

    if (isError) throw error;
    
    const defaultValues = newChange();
    if (defaults.elephant) {
        defaultValues.elephants.push(defaults.elephant.id);
    }

    return ( 
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="changes-modal-title"
      aria-describedby="changes-modal-description"
    >
        <Container maxWidth="xs" sx={style}>
            {isLoading && <div>Loading</div>}
            {!isLoading && <>
                <Header label="Report Change" />
                <FormProvider resetAfterSubmit defaultValues={defaultValues} >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={6}> 
                                <FormDropDown name="category" label="Category" list={data.categories} required />
                            </Grid>

                            <Grid xs={12} sm={6}> 
                                <FormDropDown name="change_type" label="Type" list={data.types} required />
                            </Grid>

                            <Grid xs={12} sm={12}> 
                                <FormDropDown name="status" label="Status" list={data.statuses} required />
                            </Grid>

                            <FormConditionCheck name="status" value="single_event" >
                                <Grid xs={12} sm={12}> 
                                    <FormDatePicker name="start" label="Start" required />
                                </Grid>
                            </FormConditionCheck>

                            <FormConditionCheck name="status" value="discrete_period" >
                                <Grid xs={6} sm={6}> 
                                    <FormDatePicker name="start" label="Start" required />
                                </Grid>

                                <Grid xs={6} sm={6}> 
                                    <FormDatePicker name="end" label="End" required />
                                </Grid>
                            </FormConditionCheck>

                            <FormConditionCheck name="status" value="ongoing" >
                                <Grid xs={12} sm={12}> 
                                    <FormDatePicker name="start" label="Start" required />
                                </Grid>
                            </FormConditionCheck>

                            <Grid xs={12} sm={12}> 
                                <FormTextField name="description" label="Describe the details of the change" multiline minRows="2" required />
                            </Grid>

                            <Grid xs={12} sm={12}> 
                                <FormMultiSelectChips enableSelectAll name="elephants" label="Elephants Impacted" required list={data.elephants.map((cur)=>{ return {label: cur.name, value: cur.id} })}/>
                            </Grid>

                            <Grid xs={12}>
                                <FormButton onSubmit={onSubmitAndClose} fullWidth>Submit and close</FormButton>
                            </Grid>
                            <Grid xs={12}>
                            <FormButton onSubmit={onSubmit} fullWidth>
                                Submit and Reset Form
                            </FormButton>
                            </Grid>
                        </Grid>
                    </Form>
                </FormProvider>
            </>}


        </Container>

    </Modal>
  );

}
/*

                            <FormButton resetOnSubmit onSubmit={handleSaveAndReset} fullWidth>
      <Box sx={style}>
        <Typography id="changes-modal-title" variant="h6" component="h2">
          Text in a modal
        </Typography>
        <Typography id="changes-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography>
      </Box>
      */