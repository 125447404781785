import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import {useParams, useLoaderData } from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDatePicker from '../components/form/FormDatePicker';

import Header from "../components/Header";
import Subheader from "../components/Subheader";



import {date} from "../utils/datetime";
import { listLabel } from '../utils/lists';


import {categoryList, typeList, statusList} from '../models/categories';
import {saveChange,getChange} from '../models/changes';

import { useConnectivity } from '../context/ConnectivityProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    console.log("loader params",params);
    const change = await getChange(params.changeId);
    return {
        change,
        categories: await categoryList(),
        types: await typeList(),
        statuses: await statusList(),
    };
}


export default function ChangeResolvePage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const {facilityId, user} = useAuth();
    const {isOnline} = useConnectivity();
    const params = useParams();
    const navigate = useNavigate();
    console.log(params);

    // used to read the loader data that is returned from the function above
    const {change,categories,types,statuses} = useLoaderData(); 

    //const timeframe = (change.start && change.end) ? date(change.start) + " - " + date(change.end) : "Started " + date(change.start);

    // note you can play with the container maxwidth to spread out the window content appears in

    const onSubmit = async (data) => {
        try {

        console.log(data);
        data.is_resolved = true;
        const results = await saveChange(data,facilityId,user.id,isOnline);
        console.log("save results: ", results);
        //navigate("/changes/" + change.id);
        navigate(-1);
        setSuccess("Change Marked As Resolved");

        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            <Subheader label="Details" />
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Category</Typography>    
                    <Typography variant="body2">{listLabel(change.category,categories)}</Typography>    
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Type</Typography>    
                    <Typography variant="body2">{listLabel(change.change_type,types)}</Typography>    
                </Grid>

                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Status</Typography>    
                    <Typography variant="body2">{listLabel(change.status,statuses)}</Typography>    
                </Grid>

                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Started</Typography>    
                    <Typography variant="body2">{date(change.start)}</Typography>
                </Grid>
            </Grid>


            <Subheader label="Description" />
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={12}> 
                    <Typography variant='body2'>{change.description}</Typography>
                </Grid>
            </Grid>


            {(change.is_resolved) ? (
                <Typography color="error">This is already resolved</Typography>
            ) : (
            <FormProvider onSubmit={onSubmit} defaultValues={change} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} > 
                            <FormDatePicker name="end" label="End Date"/>
                        </Grid>
                        <Grid xs={12} > 
                            <FormTextField name="resolved_information" label="Resolution Information" multiline minRows="2" />
                        </Grid>

                        <Grid xs={12}>
                            <FormButton>Mark Change As Resolved</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>
            )}
        </Container>
    );
}