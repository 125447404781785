import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import {today, syncvalue} from "../utils/datetime";
import dayjs from 'dayjs';
import { syncRecordsets } from '../utils/sync';



const template = () => {
    const record = {
        "id": "",
        "facility_id": "",
        "user_id": "",
        "category": "",
        "change_type": "",
        "status": "",
        "start": today(),
        "end": "",
        "is_resolved": false,
        "resolved_information": "",
        "description": "",
        "elephants": []
    }
    return {...record};
}

//{{category: '', change_type: '', status: '', start: today(), end: null, description: '', elephants: []}}

const transformFromDb = (inputRecord) => {

    const {elephants_changes,...changes} = inputRecord;
    const record = template();

    const elephantIds = elephants_changes.map((cur)=>{
        return cur.elephant_id;
    });

    const temp = {...record,elephants: elephantIds,...changes};

    //if (temp['start']) temp['start'] = dayjs(temp['start']);
    //if (temp['end']) temp['end'] = dayjs(temp['end']);

    temp['start'] = (temp['start']) ? dayjs(temp['start']) : "";
    temp['end'] = (temp['end']) ? dayjs(temp['end']) : "";

    console.log(temp);
    return temp;
};

const transformToDb = (inputRecord) => {

    const {created_at,updated_at,elephants,...remaining} = inputRecord;

    //if (remaining['start']) remaining['start'] = remaining['start'].toDate();
    //if (remaining['end']) remaining['end'] = remaining['end'].toDate();

    remaining['start'] = (remaining['start']) ? remaining['start'].toDate() : null;
    remaining['end'] = (remaining['end']) ? remaining['end'].toDate() : null;

    return {elephantIds: elephants, change: remaining};
}


const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    if (temp['start'] instanceof dayjs) temp['start'] = temp['start'].toDate();
    if (temp['end'] instanceof dayjs) temp['end'] = temp['end'].toDate();
    return temp;
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    if (temp['start'] instanceof Date) temp['start'] = dayjs(temp['start']);
    if (temp['end'] instanceof Date) temp['end'] = dayjs(temp['end']);
    return temp;
}


const newChange = () => {
    return template();
}

// fetchs records from the DB
const fetchChanges = async() => {
    try {
        const { data, error } = await supabase
            .from('changes')
            .select('*, elephants_changes (elephant_id)');
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {
            return data.map((cur)=>transformFromDb(cur));
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}

const fetchChangesBySync = async(lastSync) => {
    try {
        const { data, error } = await supabase
            .from('changes')
            .select('*, elephants_changes (elephant_id)')
            .gte('sync',lastSync)
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {
            return data.map((cur)=>transformFromDb(cur));
        }
    } catch (error) {
        console.error(error);
        throw error
    }
}



const listChanges = async () => {
    try {

        const temp = await db().changes.toCollection().toArray();
        return temp.map((cur) => {
            return fromDexie(cur);
        });
    } catch (error) {
        console.error(error);
        throw error
    }
}

const listChangesBySync = async (lastSync) => {
    try {

        const temp = await db().changes.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur) => {
            return fromDexie(cur);
        });
    } catch (error) {
        console.error(error);
        throw error
    }
}

const getChange = async(id) => {
    try {
        const record = await db().changes.where('id').equals(id).first();
        return fromDexie(record);
    } catch (error) {
        console.error(error);
        throw error
    }
}

// this is just for writing a complete record to dexie
const writeChangeToDexie = async (inputRecord) => {
    const cleanRecord = toDexie(inputRecord);
    const id = await db().changes.put(cleanRecord);
    console.log("added ",id);
    return id;
}

// this is just for writing a complete record to supabase
const writeChangeToSupabase = async(inputRecord) => {
        const {change, elephantIds} = transformToDb(inputRecord);

        const changeResult = await supabase.from('changes').upsert(change);
        const rpcResult = await supabase.rpc('update_change_elephants', {input_cid: change.id, input_eids: elephantIds});

        console.log(changeResult);
        console.log(rpcResult);
        if (changeResult.error) throw new Error(`Error ${changeResult.error.code}: ${changeResult.error.message}`);
        if (rpcResult.error) throw new Error(`Error ${rpcResult.error.code}: ${rpcResult.error.message}`);
        return {changeResult,rpcResult, id: change.id};
};

// this is called by an operation that changes the record in some way
// creating a new record or updating values in an existing one.
// this establishes a new sync value too
const saveChange = async (inputRecord, facilityId, userId, isOnline) => {
    try {

        const syncValue = syncvalue();

        inputRecord.id = inputRecord.id || uuidv4();
        inputRecord.facility_id = facilityId;
        inputRecord.user_id = userId;
        inputRecord.sync = syncValue;

        const dexieResults = await writeChangeToDexie(inputRecord);
        const supabaseResults = (isOnline) ? await writeChangeToSupabase(inputRecord) : {status: "offline"};
        return {dexieResults,supabaseResults};

    } catch (error) {
        console.error(error);
        throw error;
    }

}

const sync = async({facilityId,userId, lastSync = null}) => {

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({remoteData,localData, remoteWriteFunc: writeChangeToSupabase, localWriteFunc: writeChangeToDexie});
    return results;
}

const syncFullRecordSets = async () => {
    const remoteData = await fetchChanges() || [];
    const localData = await listChanges() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchChangesBySync(lastSync) || [];
    const localData = await listChangesBySync(lastSync) || [];

    return {remoteData, localData};
};


const truncateDexie = async() => {
    return await db().changes.clear();
}

export {listChanges, saveChange, newChange, sync, getChange, truncateDexie};