import React from 'react';
import './App.css';

import theme from './themes/defaultTheme';
import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider } from '@mui/material/styles';
import AppReady from "./components/AppReady";
import {MessageProvider} from './context/MessageProvider.js';
import {AuthProvider} from './context/AuthProvider';
import {ConnectivityProvider} from './context/ConnectivityProvider';
import { ConfirmationDialogProvider } from './context/ConfirmationDialogContext';


import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import SyncLoader from './components/SyncLoader';
import { SyncProvider } from './context/SyncingProvider';
import { TimerProvider } from './context/TimerProvider';
import { SectionBProvider } from './context/SectionBProvider';

const queryClient = new QueryClient()

console.log("App Name: %s %s",process.env.REACT_APP_NAME, process.env.REACT_APP_VERSION);
console.log("Git Hash: %ss",process.env.REACT_APP_GIT_HASH);

function App() {


    return (
      <ThemeProvider theme={theme}>
        <MessageProvider>
          <QueryClientProvider client={queryClient}>
            <ConnectivityProvider>
              <AuthProvider>
                <SyncProvider>
                  <ConfirmationDialogProvider>
                    <TimerProvider>
                      <SectionBProvider>
                        <SyncLoader />
                        <CssBaseline />
                        <AppReady />
                      </SectionBProvider>
                    </TimerProvider>
                  </ConfirmationDialogProvider>
                </SyncProvider>
              </AuthProvider>
            </ConnectivityProvider>
            <ReactQueryDevtools initialIsOpen={false} position="top-right" />
          </QueryClientProvider>
        </MessageProvider>
      </ThemeProvider>
    );


}

export default App;
