import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2




import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormRadioGroup from '../components/form/FormRadioGroup';
import FormDropDown from '../components/form/FormDropDown';
import FormMultiSelectChips from '../components/form/FormMultiSelectChips';
import FormSlider from '../components/form/FormSlider';
import FormDateTimePicker from '../components/form/FormDateTimePicker';
import FormChildDataGrid from '../components/form/FormChildDataGrid';
import FormButton from '../components/form/FormButton';

import { v4 as uuidv4 } from 'uuid';

import {useParams} from 'react-router-dom';


export default function TestFormPage({title = "EWP",...props}) {

    React.useEffect( ()=> {document.title = title}, [title]);

    const params = useParams();
    console.log(params);

    const onSubmit = (data) => console.log("submit data: ",data);

    return (
        <>
            <Typography variant="h4">Test Form Page</Typography>

            <FormProvider onSubmit={onSubmit} defaultValues={{firstname: '',lastname:'', gender: '',mylist: '', elephants: [], slide1: 5, ts: null, child:[], altlist: []}}>
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12}> 
                            <FormTextField name="firstname" label="First Name" required rules={{maxLength: {value: 10, message: "Too Long!"}}} />
                        </Grid>
                        <Grid xs={12}>
                            <FormTextField name="lastname" label="Last Name" />
                        </Grid>
                        <Grid xs={12}>
                            <FormChildDataGrid 
                                name="child" 
                                label="data grid" 
                                newRow={() => {
                                    return {
                                        id: uuidv4(),
                                        description: '',
                                    };
                                }}
                                columns={[
                                    { 
                                        field: 'description', 
                                        headerName: 'Description', 
                                        valueGetter: ({ row }) => {
                                            return row.description;
                                        },
                                    },
                                ]}
                                formRender={(defaultValues,onSubmit,onCancel)=>{
                                    return (
                                    <FormProvider defaultValues={defaultValues} >
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="description" label="Description" required />
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                                                </Grid>
                                            </Grid>
              
                                        </Form>
                                    </FormProvider>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <FormRadioGroup name="gender" list={[{label: "Male", value: "male"},{label: "Female", value: "female"}]} />
                        </Grid>

                        <Grid xs={12}>
                            <FormChildDataGrid 
                                name="altlist" 
                                label="alt list" 
                                newRow={() => {
                                    return {
                                        id: uuidv4(),
                                        item1: '',
                                        item2: '',
                                        item3: '',
                                    };
                                }}
                                columns={[
                                    { 
                                        field: 'item1', 
                                        headerName: 'Item 1', 
                                    },
                                    { 
                                        field: 'item2', 
                                        headerName: 'Item 2', 
                                    },
                                ]}
                                formRender={(defaultValues,onSubmit,onCancel)=>{
                                    return (
                                    <FormProvider defaultValues={defaultValues} >
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="item1" label="Item 1"/>
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="item2" label="Item 2"/>
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="item3" label="Item 3"/>
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                                                </Grid>
                                            </Grid>
              
                                        </Form>
                                    </FormProvider>
                                    );
                                }}
                            />
                        </Grid>


                        <Grid xs={12}>
                            <FormDropDown name="mylist" label="My List" list={[{label: "One", value: "1"},{label: "Two", value: "2"}]} />
                        </Grid>
                        <Grid xs={12} sm={12}> 
                            <FormMultiSelectChips enableSelectAll name="elephants" label="Elephants Impacted" list={[{value: 1, label: "One"},{value: 2, label: "Two"},{value: 3, label: "Three"}]}/>
                        </Grid>
                        <Grid xs={12} sm={12} sx={{display: "flex", justifyContent: "center"}}> 
                            <FormSlider name="slide1" label="Slider Value" min={0} max={10} step={0.1} marks={[
                                {
                                    value: 0,
                                    label: "Not Content"
                                },
                                {
                                    value: 10,
                                    label: "Content"
                                },
                            ]}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <FormDateTimePicker name="ts" label="Incident Time" required />
                        </Grid>
                        <Grid xs={12}>
                            <Button variant="contained" type="submit">Submit</Button>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>

        </>
    );
}