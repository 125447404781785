export async function reviewPeriods() {
  const reviewPeriodList = [
    {
      label: "Once a month",
      value: 1,
    },
    {
      label: "Every 2 months",
      value: 2,
    },
    {
      label: "Every 3 months",
      value: 3,
    },
    {
      label: "Every 6 months",
      value: 6,
    },
  ];

  return reviewPeriodList;
}

export async function userRoles() {
  return [
    {
      label: "Keeper",
      value: "keeper",
    },
    {
      label: "Admin",
      value: "admin",
    },
  ];
}

export async function sex() {
  return [
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Male",
      value: "male",
    },
  ];
}

export async function genus() {
  return [
    {
      label: "African",
      value: "african",
    },
    {
      label: "Asian",
      value: "asian",
    },
  ];
}

export async function knownEstimated() {
  return [
    {
      label: "Known",
      value: 0,
    },
    {
      label: "Estimated",
      value: 1,
    },
  ];
}


export async function categoryList() {
return [
    {
        label: "Management",
        value: "management_husbandry",
    },
    {
        label: "Facility",
        value: "facility",
    },
    {
        label: "Social Group",
        value: "social_group",
    },
    {
        label: "Event",
        value: "event",
    },
    {
        label: "Health",
        value: "health",
    },
    {
        label: "Other",
        value: "other",
    },
];
}


export async function typeList() {
  return [
    {
        label: "Temporary",
        value: "temporary"
    },
    {
        label: "Permanent",
        value: "permanent"
    },
];
}

export async function statusList() {
  return [
    {
        label: "Single Event",
        value: "single_event"
    },
    {
        label: "Discrete Period",
        value: "discrete_period"
    },
    {
        label: "Ongoing",
        value: "ongoing"
    },
];
}

export async function relationshipTypeList() {
  return [
    {
        label: "Mother",
        value: "mother"
    },
    {
        label: "Father",
        value: "father"
    },
    {
        label: "Maternal Sibling",
        value: "maternal_sibling"
    },
    {
        label: "Paternal Sibling",
        value: "paternal_sibling"
    },
    {
        label: "Maternal Cousin",
        value: "maternal_cousin"
    },
    {
        label: "Paternal Cousin",
        value: "paternal_cousin"
    },
    {
        label: "Grandmother",
        value: "grandmother"
    },
    {
        label: "Grandfather",
        value: "grandfather"
    },
    {
        label: "Aunt",
        value: "aunt"
    },
    {
        label: "Uncle",
        value: "uncle"
    },
    {
        label: "Niece",
        value: "niece"
    },
    {
        label: "Nephew",
        value: "nephew"
    }
];
}

export async function stereotypyFrequency() {

  return [
    {
        label: "Always (few, if any, sterotypy-free intervals)",
        value: "always",
    },
    {
        label: "Very frequently (virtually every hour)",
        value: "very_frequently",
    },
    {
        label: "Frequently (daily, multiple times per day)",
        value: "frequently",
    },
    {
        label: "Occasionally (daily but infrequent)",
        value: "occasionally",
    },
    {
        label: "Rarely (not daily)",
        value: "rarely",
    },
    {
        label: "Never",
        value: "never",
    },
  ];
}

export async function stereotypiesInterruptBehaviorFrequency() {
  return [
      {
          label: "Stereotypies do not interrupt flow of behaviour",
          value: "do_not"
      },
      {
          label: "Stereotypies occasionally interrupt flow of behaviour",
          value: "occasionally"
      },
      {
          label: "Stereotypies frequently interrupt flow of behaviour",
          value: "frequently"
      },
      {
          label: "Frequently interrupt flow of behaviour and occasionally disrupt intended action",
          value: "frequently_occasionally_disrupt"
      },
      {
          label: "Stereotypies frequently disrupt intended action",
          value: "frequently_disrupt"
      },
  ];
}

export async function yesNoUnknown() {

  return [
      {
          label: "Yes",
          value: "yes"
      },
      {
          label: "No",
          value: "no"
      },
      {
          label: "Unknown",
          value: "unknown"
      },
  ];
}