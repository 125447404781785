import * as React from 'react';

import { useLoaderData, useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import AddIcon from '@mui/icons-material/Add';

import EwpFab from "../components/EwpFab";
import Header from "../components/Header";

import UserCard from "../components/cards/UserCard";

import {listUsers} from '../models/users';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader() {
    const users = await listUsers();
    console.log(users);
    return {users};
}


export default function UsersListPage({title = "Users",...props}) {
    const navigate = useNavigate();

    // used to read the loader data that is returned from the function above
    const {users} = useLoaderData(); 

    console.log(users);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */


    const navigateToUser = (id) => {
        console.log(id);
        navigate("/users/" + id);
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="md">
            <Header label={title} />

            <Grid container spacing={2}>
            {users.map((user)=>(
                <Grid key={user.id} xs={12} sm={6} md={4}>
                    <UserCard user={user} onActionAreaClick={()=>{navigateToUser(user.id)}} />
                </Grid>
            ))}
            </Grid>

            <EwpFab onClick={()=>navigate("/users/create")}>
                <AddIcon />
            </EwpFab>
        </Container>
    );
}

/*

            */