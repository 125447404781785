import * as React from 'react';

import {useLoaderData, useNavigate, useLocation} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDropDown from '../components/form/FormDropDown';
import FormDatePicker from '../components/form/FormDatePicker';
import FormSwitch from '../components/form/FormSwitch';

import Header from "../components/Header";

import { getUser, saveUser} from '../models/users';
import {userRoles } from '../models/categories';

import { useConnectivity } from '../context/ConnectivityProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    const existingUser = (params.userId) ? await getUser(params.userId) : null;
    return {existingUser, userRoles: await userRoles()};
}

export default function UserFormPage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const {facilityId} = useAuth();
    const navigate = useNavigate();
    const {isOnline} = useConnectivity();

    console.log("facilityId: ", facilityId);
    // used to read the loader data that is returned from the function above
    const {existingUser, userRoles} = useLoaderData(); 
    const {state} = useLocation();
    console.log(state);
    const user = (existingUser) ? existingUser : state;

    console.log("User Record: ", user);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const results = await saveUser(data,facilityId,isOnline);
            console.log("save results: ", results);
            setSuccess("User Saved");
            navigate("/users/"+ results.id);
        } catch (error) {
            setError(error);
        }
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            <FormProvider onSubmit={onSubmit} defaultValues={user} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12}> 
                            <FormTextField name="name" label="Name" required />
                        </Grid>
                        <Grid xs={12} sm={6}> 
                            <FormTextField name="job_title" label="Job Title" required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormDropDown name="role" label="User Role" list={userRoles} required />
                        </Grid>
                        
                        <Grid xs={12} sm={6}> 
                            <FormTextField name="phone" label="Phone"/>
                        </Grid>
                        <Grid xs={12} sm={6}> 
                            <FormTextField name="email" label="Email" required/>
                        </Grid>
                        <Grid xs={12} sm={6}> 
                            <FormDatePicker name="began_working_with_elephants_at" label="Begain Working With Elephants On" />
                        </Grid>
                        <Grid xs={12} sm={6}> 
                            <FormDatePicker name="began_working_in_facility_at" label="Begain Working At Institution On" />
                        </Grid>

                        <Grid xs={6} sm={6}> 
                            <FormSwitch name="is_active" label="Is Active" />
                        </Grid>

                        <Grid xs={6} sm={6}> 
                            <FormDatePicker name="departure_date" label="Departure Date" />
                        </Grid>

                        <Grid xs={12}>
                            <FormButton>Submit</FormButton>
                        </Grid>

                    </Grid>
                </Form>
            </FormProvider>



        </Container>
    );
}