import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { useTheme } from '@mui/material/styles';

export default function FacilityIcon(props) {
  const theme = useTheme();

  //console.log("elephant props",props);

  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}

        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
        >
            <path style={{fill: theme.palette.text.secondary}} d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
        </svg>

    </SvgIcon>
  );
}
