import * as React from 'react';

import {useLoaderData } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';
import { CheckCircle, Error, AccessTime, DoNotDisturb } from '@mui/icons-material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormButton from '../components/form/FormButton';
import FormDatePicker from '../components/form/FormDatePicker';

import Header from "../components/Header";
import Subheader from "../components/Subheader";

import DetailsHeader from '../components/DetailsHeader';
import DetailsContent from '../components/DetailsContent';
import ErrorContent from '../components/ErrorContent';


import {date, today} from "../utils/datetime";


import { getAssessment, saveAssessment } from '../models/assessments';
import { getElephant } from '../models/elephants';

import { useConnectivity } from '../context/ConnectivityProvider';
import { overallSectionStatus } from '../utils/sections';
import { listSectionAsByAssessment } from '../models/sectiona';
import { listSectionBsByAssessment } from '../models/sectionb';
import { listSectionCsByAssessment } from '../models/sectionc';
import { listSectionCIoAsByAssessment } from '../models/sectioncioa';
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from '@mui/material';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
  return fetchData(params)
}

const fetchData = async(params) => {
    console.log("loader params",params);
    const assessment = await getAssessment(params.assessmentId);
    const elephant = await getElephant(assessment.elephant_id);
    const sectionAs = await listSectionAsByAssessment(assessment.id);
    const sectionBs = await listSectionBsByAssessment(assessment.id);
    const sectionCs = await listSectionCsByAssessment(assessment.id);
    const sectionCIoAs = await listSectionCIoAsByAssessment(assessment.id);
    return {assessment,elephant, sectionAs, sectionBs, sectionCs, sectionCIoAs};
}

export default function AssessmentSubmitPage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const {facilityId, user} = useAuth();
    const {isOnline} = useConnectivity();
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [payload, setPayload] = React.useState({})
    
    const navigate = useNavigate();
    const {assessment,elephant,sectionAs,sectionBs,sectionCs,sectionCIoAs} = useLoaderData(); 
    const statusSectionA = overallSectionStatus(sectionAs || []);
    const statusSectionB = overallSectionStatus(sectionBs || []);
    const statusSectionC = overallSectionStatus(sectionCs || []);
    const statusSectionCIoA = overallSectionStatus(sectionCIoAs || []);
    const [updatedAssessment] = React.useState(assessment)
    const checkIncompleteSections = ()=>{
        let incompleteSections=[]
        if(statusSectionC !== "Completed" && !updatedAssessment.confirm_section_c_incomplete){
            incompleteSections.push("sectionC");
        }
        if(statusSectionB !== "Completed" && !updatedAssessment.confirm_section_b_incomplete){
            incompleteSections.push("sectionB");
        }
        if(statusSectionA !== "Completed" && !updatedAssessment.confirm_section_a_incomplete){
            incompleteSections.push("sectionA");
        }
        if(statusSectionCIoA !== "Completed" && !updatedAssessment.confirm_section_cioa_incomplete){
            incompleteSections.push("sectionCIoA");
        }
        console.log("incompleteSections",incompleteSections);
        return incompleteSections
    }



    const [checkedSections, setCheckedSections] = React.useState([])
    // used to read the loader data that is returned from the function above

    //const timeframe = (change.start && change.end) ? date(change.start) + " - " + date(change.end) : "Started " + date(change.start);

    // note you can play with the container maxwidth to spread out the window content appears in

const handleCheck = (checked,name) => {
    let copy=[...checkedSections];
    if(checked){
        if(!copy.includes(name)){
            copy.push(name);
        }
    }
    else{
        copy.splice(copy.indexOf(name),1);
    }
    setCheckedSections(copy)
}

const submitAssessment = async(data)=>{
  try{
    const saveResult = await saveAssessment(data,facilityId,user.id, isOnline);
            console.log(saveResult);

            // when an assessment is submitted, 
                // all records in each section are marked as completed, if not done so already,
                // assessment is marked as submitted

            

            /*
            data.is_resolved = true;
            const results = await saveChange(data,facilityId,user.id);
            console.log("save results: ", results);
            //navigate("/changes/" + change.id);
            */
            setSuccess("Assessment Saved, Evaluation Started");
            navigate("/assessments/" + updatedAssessment.id);
  }
  catch(e){
    console.error(e);
    setError(e.message);
  }
}
    const onSubmit = async (data) => {
        try {
          console.log(data);
            if(checkIncompleteSections().length>0){
                setShowConfirmModal(true);
                setPayload(data)
                return
            }
            submitAssessment(data)
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };

    const statusIcon={
        "Completed": <CheckCircle color="success" fontSize='large'/>,
        "All Submitted": <CheckCircle fontSize='large' color='success'/>,
        "In Progress": <AccessTime fontSize='large' color="action" />,
        "Not Started": <DoNotDisturb fontSize='large' color='error' />,
        "incomplete": <Error fontSize='large' color='info' />,
    }

    const markSectionsAsIncomplete= async ()=>{
        try{
            let payloadObj={...payload}
            if(checkedSections.includes("sectionA")){
                payloadObj.confirm_section_a_incomplete=true;
            }
            if(checkedSections.includes("sectionB")){
                payloadObj.confirm_section_b_incomplete=true;
            }
            if(checkedSections.includes("sectionC")){
                payloadObj.confirm_section_c_incomplete=true;
            }
            if(checkedSections.includes("sectionCIoA")){
                payloadObj.confirm_section_cioa_incomplete=true;
            }
            await submitAssessment({...payloadObj});
            setShowConfirmModal(false);
        }
        catch(e){
            console.log(e)
        }
    }

    console.log("assessment", assessment);


    return (
      <>
        <Container component="main" maxWidth="sm">
          <Header label={title} />

          <Subheader label="Details" />
          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid xs={6} sm={6}>
              <DetailsHeader>Elephant</DetailsHeader>
              <DetailsContent>{elephant.name}</DetailsContent>
            </Grid>
            <Grid xs={6} sm={6}>
              <DetailsHeader>Started On</DetailsHeader>
              <DetailsContent>{date(updatedAssessment.started_on)}</DetailsContent>
            </Grid>

            <Grid xs={4} sm={4}>
              <DetailsHeader>Section A Records</DetailsHeader>
              <DetailsContent>4</DetailsContent>
            </Grid>
            <Grid xs={4} sm={4}>
              <DetailsHeader>Section B Records</DetailsHeader>
              <DetailsContent>12</DetailsContent>
            </Grid>
            <Grid xs={4} sm={4}>
              <DetailsHeader>Section C Records</DetailsHeader>
              <DetailsContent>20</DetailsContent>
            </Grid>
            <Subheader label="Status" />
            {!updatedAssessment.submitted_on &&<Grid
              xs={12}
              container
              sx={{
                mt: 1,
                mb: 3,
                border: "1px solid #e0e0e0",
                borderRadius: 1,
              }}
              justifyContent="space-around"
              flexDirection="row"
              rowGap={2}
            >
              <Grid xs={6} container flexDirection="column" alignItems="center">
                <DetailsHeader>Section A</DetailsHeader>
                {!updatedAssessment.confirm_section_a_incomplete?statusIcon[statusSectionA]:statusIcon["incomplete"]}
                <DetailsContent>{!updatedAssessment.confirm_section_a_incomplete?statusSectionA:"Marked as Incomplete"}</DetailsContent>
                {checkIncompleteSections().includes("sectionA") &&
                        <FormControlLabel onChange={(e)=>handleCheck(e.target.checked,"sectionA")} control={<Checkbox />} label="Mark as incomplete" />}
              </Grid>
              <Grid xs={6} container flexDirection="column" alignItems="center">
                <DetailsHeader>Section B</DetailsHeader>
                {!updatedAssessment.confirm_section_b_incomplete?statusIcon[statusSectionB]:statusIcon["incomplete"]}
                <DetailsContent>{!updatedAssessment.confirm_section_b_incomplete?statusSectionB:"Marked as Incomplete"}</DetailsContent>
                {
                    checkIncompleteSections().includes("sectionB") &&
                        <FormControlLabel onChange={(e)=>handleCheck(e.target.checked,"sectionB")} control={<Checkbox />} label="Mark as incomplete" />
                }
              </Grid>
              <Grid xs={6} container flexDirection="column" alignItems="center">
                <DetailsHeader>Section C</DetailsHeader>
                {!updatedAssessment.confirm_section_c_incomplete?statusIcon[statusSectionC]:statusIcon["incomplete"]}
                <DetailsContent>{!updatedAssessment.confirm_section_c_incomplete?statusSectionC:"Marked as Incomplete"}</DetailsContent>
                {
                    checkIncompleteSections().includes("sectionC") &&
                        <FormControlLabel onChange={(e)=>handleCheck(e.target.checked,"sectionC")} control={<Checkbox />} label="Mark as incomplete" />
                }
              </Grid>
              <Grid xs={6} container flexDirection="column" alignItems="center">
                <DetailsHeader>Incidents of aggression</DetailsHeader>
                {!updatedAssessment.confirm_section_cioa_incomplete?statusIcon[statusSectionCIoA]:statusIcon["incomplete"]}
                <DetailsContent>{!updatedAssessment.confirm_section_cioa_incomplete?statusSectionCIoA:"Marked as Incomplete"}</DetailsContent>    
                {
                    checkIncompleteSections().includes("sectionCIoA") &&
                        <FormControlLabel onChange={(e)=>handleCheck(e.target.checked,"sectionCIoA")} control={<Checkbox />} label="Mark as incomplete" />
                }
              </Grid>
            </Grid>}
          </Grid>

          {updatedAssessment.submitted_on ? (
            <ErrorContent>
              This assessment has already been submitted.
            </ErrorContent>
          ) : (
            <FormProvider
              onSubmit={onSubmit}
              defaultValues={{ ...assessment, submitted_on: today() }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <FormDatePicker
                      name="submitted_on"
                      label="Submitted Date"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormButton disabled={checkIncompleteSections().length!== checkedSections.length }>Submit Assessment</FormButton>
                  </Grid>
                </Grid>
              </Form>
            </FormProvider>
          )}
        </Container>
        {showConfirmModal && (
          <Modal
            open={showConfirmModal}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: 2,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                Submit assessment?
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                You are about to submit the assessment with incomplete sections
              </Typography>
              <Typography variant="body2" sx={{ color:"red",mb:2 }}>
              This action cannot be undone
              </Typography>
               <Grid sx={{mt:2}} container justifyContent={"flex-end"} columnGap={2}>
                <Button onClick={()=>{setShowConfirmModal(false)}} variant='outlined'>
                    Cancel
                </Button>
                <Button variant='contained' onClick={markSectionsAsIncomplete}>
                    Submit
                </Button>
                </Grid>
            </Box>
          </Modal>
        )}
      </>
    );
}

/*
            <Subheader label="Details" />
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Category</Typography>    
                    <Typography variant="body2">{listLabel(change.category,categories)}</Typography>    
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Type</Typography>    
                    <Typography variant="body2">{listLabel(change.change_type,types)}</Typography>    
                </Grid>

                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Status</Typography>    
                    <Typography variant="body2">{listLabel(change.status,statuses)}</Typography>    
                </Grid>

                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Started</Typography>    
                    <Typography variant="body2">{date(change.start)}</Typography>
                </Grid>
            </Grid>


            <Subheader label="Description" />
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={12}> 
                    <Typography variant='body2'>{change.description}</Typography>
                </Grid>
            </Grid>

*/