import { supabase } from '../lib/supabase'
import {db} from '../db';

import {syncvalue,timeFromStorage, timeToStorage} from "../utils/datetime";

const transformFromDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['start_of_day'] = timeFromStorage(temp['start_of_day']);
    temp['end_of_day'] = timeFromStorage(temp['end_of_day']);
    temp['review_period'] = (temp['review_period'] === null) ? 1 : temp['review_period'];
    return temp;
}

const transformToDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['start_of_day'] = timeToStorage(temp['start_of_day']);
    temp['end_of_day'] = timeToStorage(temp['end_of_day']);
    return {facility: temp};
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['start_of_day'] = timeToStorage(temp['start_of_day']);
    temp['end_of_day'] = timeToStorage(temp['end_of_day']);
    return temp;
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['start_of_day'] = timeFromStorage(temp['start_of_day']);
    temp['end_of_day'] = timeFromStorage(temp['end_of_day']);
    temp['review_period'] = (temp['review_period'] === null) ? 1 : temp['review_period'];
    return temp;
}




const fetchFacility = async() => {
    try {
        const { data, error } = await supabase
            .from('facility')
            .select('*')
        if (error) throw new Error(`Error ${error.code}: ${error.message}`);
        if (data !== null) {
            return transformFromDb(data[0]);
        }
    } catch (error) {
        console.error(error);
        throw error
    }
    throw new Error("Facility for logged in user not found");
};

const getFacilityLiveQuery = () => {
    return db().facility.toCollection().first();
};

const getFacility = async() => {
    try {

        const temp = await db().facility.toCollection().first();
        return (temp) ? fromDexie(temp) : undefined;

    } catch (error) {
        console.error(error);
        throw error;
    }
};

const writeFacilityToSupabase = async(inputRecord) => {
    try {
        const {facility} = transformToDb(inputRecord);
        const updateResult = await supabase
            .from('facility')
            .update(facility)
            .eq('id',facility.id)
        if (updateResult.error) throw new Error(`Error ${updateResult.error.code}: ${updateResult.error.message}`);
        return updateResult;
    } catch (error) {
        console.error(error);
        throw error
    }

}

const writeFacilityToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().facility.put(cleanRecord);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }

}

const saveFacility = async(inputRecord,isOnline) => {
    try {
        const syncValue = syncvalue();
        // we should always have an id, so not setting it here.
        inputRecord.updated_at = new Date();
        inputRecord.sync = syncValue;

        const dexieResults = await writeFacilityToDexie(inputRecord);
        const supabaseResults = (isOnline) ? await writeFacilityToSupabase(inputRecord) : {status: "offline"};

        return {dexieResults,supabaseResults};

    } catch (error) {
        console.error(error);
        throw error;
    }
};

const sync = async() => {
    const results = {
        localWrites: [],
        remoteWrites: [],
        syncsEqual: [],
    };

    const remoteData = await fetchFacility();


    const localData = await getFacility();


    if (localData === undefined) {
        // if local data is missing, write remote data to local
        const id = await writeFacilityToDexie(remoteData);
        results.localWrites.push(id);
    } else {
        // if both present, compare sync values and write whichever is later to the other

        if (localData.sync > remoteData.sync) {
            results.remoteWrites.push(await writeFacilityToSupabase(localData));
        } else if (remoteData.sync > localData.sync) {
            results.localWrites.push(await writeFacilityToDexie(remoteData));
        } else {
            results.syncsEqual.push(remoteData.id);
        }
    }

    return results;
};

const truncateDexie = async() => {
    return await db().facility.clear();
}

export {fetchFacility, getFacility, getFacilityLiveQuery, saveFacility, sync, truncateDexie};