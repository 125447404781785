import { createBrowserRouter } from "react-router-dom";

import Layout from '../components/layout/Layout';

import Pages from '../pages';


const drawerMenu = [
        {
            path: '/',
            id: "SignIn",
            text: "Sign In",
            icon: "LOGIN"
        },
        {
            path: '/forgotpassword',
            id: "ForgotPassword",
            text: "Forgot Password",
            icon: "FORGOTPASSWORD"
        },
        {
            path: '/diagnostics',
            id: "Diagnostics",
            text: "Diagnostics",
            icon: "DIAGNOSTICS"
        },
];

const bottomBarMenu = [
  drawerMenu[0],
  drawerMenu[1]
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout menu={drawerMenu} bottomMenu={bottomBarMenu} />,
    errorElement: (<Layout menu={drawerMenu} bottomMenu={bottomBarMenu}><Pages.ErrorPage /></Layout>),
    children: [
      {
        index: true,
        element: <Pages.SignInPage title="Sign In"/>,
        icon: "LOGIN",
      },
      {
        text: "Forgot Password",
        path: "/forgotpassword",
        element: <Pages.ForgotPasswordPage title="Forgot Password" />
      },
      {
        text: "OTP",
        path: "/otp",
        element: <Pages.OtpPage title="OTP" />
      },
      {
        path: "/diagnostics",
        element: <Pages.DiagnosticsPage />,
      },
    ]
  },
]);


export {router,drawerMenu,bottomBarMenu};