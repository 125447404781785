import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from './FormProvider';

const FormTextField = React.forwardRef(({ name, label, required, rules = {}, refValue, shrinkLabel=undefined ,...props }, ref) => {
  const { register, errors, defaultRules, watch } = useForm();

  const requiredDefaultRule = required ? { required: defaultRules['required'] } : {};
  rules = { ...requiredDefaultRule, ...rules };

  if (refValue) {
    refValue.current = watch(name);
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      inputRef={ref}
      required={required}
      error={errors[name] ? true : false}
      helperText={errors[name] ? errors[name]?.message : null}
      InputLabelProps={{ shrink: shrinkLabel }}
      {...props}
      {...register(name, rules)}
    />
  );
});

export default FormTextField;
