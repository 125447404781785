import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import {useParams, useLoaderData} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDropDown from '../components/form/FormDropDown';
import FormSwitch from '../components/form/FormSwitch';
import FormTimePicker from '../components/form/FormTimePicker';

import Header from "../components/Header";
import Subheader from "../components/Subheader";

import { getFacility, saveFacility } from '../models/facility'; 
import { reviewPeriods } from '../models/categories';

import { useConnectivity } from '../context/ConnectivityProvider';
import { useNavigate } from 'react-router-dom';

export async function loader() {
    //return {facility: {name: 'Franks',poc_name:'',poc_email: '', poc_phone: '', review_period: '', use_24_hour_clock: true, start_of_day: time(), end_of_day: time('15:30')}};
    return {
        facility: await getFacility(), 
        reviewPeriods: await reviewPeriods()
    };
}


export default function InstitutionDetailsFormPage() {
    const {setSuccess, setError} = useMessage();
  const params = useParams();
  const {isOnline} = useConnectivity();

  const {updateFacility} = useAuth();
    const navigate = useNavigate();

  const {facility, reviewPeriods} = useLoaderData();
  console.log("facility: ", facility);
  console.log("reviewPeriods: ", reviewPeriods);

  console.log(params);

  const onSubmit = async (data) => {
    try {
        console.log("onSubmit Data: ", data);
        await saveFacility(data,isOnline);
        navigate("/");
        updateFacility(data);
        //revalidator.revalidate();
        setSuccess("Institution Saved");
    } catch (error) {
        console.error(error);
        setError(error);
    }
  }

    console.log("facility:", facility);

  return (
    <Container component="main" maxWidth="sm">
    <Header label="Institution Details"/ >
    <FormProvider onSubmit={onSubmit} defaultValues={facility} >
        <Form>
            <Grid container spacing={2}>
                <Grid xs={12}> 
                    <FormTextField name="name" label="Name of Institution" required/>
                </Grid>

                <Grid xs={12}> 
                    <FormTextField name="poc_name" label="Point of Contact Name" required/>
                </Grid>

                <Grid xs={12}> 
                    <FormTextField name="poc_email" label="Point of Contact Email" required/>
                </Grid>

                <Grid xs={12}> 
                    <FormTextField name="poc_phone" label="Point of Contact Phone"  required/>
                </Grid>

                <Grid xs={12}> 
                    <FormDropDown name="review_period" label="Review Period" list={reviewPeriods} required/>
                </Grid>

                <Grid xs={12}> 
                    <FormSwitch name="use_24_hour_clock" label="Use 24 Hour Time" />
                </Grid>

                <Grid xs={12}> 
                    <Subheader label="Work Day Hours" />
                </Grid>

                <Grid xs={12} sm={6}> 
                    <FormTimePicker name="start_of_day" label="Start Of Day" required/>
                </Grid>

                <Grid xs={12} sm={6}> 
                    <FormTimePicker name="end_of_day" label="End Of Day" required/>
                </Grid>


                <Grid xs={12}>
                    <FormButton>Submit</FormButton>
                </Grid>
            </Grid>
        </Form>
    </FormProvider>
    </Container>
  );
}