import { useTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';
import Spinner from './Spinner';
import { getFacility } from '../models/facility'; 
import { useQuery } from '@tanstack/react-query';

import { dateLocaleFormat,isDateTimeOverdue } from '../utils/datetime';
import { isInProgress } from '../utils/assessments';

import { sprintf } from 'sprintf-js';

export default function LastAssessmentAt({assessment = null, defaultLabelTemplate = "%s", inProgressLabelTemplate = null, overdueLabelTemplate = "Overdue, Last Assessed %s", noAssessmentLabel = "Not Assessed Yet", ...props}) {
    const theme = useTheme();
    const {isLoading, data} = useQuery({
        queryKey: ['facility'],
        queryFn: async() => {
            return {
                facility: await getFacility(),
            };
        },
        networkMode: "offlineFirst",
    });

    let text = "";
    let color = theme.palette.done.main;
    if (isLoading === false) {

        if (assessment === null || assessment === undefined) {
            color = theme.palette.start.main
            text = noAssessmentLabel;
        } else {

            const assessmentDate = assessment.started_on.format(dateLocaleFormat())
            text = sprintf(defaultLabelTemplate, assessmentDate);

            if (isInProgress(assessment)) {
                color = theme.palette.inprogress.main
                if (inProgressLabelTemplate) text = sprintf(inProgressLabelTemplate,assessmentDate);
            } else if (isDateTimeOverdue(assessment.started_on,data.facility)) {
                color = theme.palette.overdue.main
                if (overdueLabelTemplate) text = sprintf(overdueLabelTemplate,assessmentDate);
            }
        }

    }

    return (
        <>
        {isLoading && <Spinner />}
        {!isLoading && 
        <Typography component="span" sx={{color, fontWeight: "bold"}}>{text}</Typography>
        }
        </>
    )
}