import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import FormDropDownAndUsed from "../components/form/FormDropDownAndUsed";
import Form from "../components/form/Form";
import { FormProvider } from "../components/form/FormProvider";
import FormButton from "../components/form/FormButton";
import { useAuth } from "../context/AuthProvider";
import { useMessage } from "../context/MessageProvider";
import { getAssessment } from "../models/assessments";
import { listElephants } from "../models/elephants";
import { getFacility } from "../models/facility";

import Subtitle from "../components/Subtitle";
import GoBack from "../components/GoBack";

import { findModel } from '../utils/model';

import {
  listSectionCsByAssessment,
  newSectionC,
  saveSectionC,
} from "../models/sectionc";
import { halfHourTimeSlots, today } from "../utils/datetime";

import { useConnectivity } from "../context/ConnectivityProvider";

export async function loader({ params }) {
  console.log("sectionc params",params);

  const assessment = await getAssessment(params.assessmentId);
  const sectionCs = await listSectionCsByAssessment(assessment.id);

  return {
    assessment,
    elephants: await listElephants(),
    newSectionC: newSectionC(),
    facility: await getFacility(),
    sectionCs,
  };
}

export default function AssessmentSectionCSelectPage({ title = "EWP", ...props }) {

    const { setSuccess } = useMessage();
    const navigate = useNavigate();
    const { facility, assessment, sectionCs, newSectionC, elephants } = useLoaderData();

    const elephant = findModel(elephants,assessment.elephant_id);

    const { user } = useAuth();
    const ranges = halfHourTimeSlots(facility.end_of_day, facility.start_of_day);
    const { isOnline } = useConnectivity();


    const hasInprogressRecords = (sectionCs.filter((cur)=>{
        return cur.observed_on && !cur.submitted_on
    }).length) ? true : false;
    console.log(hasInprogressRecords);

    let makeFirstActive = null;


    const rangeList = ranges.map((observationRange)=>{

        const matchedSectionC = sectionCs.filter((cur)=>{
            return observationRange === cur.observation_range
        });

        if (makeFirstActive === false) makeFirstActive = true;
        if (hasInprogressRecords === false && matchedSectionC.length === 0 && makeFirstActive === null) {
            console.log("here?");
            makeFirstActive = false;
        }

        return {
            value: observationRange,
            label: observationRange,
            disabled: ((hasInprogressRecords && matchedSectionC.length === 1) || (hasInprogressRecords === false && makeFirstActive === false)) ? false : true,
        };
    });

    // generate a used list out of the submitted sectionCs
    const usedList = sectionCs.filter((cur)=>{
        return (cur['submitted_on']);
    }).map((cur)=>{
        return cur.observation_range;
    });




    const onSubmit = async (data)=>{
        console.log("onSubmit: ", data);


        const matchedSectionC = sectionCs.filter((cur)=>{
            return data.observation_range === cur.observation_range;
        });

        console.log("matchedSectionC ", matchedSectionC);

        if (matchedSectionC.length > 1) {
            throw new Error("An issue exists with the section C, two or more records are using the same observation range");
        } else if (matchedSectionC.length === 1) {
            const existingSectionC = matchedSectionC[0];
            setSuccess("Started Section C");
            navigate("/assessments/" + assessment.id + "/sectionc/" + existingSectionC.id);
        } else {

            const payload = {...newSectionC,...data,observed_on: today(), assessment_id: assessment.id, user_id: user.id};
            console.log("payload: ", payload);

            const saveResult = await saveSectionC(payload,isOnline);
            console.log("sectiona save: ", saveResult);

            setSuccess("Started Section C");
            navigate("/assessments/" + assessment.id + "/sectionc/" + saveResult.id);

        }



    }

    return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(`/assessments/${assessment.id}`)} />
            <Header label={title} />
            <Subtitle>{elephant?.name}</Subtitle>

            <FormProvider onSubmit={onSubmit} defaultValues={{observation_range: ''}} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} sx={{justifyContent: "center", alignItems: "center"}}> 
                            <FormDropDownAndUsed name="observation_range" label="Observation Timeframe" list={rangeList} required usedList={usedList} />
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormButton>Start</FormButton>
                        </Grid>
                    </Grid>

                </Form>
            </FormProvider>
        </Container>

    );
}
