import * as React from 'react';
import Button from '@mui/material/Button';
import {useForm} from './FormProvider';




export default function FormButton({children,onSubmit,...props}) {
    const {handleSubmit} = useForm();

    const propsFunc = (onSubmit) ? handleSubmit(onSubmit) : null;

    return (
        <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={propsFunc}
            {...props}
        >{children}</Button>);
};