import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import CloudOnIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import HelpIcon from '@mui/icons-material/Help';

import {useConnectivity} from '../context/ConnectivityProvider';
import {useMessage} from '../context/MessageProvider';

import {useParams} from 'react-router-dom';

import CustomTooltip from '../components/tooltip/CustomTooltip';

import {time, timeToStorage, timeFromStorage, isDateTimeOverdue} from "../utils/datetime";

import dayjs from 'dayjs';

import {useLayout} from '../components/layout/Layout';

import { useConfirmationDialog } from '../context/ConfirmationDialogContext';

import { useSync } from '../context/SyncingProvider';

import { useTimer } from '../context/TimerProvider';
import Timer from '../components/Timer';

import { useSectionB } from '../context/SectionBProvider';

import { supabase } from '../lib/supabase'

import CheckFacilityConfiguration from '../components/CheckFacilityConfiguration';

export default function TestPage({title = "EWP",...props}) {
    const {setMessage, setSuccess, setWarning, setError} = useMessage();
    const {hideNavigation,showNavigation} = useLayout();
    const {isOnline} = useConnectivity();
    const {startSync,endSync, performSync} = useSync();

    const {secondsRemaining, startingSeconds, isRunning, isDone, startTimer, endTimer, pauseTimer} = useTimer();

    const {current, nextRecord, prevRecord, nextIndex, prevIndex, index, inProgressObservations, addObservation, removeObservation, nextObservation, prevObservation, clearObservations, setObservation} = useSectionB();


    React.useEffect( ()=> {document.title = title}, [title]);

    const params = useParams();
    console.log(params);

    const record = {
        start: dayjs(),
    };


    console.log("initial record: ",record);
    console.log("to storage: ", timeToStorage(record.start));
    console.log("from storage: ", timeFromStorage(timeToStorage(record.start)));

    console.log("time func: ", time(dayjs()));


    const {confirm} = useConfirmationDialog();
    const handleConfirmOpen1 = async () => {
        confirm({
            message: "can you read this?",
            onSubmit: async () => { console.log("onSubmit 1 executed"); },
        });
    }

    const handleConfirmOpen2 = async () => {
        confirm({
            title: "Are you sure?",
            message: "Please confirm",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onSubmit: async () => { console.log("onSubmit 2 executed"); },
            onCancel: async () => { console.log("onCancel 2 executed"); }
        });
    }

    const [output,setOutput] = React.useState(null);

    return (
        <>
            <CheckFacilityConfiguration />
            <Typography variant="h4">Test Page</Typography>

            <Grid container spacing={2}>
                <Grid xs={6} sm={3}>
                        <Button variant="contained" onClick={async ()=>{
                            const result = await supabase.functions.invoke('hello-world',{body: {name: "Pete"}});
                            console.log(result);
                            setOutput(JSON.stringify(result));
                        }}>Invoke hello-world</Button>
                </Grid>
                <Grid xs={6} sm={3}>{output}</Grid>
            </Grid>




            <Grid container spacing={2}>
                <Grid xs={6} sm={3}>
                        <Button variant="contained" onClick={()=>setMessage("Notification Message")}>Send Notification Message</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                        <Button variant="contained" onClick={()=>setSuccess("Success Message")}>Send Success Message</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                        <Button variant="contained" onClick={()=>setWarning("Warning Message")}>Send Warning Message</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                        <Button variant="contained" onClick={()=>setError("Error Message")}>Send Error Message</Button>
                </Grid>
            </Grid>

            {isOnline && <CloudOnIcon variant="outline" sx={{color: "green"}}/>}
            {!isOnline && <CloudOffIcon sx={{color: "red"}}/>}

            <CustomTooltip keyString="defaultInfo">
                <Typography>This is a test</Typography>
            </CustomTooltip>

            <CustomTooltip keyString="defaultInfo">
                <HelpIcon />
            </CustomTooltip>

            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Button variant="contained" onClick={hideNavigation}>Hide Nav</Button>
                    <Button variant="contained" onClick={showNavigation}>Show Nav</Button>
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={handleConfirmOpen1}>Show Confirmation Dialog 1</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={handleConfirmOpen2}>Show Confirmation Dialog 2</Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={performSync}>Perform Sync</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={startSync}>Start Sync</Button>
                </Grid>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={endSync}>End Sync</Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid xs={6} sm={3}>
                    <Button variant="contained" onClick={()=>{
                        console.log(isDateTimeOverdue("2023-07-22 11:46:00",{review_period: 2}));
                    }}>Test isOverdue</Button>
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid xs={6} >
                    Starting: {startingSeconds}
                </Grid>
                <Grid xs={6} >
                    Remaining: {secondsRemaining}
                </Grid>
                <Grid xs={12} >
                    <Timer />
                </Grid>
                <Grid xs={6} >
                    Is Running: {(isRunning) ? 1 : 0}
                </Grid>
                <Grid xs={6} >
                    Is Done: {(isDone) ? 1 : 0}
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        console.log("starting timer");
                        startTimer(10);
                    }}>Start Timer</Button>
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        pauseTimer();
                    }}>Pause Timer</Button>
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        endTimer();
                    }}>End Timer</Button>
                </Grid>
            </Grid>



            <Grid container spacing={2}>

                <Grid xs={12} >
                    <Button variant="contained" onClick={()=>{
                        addObservation({id: 'abc123', title: 'Item 1'});
                        addObservation({id: 'bcd123', title: 'Item 2'});
                        addObservation({id: 'cde123', title: 'Item 3'});
                        addObservation({id: 'def123', title: 'Item 4'});
                    }}>Add</Button>
                </Grid>

                <Grid xs={4} >
                    <Typography>{prevIndex}</Typography>
                    <Typography>{prevRecord?.title}</Typography>
                </Grid>
                <Grid xs={4} >
                    <Typography>Length: {inProgressObservations.length}</Typography>
                    <Typography>{index}</Typography>
                    <Typography>{current?.title}</Typography>
                </Grid>
                <Grid xs={4} >
                    <Typography>{nextIndex}</Typography>
                    <Typography>{nextRecord?.title}</Typography>
                </Grid>

                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        prevObservation();
                    }}>Prev</Button>
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        nextObservation();
                    }}>Next</Button>
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        removeObservation(current);
                    }}>Remove Current</Button>
                </Grid>
                <Grid xs={4} >
                    <Button variant="contained" onClick={()=>{
                        clearObservations();
                    }}>Clear</Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {inProgressObservations.map((cur,index)=>
                    <Grid xs={3} >
                        <Button variant="contained" onClick={()=>{
                            setObservation(index);
                        }}>{index}</Button>
                    </Grid>
                )}
            </Grid>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet aliquam id diam maecenas ultricies. Gravida arcu ac tortor dignissim convallis aenean et. Cras adipiscing enim eu turpis egestas pretium aenean pharetra. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis. In mollis nunc sed id semper risus in hendrerit. In hac habitasse platea dictumst vestibulum. Mi quis hendrerit dolor magna eget est. Lorem ipsum dolor sit amet consectetur adipiscing. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed. Commodo quis imperdiet massa tincidunt nunc pulvinar sapien. Diam phasellus vestibulum lorem sed risus ultricies tristique. Amet massa vitae tortor condimentum lacinia quis vel eros. Cras adipiscing enim eu turpis. Auctor elit sed vulputate mi sit amet mauris. Ac feugiat sed lectus vestibulum mattis. Magna ac placerat vestibulum lectus mauris ultrices eros. Etiam erat velit scelerisque in dictum non consectetur a. Aliquet enim tortor at auctor urna nunc id.</p>

            <p>Urna condimentum mattis pellentesque id nibh tortor id. Vitae elementum curabitur vitae nunc. Massa sed elementum tempus egestas sed sed risus pretium quam. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum. Amet aliquam id diam maecenas ultricies mi. A erat nam at lectus urna. Mauris cursus mattis molestie a iaculis at erat pellentesque. Vel eros donec ac odio tempor orci. Vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra. Massa ultricies mi quis hendrerit dolor. Mattis nunc sed blandit libero volutpat. Nisi porta lorem mollis aliquam ut porttitor leo. Magna etiam tempor orci eu lobortis elementum nibh tellus.</p>

            <p>Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Feugiat in fermentum posuere urna. Sed sed risus pretium quam vulputate dignissim. Eu volutpat odio facilisis mauris. Commodo elit at imperdiet dui accumsan sit amet. Ut sem viverra aliquet eget sit amet tellus cras adipiscing. Sit amet justo donec enim diam vulputate. Diam volutpat commodo sed egestas egestas fringilla. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Eget dolor morbi non arcu risus. Sapien faucibus et molestie ac feugiat. Quam adipiscing vitae proin sagittis nisl. Eget duis at tellus at urna condimentum mattis pellentesque id. Et leo duis ut diam quam.</p>

            <p>Gravida quis blandit turpis cursus in. Ultrices eros in cursus turpis massa tincidunt dui ut. Vulputate enim nulla aliquet porttitor lacus. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur. Maecenas pharetra convallis posuere morbi leo urna molestie at elementum. Tellus integer feugiat scelerisque varius morbi enim. Nibh tortor id aliquet lectus proin nibh nisl condimentum. In vitae turpis massa sed elementum. Vulputate ut pharetra sit amet aliquam id diam maecenas. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Laoreet sit amet cursus sit amet dictum sit amet. Pretium lectus quam id leo in vitae turpis massa sed. Risus quis varius quam quisque id. Feugiat in ante metus dictum at tempor commodo ullamcorper a. Dictum fusce ut placerat orci nulla.</p>

            <p>In pellentesque massa placerat duis ultricies lacus sed. Ut morbi tincidunt augue interdum velit. Mattis vulputate enim nulla aliquet porttitor. Amet mauris commodo quis imperdiet massa. Magna eget est lorem ipsum dolor. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Id faucibus nisl tincidunt eget. Nibh tortor id aliquet lectus proin nibh. Habitasse platea dictumst quisque sagittis purus sit amet volutpat. Montes nascetur ridiculus mus mauris vitae. Aenean sed adipiscing diam donec adipiscing tristique risus nec. Feugiat vivamus at augue eget. Pretium fusce id velit ut tortor pretium viverra. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus. Adipiscing diam donec adipiscing tristique risus nec.</p>

        </>
    );
}


/*

*/