import * as React from 'react';

import {useLoaderData} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useMessage } from '../context/MessageProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerIcon from '@mui/icons-material/Timer';


import {FormProvider} from '../components/form/FormProvider';
import FormButton from '../components/form/FormButton';
import FormTextField from '../components/form/FormTextField';
import ComponentTextField from '../components/form/ComponentTextField';
import FormMultiSelectChips from '../components/form/FormMultiSelectChips';
import ComponentMultiSelectChips from '../components/form/ComponentMultiSelectChips';

import Header from "../components/Header";

import { findModel } from '../utils/model';

import EwpButton from '../components/EwpButton';

import { listElephants } from '../models/elephants';
import { getAssessment } from '../models/assessments';
import { newSectionBBehavior, resetSectionBRecord, getSectionB, saveSectionB, saveSectionBLocal } from '../models/sectionb';


import GoBack from '../components/GoBack';

import {today} from "../utils/datetime";

import Timer from '../components/Timer';

import CustomTooltip from '../components/tooltip/CustomTooltip';
import HelpIcon from '@mui/icons-material/Help';

import { useForm } from '../components/form/FormProvider';
import { useLayout } from '../components/layout/Layout';
import { useConfirmationDialog } from '../context/ConfirmationDialogContext';
import { useConnectivity } from '../context/ConnectivityProvider';
import { useTimer } from '../context/TimerProvider';
import { useSectionB } from '../context/SectionBProvider';
import BehaviorChips from '../components/chips/BehaviorChips';
import { chipWords } from '../utils/behaviorChipWords';
import { useEffect } from 'react';

const CustomForm = ({children,...props}) => {
    const {handleSubmit,control,onSubmit, setValue, reset, formState: {defaultValues}} = useForm();

    //const contextFunc = (onSubmit) ? handleSubmit(onSubmit) : null;
    const contextFunc = handleSubmit((inputData)=>{
        onSubmit(inputData);
        reset(defaultValues);
    });

    useEffect(() => {
        if(props.cb){
            props.cb(setValue,control)
        }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props])

    return (
        <form onSubmit={contextFunc} noValidate {...props}>
            {React.Children.map(children, child => 
                React.cloneElement(child, { control: control })
            )}
        </form>
    )
}

const BehaviorAccordion = ({
    behaviorKey,
    title,
    count = 0,
    onSubmit, 
    elephants,
    description,
    location,
    associatedElephants,
    descriptionProps ={},
    locationProps = {},
    associatedElephantsProps = {},
    chips=false,
    chipKey=''
    }) => {
    var callbackFunc=()=>{}
    var control={}
    const tooltipKey = "section_b_" + behaviorKey;
    return (
    <Accordion defaultExpanded={false}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        >
            <Stack sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                <Typography sx={{}}>{title}</Typography>
                <CustomTooltip keyString={tooltipKey} >
                    <HelpIcon sx={{marginLeft: 1}}/>
                </CustomTooltip>
                <Typography sx={{flexGrow: 1, textAlign: "right"}}>{count}</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails>

            <FormProvider onSubmit={onSubmit} defaultValues={{behavior: behaviorKey, description: '',location: '', associated_elephants: []}} >
            <CustomForm cb={(cb,ctrl)=>{callbackFunc=cb;control=ctrl}}>
                <Grid container spacing={2}>
                    {description && 
                    <Grid xs={12} sm={12}> 
                        <FormTextField  multiline shrinkLabel
                        rows={4} name="description" label="Description" required {...descriptionProps} />
                    </Grid>
                    }
                    {
                        chips &&
                        <>
                        <BehaviorChips words={chipKey ? chipWords[chipKey] : chipWords[behaviorKey]} onChipPress={(chipText)=>{
                            const existingDescription = control._formValues.description;
                            const updatedDescription = existingDescription ? `${existingDescription}\n${chipText}` : chipText;
                            callbackFunc('description', updatedDescription);
                        }}/>
                        </>
                    }
                    {location && 
                    <Grid xs={12} sm={12}> 
                        <FormTextField name="location" label="Location" required {...locationProps} />
                    </Grid>
                    }
                    {associatedElephants && 
                    <Grid xs={12} sm={12}> 
                        <FormMultiSelectChips enableSelectAll name="associated_elephants" label="Elephants" list={elephants.map((cur)=>{ return {label: cur.name, value: cur.id} })} {...associatedElephantsProps} />
                    </Grid>
                    }
                    <Grid xs={4} sm={4}> 
                        <FormButton>Add</FormButton>
                    </Grid>
                </Grid>
            </CustomForm>
            </FormProvider>

        </AccordionDetails>
    </Accordion>
    );

}

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    return {
        assessment: await getAssessment(params.assessmentId), 
        elephants: await listElephants(), 
        sectionbRecord: await getSectionB(params.sectionBId)
    };
}

export default function AssessmentSectionBFormPage({title = "EWP",...props}) {
    const {setSuccess, setError, setMessage} = useMessage();
    const {hideNavigation,showNavigation} = useLayout();
    const navigate = useNavigate();
    const {confirm} = useConfirmationDialog();
    const {isOnline} = useConnectivity();

    const {isRunning, startTimer, endTimer} = useTimer();

    const {inProgressObservations, current, isClipboardMode, setObservation, removeObservation, stopClipboardMode} = useSectionB();
    
    // used to read the loader data that is returned from the function above
    const params = useParams();
    console.log("Params: ", params);


    //const {assessment, elephants, sectionbRecord} = data || {};
    const {assessment, elephants, sectionbRecord} = useLoaderData();
    console.log("sectionB record from loader: ", sectionbRecord);
    const [sectionb,setSectionb] = React.useState(sectionbRecord);

    React.useEffect(()=>{
        if (isClipboardMode && current) navigate("/assessments/" + current.assessmentId + "/sectionb/" + current.id);

        if (isClipboardMode && current === undefined) {
            stopClipboardMode();
            resetTimer();
            navigate("/assessments/" + assessment.id);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current]);

    // handles a switch using the observation clipboard system
    React.useEffect(()=>{
        setSectionb(sectionbRecord);
    },[sectionbRecord]);

    React.useEffect(()=>{
        const saveChange = async () => {
            console.log("sectionb: ", sectionb);
            //await onChange();
            const saveResult = await saveSectionBLocal(sectionb);
            console.log(saveResult);
        };
        saveChange();
    },[sectionb]);

    console.log("assessment: ", assessment, "elephants:", elephants);

    //const elephant = findModel(elephants || [],assessment?.elephant_id);
    const elephant = findModel(elephants,assessment.elephant_id);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async () => {
        try {
            if (inProgressObservations.length <= 1) showNavigation();
            const data = {...sectionb, submitted_on: today()};
            if(data['other_elephants_nearby'].includes('None')){
                data['other_elephants_nearby'] = [];
            }
            console.log(data);
            //console.log(sectionb);

            const saveResult = await saveSectionB(data,isOnline);
            console.log("sectionb save: ", saveResult);

            setSuccess("Section Data Saved");

            if (inProgressObservations.length > 0) removeObservation(current);

            /*
            if (inProgressObservations.length > 0 && current) {
                navigate("/assessments/" + current.assessmentId + "/sectionb/" + current.id);
            } else {
                navigate("/assessments/" + assessment.id);
            }
            */

            if (isClipboardMode === false && current === undefined) {
                resetTimer();
                navigate("/assessments/" + assessment.id);
            }


        } catch (error) {
            console.error(error);
            setError(error);
        }
    }


    const [showBackArrow,setShowBackArrow] = React.useState(true);
    const [showForm,setShowForm] = React.useState(false);

    // timer state and handler functions
    //const [runTimer,setRunTimer] = React.useState(false);
    const toggleTimer = async () => {
        if (!isRunning) {
            // starts the timer
            console.log("starting timer");
            //setRunTimer(true);
            startTimer(300)
            hideNavigation();
            setShowBackArrow(false);
            // show the main form
            setShowForm(true);
            // set record.observed_on to the curren timestamp locally
            setSectionb({...sectionb, observed_on: today()});


        } else {
            // prompt to confirm
            confirm({
                title: "Reset Observation?",
                message: "Confirming will cause the timer to stop and reset, but this also resets the observation data for this observation",
                onSubmit: async () => {
                    // reset the record
                    const temp = resetSectionBRecord(sectionb);
                    setSectionb({...temp});

                    // hides the form
                    setShowForm(false);
                    
                    // reset the timer
                    resetTimer();
                }
            })
        }
    }

    const resetTimer = () => {
        showNavigation();
        setShowBackArrow(true);
        endTimer();
        //setRunTimer(false);
    }

    const timerFinished = () => {
        setMessage("Timer Finished");
        resetTimer();
    }


    const handleAdd = (inputData) => {
        console.log("Add: ",inputData);

        const newBehavior = {...newSectionBBehavior(),...inputData};
        newBehavior.section_b_id = sectionb.id;
        newBehavior.observed_on = today();
        console.log("new behavior: ", newBehavior);

        const obj = {};
        obj[inputData['behavior']] = sectionb[inputData['behavior']] + 1;
        obj.behaviors = [...sectionb['behaviors'],newBehavior];
        setSectionb({...sectionb, ...obj});
        // record change/save here
    }

    const handleChange = (e) => {
        console.log(e);
        const field = e.target.name;
        const obj = {}
        obj[field] = e.target.value;
        setSectionb({...sectionb,...obj});
    }

    const handleElephantChange = (values,field) => {
        console.log("values: ", values);
        const obj = {}
        obj[field] = values;
        setSectionb({...sectionb,...obj});
    }

    return (
        <Container component="main" maxWidth="sm">
            {sectionb && <>
            {showBackArrow && <GoBack onClick={()=>navigate(`/assessments/${assessment.id}`)} />}
            <Header label={title} />

            {isClipboardMode && inProgressObservations.length > 1 && 
            <Grid container spacing={2}>
                {inProgressObservations.map((cur,index)=>(
                    <Grid key={cur.id} xs={12} sm={6}>
                        <EwpButton onClick={()=>{
                            setObservation(index);
                        }}>{cur?.title}</EwpButton>
                    </Grid>
                ))}
            </Grid>
            }

            <Grid container spacing={2}>
                <Grid xs={6} sm={6}> 
                    <Typography>{elephant.name}</Typography>
                    <Typography>{sectionb.observation_range}</Typography>
                    <Typography>Behavior Count: {sectionb?.behaviors?.length || 0}</Typography>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <EwpButton onClick={toggleTimer}>
                        {(isRunning) ? <Timer onCompletion={timerFinished} /> : <TimerIcon />}
                    </EwpButton>
                </Grid>
                <Grid xs={6} sm={6}> 
                </Grid>
            </Grid>

            <Grid container spacing={2} >
                <Grid xs={12} sm={6}> 
                    <ComponentTextField name="current_weather_conditions" label="Current Weather Conditions" value={sectionb.current_weather_conditions || ''} onChange={handleChange}/>
                </Grid>

                <Grid xs={12} sm={6}> 
                    <ComponentMultiSelectChips 
                        name="other_elephants_nearby" 
                        label="Other Elephants Nearby" 
                        enableSelectNone
                        enableSelectAll
                        list={elephants.map((cur)=>{ return {label: cur.name, value: cur.id} })} 
                        value={sectionb.other_elephants_nearby || []}
                        onChange={(value)=>handleElephantChange(value,"other_elephants_nearby")}
                    />
                </Grid>



                {showForm &&
                <>
                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="wallowing"
                        title="Wallowing"
                        count={sectionb.wallowing}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={false}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="feeding"
                        title="Feeding"
                        count={sectionb.feeding}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={false}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="foraging"
                        title="Foraging"
                        count={sectionb.foraging}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={false}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="locomotion"
                        title="Locomotion"
                        count={sectionb.locomotion}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={false}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="stereotyping"
                        title="Stereotyping"
                        chips
                        count={sectionb.stereotyping}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={true}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="environmental_interaction"
                        title="Environmental Interaction"
                        count={sectionb.environmental_interaction}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="affiliative_giving"
                        title="Affiliative Giving"
                        chips
                        chipKey='affiliative_behavior'
                        count={sectionb.affiliative_giving}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={true}
                        associatedElephantsProps={{label: "Recipient Elephants"}}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="affiliative_receiving"
                        title="Affiliative Receiving"
                        chips
                        chipKey='affiliative_behavior'
                        count={sectionb.affiliative_receiving}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={true}
                        associatedElephantsProps={{label: "Initiating Elephants"}}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="agonistic_giving"
                        title="Agonistic Giving"
                        chips
                        chipKey='agonistic'
                        count={sectionb.agonistic_giving}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={true}
                        associatedElephantsProps={{label: "Recipient Elephants"}}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="agonistic_receiving"
                        title="Agonistic Receiving"
                        chips
                        chipKey='agonistic'
                        count={sectionb.agonistic_receiving}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={true}
                        associatedElephantsProps={{label: "Initiating Elephants"}}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="playing_with_others"
                        title="Playing With Others"
                        count={sectionb.playing_with_others}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        location={false}
                        associatedElephants={true}
                        associatedElephantsProps={{label: "Elephants"}}
                    />
                </Grid>

                <Grid xs={12} sm={12}> 
                    <BehaviorAccordion 
                        behaviorKey="anticipating"
                        title="Anticipating (waiting)"
                        count={sectionb.anticipating}
                        onSubmit={handleAdd}
                        elephants={elephants}
                        description={true}
                        descriptionProps={{label: "Additional Info"}}
                        location={false}
                        associatedElephants={false}
                    />
                </Grid>


                <Grid xs={12} sm={12}> 
                    <ComponentTextField name="comments" label="Additional Comments" value={sectionb.comments || ''} onChange={handleChange} />
                </Grid>



                <Grid xs={12}> 
                    <EwpButton onClick={onSubmit}>Submit</EwpButton>
                </Grid>
                </>
                }

            </Grid>

            </>}
        </Container>
    );
}