import * as React from 'react';

const MessageContext = React.createContext({
    message: null,
    serverity: null,
    hasNotification: false,
    notificationProps: null,
    setMessage: ()=>{},
    setSuccess: ()=>{},
    setWarning: ()=>{},
    setError: ()=>{},
    clear: ()=>{},
});

export const useMessage = () => {
    const context = React.useContext(MessageContext);
    if (!context) {
        throw new Error('useMessage must be used within an MessageProvider');
    }
    return context;
};

export const MessageProvider = props => {
    const [state, dispatch] = React.useReducer(reducer, {
        message: null,
        severity: null,
        hasNotification: false,
    });

    const setMessage = (message,notificationProps) => {
        console.log(message);
        dispatch({type: "MESSAGE", message, severity: "info", notificationProps});
    };

    const setSuccess = (message) => {
        console.log(message);
        dispatch({type: "MESSAGE", message, severity: "success"});
    };

    const setWarning = (message) => {
        console.log(message);
        dispatch({type: "MESSAGE", message, severity: "warning"});
    };

    const setError = (message) => {
        message = (message instanceof Error) ? message.message : message;
        console.error(message);
        dispatch({type: "MESSAGE", message, severity: "error"});
    };

    const clear = () => {
        dispatch({type: "CLEAR"});
    }

    return (
        <MessageContext.Provider value={{
            ...state,
            setMessage,
            setSuccess,
            setWarning,
            setError,
            clear
            }}>
            {props.children} 
        </MessageContext.Provider>
    );
}

const reducer = (prevState,action) => {
    switch (action.type) {
        case "CLEAR":
            return {
                ...prevState,
                hasNotification: false,
                message: null,
                severity: "",
                notificationProps: null,
            }
        case "MESSAGE":
            return {
                ...prevState,
                message: action.message,
                severity: action.severity,
                hasNotification: true,
                notificationProps: action.notificationProps
            }

        // no default
    }
}