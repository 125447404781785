import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration)

const dateLocaleFormat = () => {
    return "MM/DD/YYYY";
} 

const time = (inputTimeString) => {
    if (inputTimeString === null) {
        return null;
    }
    if (inputTimeString === undefined) {
        return dayjs();
    }
    if (inputTimeString instanceof dayjs) {
        return inputTimeString.format("HH:mm");
    }
    return dayjs(inputTimeString,'HH:mm');
}

const date = (inputDateString) => {
    if (inputDateString === null) return null;
    return dayjs(inputDateString).format(dateLocaleFormat());
}

const datetime = (inputDateString) => {
    return date(inputDateString) + " " + time(inputDateString);
}

const today = () => {
    return dayjs();
}

const syncvalue = () => {
    return (Date.now() / 1000)
}

const dateToStorage = (inputValue) => {
    return (inputValue instanceof dayjs) ? inputValue.toDate() : inputValue;
}

const dateFromStorage = (inputValue) => {
    return (inputValue) ? dayjs(inputValue) : inputValue;
}

const timeToStorage = (inputValue) => {
    return (inputValue instanceof dayjs) ? inputValue.format('HH:mm:ss') : inputValue;
}

const timeFromStorage = (inputValue) => {
    return (inputValue) ? dayjs(inputValue, "HH:mm:ss") : inputValue;
}



function halfHourTimeSlots(startDate, endDate) {
    const timeSlots = [];
    if (!startDate.isBefore(endDate)) {
        //Add one day to the endDate
        endDate = endDate.add(1, 'day');
    }
    const roundedStartDate = dayjs(startDate).startOf('minute').add(Math.ceil(startDate.minute() / 30) * 30, 'minute');
    const roundedEndDate = dayjs(endDate).startOf('minute').add(Math.floor(endDate.minute() / 30) * 30, 'minute');

    let currentTime = roundedStartDate;
    // We removed the last time frame as the last one it's supposed to be before opening time
    while (currentTime.isBefore(roundedEndDate)) {
        timeSlots.push(currentTime.format('HH:mm'));
        currentTime = currentTime.add(30, 'minute');
    }

    return timeSlots;
}

const quarterTimeFrame = (startDayJs,endDayJs) => {

    const durationRange = dayjs.duration(endDayJs.diff(startDayJs));

    const quarteredMinutes = durationRange.as("minutes") / 4;

    const first = startDayJs.add(quarteredMinutes,"minute");
    const second = first.add(quarteredMinutes,"minute");
    const third = second.add(quarteredMinutes,"minute");
    //const last = third.add(quarteredMinutes,"minute");

    return {
        start: startDayJs,
        first,
        second,
        third,
        last: endDayJs
    };
}

const workdayTimeRanges = (startDayJs,endDayJs,inputFormat = "HH:mm") => {

    const {start, first, second, third, last} = quarterTimeFrame(startDayJs,endDayJs);

    const ranges = [];

    ranges.push(start.format(inputFormat) + " - " + first.format(inputFormat));
    ranges.push(first.format(inputFormat) + " - " + second.format(inputFormat));
    ranges.push(second.format(inputFormat) + " - " + third.format(inputFormat));
    ranges.push(third.format(inputFormat) + " - " + last.format(inputFormat));

    return ranges;
}


const isDateTimeOverdue = (inputDateTime,facility) => {
    const period = facility.review_period || 1; // in months
    const ts = dayjs(inputDateTime).add(period,'month');
    return today() > ts;
}


export {dateLocaleFormat, time, date, datetime, today, syncvalue, timeToStorage, timeFromStorage, dateToStorage, dateFromStorage, quarterTimeFrame, workdayTimeRanges, halfHourTimeSlots, isDateTimeOverdue};