import * as React from 'react';

import TextField from '@mui/material/TextField';

//import {useForm} from './FormProvider';

export default function ComponentTextField({label,required,isError = false ,errorMessage = null,...props}) {

    //const {register, errors, defaultRules, watch } = useForm();

    //const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    //rules = {...requiredDefaultRule,...rules};

    //if (refValue) {
        //refValue.current = watch(name);
    //}

    return (<TextField 
        label={label} 
        variant="outlined" 
        fullWidth 
        required={required}
        error={isError}
        helperText={errorMessage}
        {...props}
    />);
};