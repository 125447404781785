import * as React from 'react';

import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';


import Header from "../components/Header";
import Subheader from "../components/Subheader";

import {sync as syncFacility, truncateDexie as truncateDexieFacility} from "../models/facility";
import {sync as syncUsers, truncateDexie as truncateDexieUsers } from "../models/users";
import {sync as syncSectionB, truncateDexie as truncateDexieSectionB} from "../models/sectionb";
import {sync as syncSectionA, truncateDexie as truncateDexieSectionA} from "../models/sectiona";
import {sync as syncElephants, truncateDexie as truncateDexieElephants} from '../models/elephants';
import {sync as syncChanges, truncateDexie as truncateDexieChanges} from '../models/changes';
import {sync as syncAssessments, truncateDexie as truncateDexieAssessments} from '../models/assessments';
import {sync as syncFinalEvaluations, truncateDexie as truncateDexieFinalEvaluations} from '../models/finalevaluation';
import {sync as syncSectionC, truncateDexie as truncateDexieSectionC} from "../models/sectionc";
import {sync as syncSectionCIoA, truncateDexie as truncateDexieSectionCIoA} from "../models/sectioncioa";

import { useServiceWorker } from '../hooks/useServiceWorker';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/*
// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    return {objectname: {}};
}
*/


export default function DiagnosticsPage({title = "EWP",...props}) {

    const {setMessage, setError} = useMessage();
    const {facilityId, user} = useAuth();

    const { reloadPage } = useServiceWorker();

    const [showControls, setShowControls] = React.useState(false);


    console.log("ENV: ",process.env);


    const handleSyncFacility = async() => {
        console.log("syncing facility");
        console.log(await syncFacility({}));

        setMessage("Facility Syncronized");
    };

    const handleSyncUsers = async() => {
        console.log("syncing users");
        console.log(await syncUsers({facilityId}));

        setMessage("Users Syncronized");
    };

    const handleSyncChanges = async() => {
        console.log("syncing changes");
        console.log(await syncChanges({facilityId,userId: user.id}));

        setMessage("Changes Syncronized");
    };

    const handleSyncElephants = async() => {
        console.log("syncing elephants");
        console.log(await syncElephants({facilityId}));

        setMessage("Elephants Syncronized");
    };

    const handleSyncAssessments = async() => {
        console.log("syncing assessments");
        console.log(await syncAssessments({facilityId}));

        setMessage("Assessments Syncronized");
    };

    const handleSyncFinalEvaluations = async() => {
        console.log("syncing final evaluations");
        console.log(await syncFinalEvaluations({userId: user.id}));

        setMessage("Final Evaluations Syncronized");
    };

    const handleSyncSectionA = async() => {
        console.log("syncing sectionA");
        console.log(await syncSectionA({}));

        setMessage("Section A Syncronized");
    };

    const handleSyncSectionB = async() => {
        console.log("syncing sectionB");
        console.log(await syncSectionB({}));

        setMessage("Section B Syncronized");
    };

    const handleSyncSectionC = async() => {
        console.log("syncing sectionC");
        console.log(await syncSectionC({}));

        setMessage("Section C Syncronized");
    };

    const handleSyncSectionCIoA = async() => {
        console.log("syncing sectionCIoA");
        console.log(await syncSectionCIoA({}));

        setMessage("Section C: IoA Syncronized");
    };

    const handleLocalAssessmentsWipe = async() => {
        console.log(await truncateDexieAssessments());
        setMessage("Local Assessments Data Cleared");
    }

    const handleLocalFinalEvaluationsWipe = async() => {
        console.log(await truncateDexieFinalEvaluations());
        setMessage("Local Final Evaluations Data Cleared");
    }

    const handleLocalSectionBWipe = async() => {
        console.log(await truncateDexieSectionB());
        setMessage("Local Section B Data Cleared");
    }

    const handleLocalSectionAWipe = async() => {
        console.log(await truncateDexieSectionA());
        setMessage("Local Section A Data Cleared");
    }

    const handleLocalSectionCWipe = async() => {
        console.log(await truncateDexieSectionC());
        setMessage("Local Section C Data Cleared");
    }

    const handleLocalSectionCIoAWipe = async() => {
        console.log(await truncateDexieSectionCIoA());
        setMessage("Local Section C: IoA Data Cleared");
    }

    const handleLocalFacilityWipe = async() => {
        console.log(await truncateDexieFacility());
        setMessage("Local Facility Data Cleared");
    }

    const handleLocalWipe = async() => {
        console.log(await truncateDexieSectionCIoA());
        console.log(await truncateDexieSectionC());
        console.log(await truncateDexieSectionB());
        console.log(await truncateDexieSectionA());
        console.log(await truncateDexieAssessments());
        console.log(await truncateDexieFinalEvaluations());
        console.log(await truncateDexieUsers());
        console.log(await truncateDexieElephants());
        console.log(await truncateDexieChanges());
        console.log(await truncateDexieFacility());

        setMessage("Local Data Cleared");
    }

    /* Handles the operations of the dialog control and reading in the control */

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShowControls = (event) => {

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const code = formJson.code;
        console.log("code:", code, typeof code);
        console.log("val:", process.env['REACT_APP_DIAGNOSTICS_CODE'], typeof process.env['REACT_APP_DIAGNOSTICS_CODE'])

        if (String(code) === String(process.env['REACT_APP_DIAGNOSTICS_CODE'])) {
            setShowControls(true);
        } else {
            setError("Incorrect Code");
        }
        handleClose();
    }


    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">App Name</Typography>    
                    <Typography variant="body2">{process.env.REACT_APP_NAME}</Typography>    
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">App Version</Typography>    
                    <Typography variant="body2">{process.env.REACT_APP_VERSION}</Typography>    
                </Grid>
                <Grid xs={12} sm={12}> 
                    <Typography variant="body1" color="secondary">Git Hash</Typography>    
                    <Typography variant="body2">{process.env.REACT_APP_GIT_HASH}</Typography>    
                </Grid>
                <Grid xs={12} sm={12}> 
                    <Typography variant="body1" color="secondary">API URL</Typography>    
                    <Typography variant="body2">{process.env.REACT_APP_SUPABASE_APP_URL}</Typography>    
                </Grid>
                <Grid xs={12} sm={12}> 
                    <Typography variant="body1" color="secondary">BASE URL</Typography>    
                    <Typography variant="body2">{process.env.REACT_APP_BASE_URL}</Typography>    
                </Grid>
            </Grid>

            {!showControls && <>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid xs={6} sm={6}> 
                        <Button variant="contained" onClick={handleClickOpen}>Advanced Tools</Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                            component: 'form',
                            onSubmit: handleShowControls,
                            }}
                        >
                            <DialogTitle>Subscribe</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                You are entering into advanced tools. Only use these tools if directed. Local application data loss can result.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="code"
                                name="code"
                                label="Access Code"
                                type="password"
                                fullWidth
                                variant="standard"
                            />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Access</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </>}

            {showControls && <>

            <Subheader label="Sync Operations"/>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncFacility}>Sync Facility</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncUsers}>Sync Users</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncElephants}>Sync Elephants</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncChanges}>Sync Changes</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncAssessments}>Sync Assessments</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncFinalEvaluations}>Sync Final Evaluations</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncSectionA}>Sync Section A</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncSectionB}>Sync Section B</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncSectionC}>Sync Section C</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleSyncSectionCIoA}>Sync Section C: IoA</Button>
                </Grid>
            </Grid>

            <Subheader label="Service Worker"/>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={reloadPage}>Force Reload</Button>
                </Grid>
            </Grid>

            <Subheader label="Clear Local Data"/>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalWipe}>Erase All Local Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalAssessmentsWipe}>Erase All Local Assessmenets Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalFinalEvaluationsWipe}>Erase All Local Final Evaluations Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalSectionAWipe}>Erase All Section A Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalSectionBWipe}>Erase All Section B Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalSectionCWipe}>Erase All Section C Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalSectionCIoAWipe}>Erase All Section C: IoA Data</Button>
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Button variant="contained" onClick={handleLocalFacilityWipe}>Erase Facility Data</Button>
                </Grid>
            </Grid>
            </>}



        </Container>
    );
}