import * as React from 'react';

//import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import logo from '../assets/logo.png';
import Image from 'mui-image';


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';

export default function OtpPage() {


  const onSubmit = async(data)=>{
    console.log(data);
  }

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src={logo} width={128} />

          <Typography component="h1" variant="h5">
            One Time Password
          </Typography>
          <FormProvider onSubmit={onSubmit} defaultValues={{email: '', token: ''}}>
            <Form>
                <Grid container spacing={2}>
                    <Grid xs={12}> 
                      <FormTextField name="email" label="Email" required autoComplete="email" autoFocus />
                    </Grid>
                    <Grid xs={12}> 
                      <FormTextField name="token" label="Token" required autoFocus rules={{maxLength: {value: 6, message: "Too Long!"}, minLength: {value: 6, message: "Too Short"}}} />
                    </Grid>
                    <Grid xs={12}> 
                      <FormButton fullWidth>Sign In</FormButton>
                    </Grid>
                  </Grid>
            </Form>
          </FormProvider>

        </Box>
      </Container>
  );
}