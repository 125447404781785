const obj = {
  getItem: (key) => {
    const value = global.localStorage.getItem(key)
    return value ? JSON.parse(value) : null;
  },
  setItem: (key,value) => {
    return global.localStorage.setItem(key,JSON.stringify(value));
  },
  removeItem: (key) => {
    return global.localStorage.removeItem(key);
  },
  getFacility: () => {
    const key = "facility_id";
    const value = global.localStorage.getItem(key)
    return value ? JSON.parse(value) : null;
  },
  setFacility: (value) => {
    const key = "facility_id";
    return global.localStorage.setItem(key,JSON.stringify(value));
  }
}
export default obj;