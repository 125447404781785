import * as React from 'react';
import {useForm} from './FormProvider';

export default function Form({children,noOnSubmit = false,...props}) {
    const {handleSubmit,onSubmit} = useForm();

    const contextFunc = (onSubmit && noOnSubmit === false) ? handleSubmit(onSubmit) : null;

    return (
        <form onSubmit={contextFunc} noValidate {...props}>
            {children}
        </form>
    )
}