import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LoginIcon from '@mui/icons-material/Login';
import KeyIcon from '@mui/icons-material/Key';
import ElephantIcon from '../components/icons/ElephantIcon';
import ViewListIcon from '@mui/icons-material/ViewList';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PeopleIcon from '@mui/icons-material/People';

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import CustomTooltip from '../components/tooltip/CustomTooltip';

// A custom theme for this app
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#00554A',
    },
    secondary: {
      //main: '#52B2C0',
      main: '#1A65AB',
    },
    error: {
      main: red.A400,
    },
    badge: {
      main: "#E0E1DD",
      contrastText: "#000000"
    },
    activechange: {
      main: "#95B7B0"
    },
    completedchange: {
      main: "#D1E0DD"
    },
    start: {
      main: "#4CAF50"
    },
    inprogress: {
      main: "#FFA64D",
    },
    done: {
      main: "#80AAFF",
    },
    overdue: {
      main: "#990000",
      contrastText: "#FFFFFF"
    }
  },
});

const icons = {
  HOME: <HomeIcon />,
  CHANGE: <EditNoteIcon />,
  CHANGELOG: <ViewListIcon />,
  ASSESSMENT: <AssignmentIcon />,
  ELEPHANT: <ElephantIcon color={defaultTheme.palette.primary.main}/>,
  LOGIN: <LoginIcon />,
  FORGOTPASSWORD: <KeyIcon />,
  DIAGNOSTICS: <AnalyticsIcon />,
  USERS: <PeopleIcon />,

  START: <CustomTooltip keyString="start_section"><PlayCircleFilledIcon color="start"/></CustomTooltip>,
  INPROGRESS: <CustomTooltip keyString="inprogress_section"><PendingIcon color="inprogress"/></CustomTooltip>,
  COMPLETE: <CustomTooltip keyString="completed_section"><CheckCircleIcon color="done"/></CustomTooltip>,
}

const theme = {...defaultTheme,icons, mobileBreakPoint: defaultTheme.breakpoints.up('sm')};

export default theme;