import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import DetailsHeader from '../DetailsHeader';
import DetailsContent from '../DetailsContent';

import {date} from "../../utils/datetime";

import { sex as sexList, genus as genusList} from "../../models/categories";
import { useQuery } from '@tanstack/react-query'
import { listLabel } from "../../utils/lists";

export default function ElephantDetails({elephant,...props}) {

    console.log(elephant);

    const {isLoading, data} = useQuery({
        queryKey: ['sexgenus'],
        queryFn: async() => {
            return {
                sex: await sexList(),
                genus: await genusList(),
            };
        },
        networkMode: "offlineFirst",
    });

    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid xs={6} sm={6}> 
                <DetailsHeader>Sex</DetailsHeader>
                <DetailsContent>{!isLoading && listLabel(elephant.sex,data.sex)}</DetailsContent>
            </Grid>
            <Grid xs={6} sm={6}> 
                <DetailsHeader>Genus</DetailsHeader>
                <DetailsContent>{!isLoading && listLabel(elephant.genus,data.genus)}</DetailsContent>
            </Grid>
            <Grid xs={6} sm={6}> 
                <DetailsHeader>Date Born</DetailsHeader>
                <DetailsContent>{date(elephant.born_on)} ({(elephant.born_on_is_estimated) ? "Estimated" : "Known"})</DetailsContent>
            </Grid>

            {elephant.died_on && <Grid xs={6} sm={6}> 
                <DetailsHeader>Date of Death</DetailsHeader>
                <DetailsContent>{date(elephant.died_on)}</DetailsContent>
            </Grid>}

            <Grid xs={12} sm={6}> 
                <DetailsHeader>Date Entered Captivity</DetailsHeader>
                <DetailsContent>{date(elephant.entered_captivity_on)} ({(elephant.entered_captivity_on_is_estimated) ? "Estimated" : "Known"})</DetailsContent>
            </Grid>
            <Grid xs={12} sm={6}> 
                <DetailsHeader>Date Arrived At Current Institution</DetailsHeader>
                <DetailsContent>{date(elephant.arrived_on)} ({(elephant.arrived_on_is_estimated) ? "Estimated" : "Known"})</DetailsContent>
            </Grid>
        </Grid>
    );
}