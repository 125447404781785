import * as React from 'react';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';


import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';

const style = {
  position: 'absolute',
  minHeight: '30%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 12,
  borderRadius: "4px",
  p: 2,
};

function EditToolbar(props) {
  const { handleOpen, buttonText } = props;

  const handleClick = () => {
    handleOpen()();
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {buttonText}
      </Button>
    </GridToolbarContainer>
  );
}
/*
Example of initialization

    // or list of objects that match the column structure with an ID field
    const initialRows = [
      {
        id: randomId(),
        description: randomTraderName(),
        frequency: randomTraderName(),
        time_of_day: randomCreatedDate(),
        location: randomTraderName(),
      },
      {
        id: randomId(),
        description: randomTraderName(),
        frequency: randomTraderName(),
        time_of_day: randomCreatedDate(),
        location: randomTraderName(),
      },
      {
        id: randomId(),
        description: randomTraderName(),
        frequency: randomTraderName(),
        time_of_day: randomCreatedDate(),
        location: randomTraderName(),
      },
    ];

    const [rows, setRows] = React.useState(initialRows);

    const newRow = () => {
        return {
            id: uuidv4(),
            name: '',
            age: '',
            department: 'Foo'
        };
    }

    // these define the column(s) to show information on the data grid, use the normal Data Grid column defintion here.
    const columns = [
        { 
            field: 'description', 
            headerName: 'Description', 
        },
    ];

    // initialization of component

    rows: the array of objects that represents the data we're working with
    setRows: the state update function
    columns: the list of columns to show in the data grid
    makeRow: the function to invoke when a new record needs to be added to the rows array
    formRender:  A component structure that renders out a form, using the form components. This form is rendered in a modal when adding or editing records.


        <CRUDDataGridModal 
            rows={rows}
            setRows={setRows}
            columns={columns}
            makeRow={newRow}
            formRender={(defaultValues,onSubmit,onCancel)=>{
                return (
                <FormProvider defaultValues={defaultValues} >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12}> 
                                <FormTextField name="description" label="Description" required />
                            </Grid>
                            <Grid xs={6} sm={6}> 
                                <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                            </Grid>
                            <Grid xs={6} sm={6}> 
                                <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                            </Grid>
                        </Grid>

                    </Form>
                </FormProvider>
                );
            }}
        />
    
*/
export default function CRUDDataGridModal({rows,setRows,columns,makeRow,idField = 'id',buttonText = 'Add Row',boxProps = {},dataGridProps = {},formRender,...props}) {

  const [open,setOpen] = React.useState(false);

  const handleDeleteClick = (id) => () => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      //width: 100,
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleOpen(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    };

  const templateColumn = {
    editable: false,
    flex: 1
  }


  const mergedColumns = columns.map((column)=>{
    return {...templateColumn,...column};
  });
  mergedColumns.push(actionColumn);


  const [modalForm,setModalForm] = React.useState(null);


  const handleOpen = (id) => () => {
    const record = rows.filter((cur)=>cur.id === id)[0] || makeRow();
    console.log("using record: ",record);
    setModalForm(formRender(record,handleSave,handleClose));
    setOpen(true);
  }

  const handleSave = async (newRow) => {
      console.log("form save record: ", newRow);
      const updatedRow = { ...newRow};
      const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      if (rows.map((row)=>row.id).includes(newRow.id) === false) newRows.push(newRow);
      setRows(newRows);
      setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
    <Box
      sx={{
        //width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
      {...boxProps}
    >
      <DataGrid
        rows={rows}
        columns={mergedColumns}
        hideFooter={true}
        disableRowSelectionOnClick
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { handleOpen, buttonText }
        }}
        {...dataGridProps}
      />



    </Box>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="changes-modal-title"
      aria-describedby="changes-modal-description"
    >
        <Container maxWidth="xs" sx={style}>
          <Typography>Test</Typography>
          {modalForm}
        </Container>
    </Modal>
    </>
  );
}