import * as React from 'react';

import {useNavigate} from 'react-router-dom';
import {useAuth} from '../context/AuthProvider';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';


import Header from "../components/Header";

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    return {objectname: {}};
}


export default function ProfilePage({title = "EWP",...props}) {
    const navigate = useNavigate();
    const {user, facilityId, signOut} = useAuth();

    // used to read the loader data that is returned from the function above
    /*
    const {objectname} = useLoaderData(); 
    */

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

    const handleSignOut = async() => {
        await signOut();
        navigate("/");
    }
    const handleChangePassword = async() => {
        navigate("/profile/change_password");
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <>
        {user && <>
        <Container component="main" maxWidth="sm">
            <Header label={title} />

        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">User ID</Typography>    
                <Typography variant="body2">{user.id}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Email</Typography>    
                <Typography variant="body2">{user.email}</Typography>    
            </Grid>
            <Grid xs={6} sm={6}> 
                <Typography variant="body1" color="secondary">Facility ID</Typography>    
                <Typography variant="body2">{facilityId}</Typography>    
            </Grid>

            <Grid xs={12} sm={12}> 
                <Button variant="contained" fullWidth onClick={handleChangePassword}>Change Password</Button>
            </Grid>

            <Grid xs={12} sm={12}> 
                <Button variant="contained" fullWidth onClick={handleSignOut}>Sign Out</Button>
            </Grid>
        </Grid>
        </Container>
        </>}
        {!user && 
            <Button onClick={()=>{
                navigate('/');
            }}>Log Back In</Button>
        }
        </>
    );
}