import * as React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
} from '@mui/x-data-grid-generator';

import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';

import CRUDDataGridModal from '../components/CRUDDataGridModal';

import { v4 as uuidv4 } from 'uuid';

const initialRows = [
  {
    id: randomId(),
    description: randomTraderName(),
    frequency: randomTraderName(),
    time_of_day: randomCreatedDate(),
    location: randomTraderName(),
  },
  {
    id: randomId(),
    description: randomTraderName(),
    frequency: randomTraderName(),
    time_of_day: randomCreatedDate(),
    location: randomTraderName(),
  },
  {
    id: randomId(),
    description: randomTraderName(),
    frequency: randomTraderName(),
    time_of_day: randomCreatedDate(),
    location: randomTraderName(),
  },
];


export default function TestDataGrid({title = "EWP",...props}) {

  const [rows, setRows] = React.useState(initialRows);

  React.useEffect(()=>{
    console.log(rows);
  },[rows]);

  const newRow = () => {
    return {
        id: uuidv4(),
        name: '',
        age: '',
        department: 'Foo'
    };
  }

  const columns = [
        { 
            field: 'description', 
            headerName: 'Description', 
            valueGetter: ({ row }) => {
              return row.description + row.frequency;
            },
        },
    ];

  return (
    <Container component="main" maxWidth="xs">
        <CRUDDataGridModal 
            rows={rows}
            setRows={setRows}
            columns={columns}
            makeRow={newRow}
            formRender={(defaultValues,onSubmit,onCancel)=>{
                return (
                <FormProvider defaultValues={defaultValues} >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12}> 
                                <FormTextField name="description" label="Description" required />
                            </Grid>
                            <Grid xs={6} sm={6}> 
                                <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                            </Grid>
                            <Grid xs={6} sm={6}> 
                                <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                            </Grid>
                        </Grid>

                    </Form>
                </FormProvider>
                );
            }}
        />
    </Container>
  );

}