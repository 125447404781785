import * as React from 'react';

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { Controller } from "react-hook-form"

import {useForm} from './FormProvider';

export default function FormTextField({name,label,list,required,fullWidth,defaultValue=null,rules = {},...props}) {

    const {control, errors, defaultRules} = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    fullWidth = (fullWidth === undefined) ? true : fullWidth;

    const labelId = name + "-label";

    //const watchValue = watch(name);

    return (

        <Controller
            render={({field: {onChange, value, onBlur}})=>
                <FormControl fullWidth={fullWidth} error={(errors[name]) ? true : false}>
                    {label && <FormLabel id={labelId}>{label}</FormLabel>}
                    <RadioGroup
                        aria-labelledby={labelId}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue={defaultValue}
                    >
                        {list.map((cur)=>(<Box key={cur.value || cur.label} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><FormControlLabel key={cur.value || cur.label} value={cur.value} control={<Radio />} label={cur.label} /><Box>{cur.tooltip}</Box></Box>))}
                    </RadioGroup>
                    {errors[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
                </FormControl>
            }
            name={name}
            control={control}
            rules={rules}
        />


    );
};