const observationStatus = (section) => {

    if (section.completed_on) {
        return "Completed";
    } else if (section.submitted_on && !section.completed_on) {
        return "Submitted";
    } else if (section.observed_on && !section.submitted_on && !section.completed_on) {
        return "In Progress";
    }
}

const inProgressObservation = (sections) => {
    const inProgress = sections.filter((cur)=>{
        return observationStatus(cur) === "In Progress";
    });

    return (inProgress.length === 1) ? inProgress[0] : null;
};

/*
const sectionInProgress = (sections) => {
    const inProgress = sections.filter((cur)=>{
        return observationStatus(cur) === "In Progress";
    });
    return (inProgress.length > 0) ? true : false;
}
*/

const overallSectionStatus = (sections) => {
    const allCompleted = sections.filter((cur)=>{
        return cur.completed_on;
    });
    if (allCompleted.length === sections.length && sections.length > 0) return "Completed";

    const submitted = sections.filter((cur)=>{
        return cur.submitted_on;
    });

    if (submitted.length === sections.length && sections.length > 0) return "All Submitted";

    if (sections.length > 0) return "In Progress";


    return "Not Started";
}

const finalEvaluationStatus = (finalEvaluation) => {

    if (finalEvaluation && 'completed_on' in finalEvaluation && finalEvaluation['completed_on']) {
        return "Completed";
    } else if (finalEvaluation) {
        return "In Progress";
    } else {
        return "Not Started";
    }
}


const booleanToInt=(input)=>{
    return (input) ? 1 : 0;
}

const intToBoolean=(input)=>{
    return (input === 1) ? true : false;
}

export {observationStatus, overallSectionStatus, inProgressObservation, booleanToInt, intToBoolean, finalEvaluationStatus};