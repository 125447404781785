import EditIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from '@mui/material';
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Stack from '@mui/material/Stack';
import Spinner from "../Spinner";

import { useTheme } from '@mui/material/styles';
import { useLayout } from "../layout/Layout";

import { isInProgress } from "../../utils/assessments";
import { overallSectionStatus, finalEvaluationStatus} from '../../utils/sections';
import { dateLocaleFormat } from "../../utils/datetime";

import { listSectionAsByAssessment } from '../../models/sectiona';
import { listSectionBsByAssessment } from '../../models/sectionb';
import { listSectionCsByAssessment } from '../../models/sectionc';
import { listSectionCIoAsByAssessment } from '../../models/sectioncioa';
import { getFinalEvaluation } from '../../models/finalevaluation';

import { useQuery } from '@tanstack/react-query';

import ElephantChangeSummary from "../ElephantChangeSummary";

const StatusIcon = ({status,...props}) => {
  const theme = useTheme();
  return (
    <>
      {(status === "Not Started") && theme.icons.START}
      {(status === "In Progress" || status === "All Submitted") && theme.icons.INPROGRESS}
      {(status === "Completed") && theme.icons.COMPLETE}
    </>
  );


}

export default function AssessmentCard({ assessment, elephant, changes = [],  onActionAreaClick }) {
  const {openChangesModal} = useLayout();

  const isInProgressStatus = isInProgress(assessment);

  const {isLoading, data} = useQuery({
    queryKey: ['allsections',assessment.id],
    queryFn: async() => {
        return {
            sectionAs: await listSectionAsByAssessment(assessment.id),
            sectionBs: await listSectionBsByAssessment(assessment.id),
            sectionCs: await listSectionCsByAssessment(assessment.id),
            sectionCIoAs: await listSectionCIoAsByAssessment(assessment.id),
            finalevaluation: await getFinalEvaluation(assessment.id),
        };
    },
    networkMode: "offlineFirst",
  });

  const statusSectionA = overallSectionStatus(data?.sectionAs || []);
  const statusSectionB = overallSectionStatus(data?.sectionBs || []);
  const statusSectionC = overallSectionStatus(data?.sectionCs || []);
  const statusFinalEvaluation = finalEvaluationStatus(data?.finalevaluation || undefined);
  //const statusSectionCIoA = overallSectionStatus((data?.sectionCIoAs || []).map((cur)=>{ cur.submitted_on = cur.incident_time; return cur; }));

  const handleAddChange = () => {
    openChangesModal({elephant});
  }

  return (
      <>
        {isLoading && <Spinner />}
        {!isLoading && 
        <Card style={{ height:"100%" }}>
          <CardActionArea onClick={onActionAreaClick}>
            <CardHeader
              title={elephant.name}
              titleTypographyProps={{ variant: "h6" }}
              subheader={elephant.studbook_number}
            />
            <Divider />
            <CardContent>


              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid xs={6} sm={6}> 
                  <Typography gutterBottom>
                    Started: {assessment?.started_on?.format(dateLocaleFormat())}
                  </Typography>

                  {assessment.submitted_on && 
                  <Typography gutterBottom>
                    Submitted: {assessment?.submitted_on?.format(dateLocaleFormat())}
                  </Typography>
                  }

                  <Typography gutterBottom>
                    Status: {isInProgressStatus ? "In Progress" : "Submitted"}
                  </Typography>
                </Grid>
                <Grid xs={6} sm={6}> 
                  {isInProgressStatus && 
                    <>
                      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Typography>Section A:</Typography><StatusIcon status={statusSectionA} />
                      </Stack>

                      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Typography>Section B:</Typography><StatusIcon status={statusSectionB} />
                      </Stack>

                      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Typography>Section C:</Typography><StatusIcon status={statusSectionC} />
                      </Stack>
                      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Typography>Final Eval:</Typography><StatusIcon status={statusFinalEvaluation} />
                      </Stack>
                    </>
                  }
                  {!isInProgressStatus && assessment.report_path &&
                    <>
                      <Typography variant="body1">{assessment?.report_title}</Typography>
                      <Typography variant="body2">{assessment?.report_range}</Typography>
                    </>
                  }
                </Grid>
                <Grid xs={12} sm={12}> 
                  <ElephantChangeSummary elephant={elephant} changes={changes} />
                </Grid>
              </Grid>




            </CardContent>
          </CardActionArea>
          {isInProgressStatus && 
            <CardActions>
                <Button variant="contained" size="small" onClick={handleAddChange}>
                  Add Change <EditIcon fontSize="14" />
                </Button> 
            </CardActions>
          }
        </Card>
        }
      </>
  );
}
/*

        <Tooltip
          title={
            assessment.submitted_on
              ? "Create new assessment"
              : "Submit assessment"
          }
          placement="top"
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: "50%",
              minWidth: 0,
              width: "36px",
              height: "36px",
              position: "absolute",
              padding: 0,
              zIndex: 100,
              top: -10,
              right: -10,
            }}
          >
            {!assessment.submitted_on ? <UploadIcon /> : <AddIcon />}
          </Button>
        </Tooltip>
        */