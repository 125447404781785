import React, { createContext, useContext, useReducer } from 'react';
import {useAuth} from '../context/AuthProvider';
import {useConnectivity} from '../context/ConnectivityProvider';

import { sync as syncUsers } from '../models/users';
import { sync as syncFacility } from '../models/facility';
import { sync as syncElephants } from '../models/elephants';
import { sync as syncChanges } from '../models/changes';
import { sync as syncAssessments } from '../models/assessments';
import { sync as syncFinalEvaluations } from '../models/finalevaluation';
import { sync as syncSectionA } from "../models/sectiona";
import { sync as syncSectionB } from "../models/sectionb";
import { sync as syncSectionC } from "../models/sectionc";
import { sync as syncSectionCIoA } from "../models/sectioncioa";

import { syncvalue } from '../utils/datetime';
import asyncstorage from '../lib/asyncstorage';


const SyncContext = createContext({
    isSyncing: false,
    message: 'Syncing',
    startSync: ()=>{},
    endSync: ()=>{},
    performSync: async ()=>{}
});

export function useSync() {
    return useContext(SyncContext);
}

export function SyncProvider(props) {

    const {facilityId, user} = useAuth();
    const {isOnline} = useConnectivity();

    const [state, dispatch] = useReducer(reducer, {
        isSyncing: false,
        message: 'Syncing'
    });

    const startSync = () => {
        dispatch({ type: "START_SYNC" });
    };

    const endSync = () => {
        dispatch({ type: "END_SYNC" });
    };

    const updateMessage = (message) => {
        dispatch({type: "UPDATE_MESSAGE", message});
    }

    /*
    const sleep = (ms) => {
        return new Promise((resolve)=>{
            setTimeout(resolve,ms);
        });
    }
    */

    const performSync = async (doFullSync) => {

        if (isOnline) {


            const key = 'lastsync_' + facilityId;
            // if not a full sync, see if e have a last synced at. 

            const lastSync = (doFullSync) ? null : await asyncstorage.getItem(key) || null;
            const inputs = {facilityId,userId: user.id, lastSync};

            console.log("performSync: ", facilityId,user.id);

            startSync();

            updateMessage("Users");
            await syncUsers(inputs);

            updateMessage("Facility");
            await syncFacility(inputs);

            updateMessage("Elephants");
            await syncElephants(inputs);

            updateMessage("Changes");
            await syncChanges(inputs);

            updateMessage("Assessments");
            await syncAssessments(inputs);

            updateMessage("Final Evaluations");
            await syncFinalEvaluations(inputs);

            updateMessage("Section A");
            await syncSectionA(inputs);

            updateMessage("Section B");
            await syncSectionB(inputs);

            updateMessage("Section C");
            await syncSectionC(inputs);

            updateMessage("Section C: IoA");
            await syncSectionCIoA(inputs);

            // timestamp once sync is done
            const syncValue = syncvalue();
            await asyncstorage.setItem(key,syncValue);

            endSync();
        }
    }

    // do a useEffect here to track when the isOnline changes
    // need to work up a way to determine that we are in a sync and not to run a second one. 
        // memo or something?

    return (
        <SyncContext.Provider value={{
            isSyncing: state.isSyncing,
            message: state.message,
            performSync,
            startSync,
            endSync
        }}>
            {props.children}
        </SyncContext.Provider>
    );
}

const reducer = (prevState, action) => {
    switch (action.type) {
        case "UPDATE_MESSAGE": 
            return {
                ...prevState,
                message: action.message || 'Syncing'
            };
        case "START_SYNC":
            return {
                ...prevState,
                isSyncing: true,
                message: 'Syncing'
            };
        case "END_SYNC":
            return {
                ...prevState,
                isSyncing: false,
                message: 'Syncing'
            };
        default:
            return prevState;
    }
};
