import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ElephantIcon from './icons/ElephantIcon';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';


export default function ElephantNameList({elephants,showLink = false,max = null,...props}) {
    const theme = useTheme();

    const list = (max) ? elephants.slice(0,max) : elephants;
    const remainder = (max) ? elephants.length - list.length : 0;
    const remainderText = "+ " + remainder + " More";

    return (
      <List disablePadding>
        {list.map((elephant) => (
          <ListItem key={elephant.id} disablePadding>
            <ListItemIcon>
              <ElephantIcon />
            </ListItemIcon>
            {showLink ? (
              <Link style={{color:theme.palette.secondary.main}} to={"/elephants/" + elephant.id}>
                <ListItemText primary={elephant.name} />
              </Link>
            ) : (
              <ListItemText primary={elephant.name} />
            )}
          </ListItem>
        ))}

        {max && list.length === max && remainder > 0 &&
          <ListItem disablePadding>
            <ListItemText primary={remainderText} />
          </ListItem>
        }

      </List>
    );
}