export const tooltipStrings={
   "defaultInfo": "This is an info text",

   "start_section": "This section has not been started yet",
   "inprogress_section": "This section is currently in progress",
   "completed_section": "This section is complete",

   "add_to_clipboard": "Add this observation to the clipboard",

   "section_a_content": "Appears at ease, tranquil, seems satisfied",
   "section_a_relaxed": "Peaceful, seems free from tension.",
   "section_a_uncomfortable": "Ill at ease without a clear context for any distress. Body, trunk, head postures un-relaxed and possibly changing frequently, appear fidgety.",
   "section_a_agitated": "A state of uncertainty which can be accompanied by physical restlessness and over-reaction to stimuli e.g. trumpeting. Scanning environment in a tense and anxious fashion.",
   "section_a_tense": "Body, head, trunk, held in a rigid fashion, un-relaxed reactions to stimuli.",
   "section_a_frustrated": "Reacting to seeking a goal without success; can be violent (kicking, tusking, whacking with trunk, head pushing with body, head-on charge) towards others or objects or take the form of tossing objects about as a displacement activity. Angry body posture.",
   "section_a_wary": "Sometimes nervous, paused reaction to some stimuli, unwilling to move in or out of an area, may be accompanied by listening and smelling. It is a slow and calm behaviour.",
   "section_a_playful": "Engaged in a bout of object, locomotory or social play. Responds positively to solicitations for play.",
   "section_a_sociable": "TBD",
   "section_a_lively": "The animal is attentive, animated, full of life and expressing energy. This is taken to be a positive emotion, but can include occasional and passing wary reactivity towards other animals or features of the environment.",
   "section_a_lethargic": "Slow and lacklustre in movement and response to the environment. Seems dull, unresponsive, exuding general lack of vigour and energy, perhaps even closed off from the surroundings, showing little emotion.",

   "section_b_wallowing": "Lying down and rolling in mud",
   "section_b_feeding": "Manipulation and breaking up of food stuffs, and consumption of food",
   "section_b_foraging": "Searching for and collecting food stuffs using the trunkor foot, may kick up grasses, shake food in the trunk or beat against the leg",
   "section_b_locomotion": "Locomotion refers to the elephant's movement from place to place.",
   "section_b_stereotyping": "Repetitive behaviour with no obvious purpose (e.g. head bobbing, swaying/weaving, trunk tossing, heading pressing, pacing, leg swing, rocking, tusk banging, or bar biting)",
   "section_b_environmental_interaction": "Investigating or interacting with non-food items in the environment.  May include: playing with/manipulcating an object, environmental investigation,  interacting with water (shower, pool); provide details in text box",
   "section_b_affiliative_giving": "Initiating any positive or neutral interaction with another elephant.  May include: Leaning on, standing underneath or above another elephant, trunk-touching, tail holding, offering food, and play behaviour",
   "section_b_affiliative_receiving": "Receiving any any positive or neutral interaction with another elephant.  May include: being leaned on, standing underneath or above another elephant, trunk-touching, tail holding, being offered food, and play behaviour",
   "section_b_agonistic_giving": "Giving any negative social behaviour (e.g. tusking, charging, biting, kicking, striking, pushing, stand offs, smacking and food stealing)",
   "section_b_agonistic_receiving": "Receiving any negative social behaviour (e.g. tusking, charging, biting, kicking, striking, pushing, stand offs, smacking and food stealing)",
   "section_b_playing_with_others": "Actively playing with another elephant(eg head to head sparring, trunk wrestlig, mounting, chasing, and rolling on one another).  Does not include behaviours following an antagnoistic encounter or as part of courtship.",
   "section_b_anticipating": "Standing alert, often near (within two elephant body lengths of) gates or enclosure bars prior to an event (e.g. feeding, moving inside, etc.).",

   "section_c_incidents_help_text":"Incidents of aggression should be filled in before starting section C",
   "section_c_feeding": "Manipulation (including breaking up of sood stuffs using the trunk or foot) and consumption of food.",
   "section_c_lying_down_alone": "Lateral recumbency alone.  No other behaviours are being performed.",
   "section_c_lying_down_with_others": "Lateral recumbency with (adjacent to or touching) others.  No other behaviours are being performed.",
   "section_c_stereotyping": "Repetitive behaviour with no obvious purpose (e.g. head bobbing, swaying/weaving, trunk tossing, head pressing, pacing, leg swing, rocking, tusk banging, bar biting)",
   "section_c_self_maintenance": "May include (not limited to): Wallowing, Rubbing, Rolling, Scratching, Scratching with tool, Throwing grass or straw on self, Dust bath",
   "section_c_interaction_with_environment": "Investigating or interacting with things in the environment (other than food).  May include but is not limited to: playing with an object and object manipulation, environmental investigation, playing with water.",
   "section_c_social_affiliative": "Actively seeks social contact or positively responds to, other elephants, without  aversion, withdral or isolation.  Appears friendly and, enjoying/taking comfort from this contact",
   "section_c_other": "NA",
   "section_c_out_of_view": "NA",
}