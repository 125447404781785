
const obj = {
  getItem: async (key) => {
    const value = global.localStorage.getItem(key)
    return value ? JSON.parse(value) : null;
  },
  setItem: async (key,value) => {
    return global.localStorage.setItem(key,JSON.stringify(value));
  },
  removeItem: async (key) => {
    return global.localStorage.removeItem(key);
  }
}

export default obj;