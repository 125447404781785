import * as React from "react";
import { useLoaderData, useNavigate} from 'react-router-dom';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import { listElephants } from "../models/elephants";
import { listAssessments } from "../models/assessments";
import { listChanges } from "../models/changes";
import AssessmentCard from "../components/cards/AssessmentCard";
import EwpFab from "../components/EwpFab";
import EwpButton from "../components/EwpButton";

import { useSectionB } from '../context/SectionBProvider';
import {useMessage} from "../context/MessageProvider";

export async function loader() {
  const elephants = await listElephants();
  const assessments = await listAssessments();
  const changes = await listChanges();
  return { elephants, assessments, changes };
}

export default function AssessmentsListPage({ title = "Assessments", ...props }) {

  const { elephants, assessments, changes} = useLoaderData();
  const navigate = useNavigate();
  const {setSuccess} = useMessage();

  const {inProgressObservations, current, clearObservations, startClipboardMode} = useSectionB();

  const sortedAssessments = assessments
  ? [...assessments].sort((a, b) => {
      // Sort by status (completed first)
      if (!a.submitted_on && b.submitted_on) {
        return -1; 
      } else if (a.submitted_on && !b.submitted_on) {
        return 1;
      }
      // If both assessments have the same status, sort by most recent first
      return new Date(b.created_at) - new Date(a.created_at);
    })
  : []; 
  const groupedAssessments = sortedAssessments.reduce((groups, assessment) => {
    const status = !assessment.submitted_on ? "In Progress" : "Completed";
    if (!groups[status]) {
      groups[status] = [];
    }
    groups[status].push(assessment);
    return groups;
  }, {});

  /*
  const inProgressAssessments = assessments.filter((cur)=>{
    return cur.started_on && !cur.submitted_on;
  });

  const completeAssessments = assessments.filter((cur)=>{
    return cur.started_on && cur.submitted_on;
  });
  */

  const handleStartObservations = () => {
    startClipboardMode();
    navigate("/assessments/" + current.assessmentId + "/sectionb/" + current.id);
  }

  const handleClearObservations = () => {
    clearObservations();
    setSuccess("Section B observation clipboard cleared");
  }

  const navigateToAssessment = (id) => {
    console.log("assessment id: ", id);
    navigate("/assessments/" + id);
  }

  return (
    <Container component="main" maxWidth="md">
    <Header label={title} />

    {inProgressObservations.length > 0 && <>
      <Subheader label="Section B Observations Clipboard" />
      <Grid container spacing={2}>
        <Grid xs={12}>
          {inProgressObservations.map((cur)=>{
            return cur.title
          }).join(", ")}

        </Grid>
        <Grid xs={12} sm={4}>
          <EwpButton onClick={handleStartObservations}>Start Observations</EwpButton>
        </Grid>
        <Grid xs={12} sm={4}>
          <EwpButton onClick={handleClearObservations}>Clear Clipboard</EwpButton>
        </Grid>
      </Grid>
    </>}

    {Object.entries(groupedAssessments).map(([status, assessments]) => (
      <div key={status}>
        <h3>{status}</h3>
        <Grid container spacing={2}>
          {assessments.map((assessment) => {
            return (
              <Grid key={assessment.id} xs={12} sm={6} md={4}>
                <AssessmentCard
                  elephant={elephants.find((el) => el.id === assessment.elephant_id)}
                  assessment={assessment}
                  changes={changes}
                  onActionAreaClick={() => {
                    navigateToAssessment(assessment.id);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    ))}
    <EwpFab onClick={() => navigate("/assessments/new")}>
      <AddIcon />
    </EwpFab>
  </Container>
  );
}