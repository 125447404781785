import * as React from 'react';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';


export default function FormLabel({children,...props}) {

    const theme = useTheme();

    return (
        <Typography sx={{color: theme.palette.text.secondary}} {...props}>{children}</Typography>
    );
};