export const chipWords = {
  stereotyping: [
    "Swaying",
    "Pacing",
    "Rocking",
    "Head bobbing",
    "Trunk swinging",
    "Foot tapping",
    "Foot/leg swing",
    "Head banging",
    "Bar biting",
  ],
  affiliative_behavior: [
    "Sparring",
    "Eating together",
    "Wallowing together",
    "Standing sleep next to each other",
    "Trunk to mouth",
    "Nursing",
  ],
  agonistic: [
    "Chasing",
    "Tusking",
    "Fighting",
    "Tail biting",
    "Smacking (with trunk)",
    "Mounting (from a dominance perspective)",
    "Tail pull",
  ],
};
