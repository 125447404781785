import * as React from 'react';

import { useLoaderData } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useMessage } from '../context/MessageProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';


import {FormProvider} from '../components/form/FormProvider';
import FormOnChange from '../components/form/FormOnChange';
import FormDropDownAndUsed from '../components/form/FormDropDownAndUsed';
import FormButton from '../components/form/FormButton';

import Header from "../components/Header";
import Subtitle from "../components/Subtitle";
import GoBack from "../components/GoBack";

import { newSectionB, saveSectionB, listSectionBsByAssessment,listSectionBsThatStartedObservationRange } from '../models/sectionb';
import { listElephants } from '../models/elephants';
import { getFacility } from '../models/facility';
import { getAssessment, listAssessments } from '../models/assessments';

import {today, workdayTimeRanges} from "../utils/datetime";

import { useConnectivity } from '../context/ConnectivityProvider';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { findModel } from '../utils/model';
import { isInProgress } from '../utils/assessments';

import { useSectionB } from '../context/SectionBProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {

    const assessments = await listAssessments();
    const assessment = await getAssessment(params.assessmentId);
    const sectionBs = await listSectionBsByAssessment(assessment.id);

    return {facility: await getFacility(), elephants: await listElephants(), assessments, assessment, sectionBs, newSectionB: newSectionB()};
}

export default function AssessmentSectionBSelectPage({title = "EWP",...props}) {

    const {setSuccess} = useMessage();
    const {facility, elephants, assessment, assessments, sectionBs, newSectionB} = useLoaderData(); 
    const navigate = useNavigate();
    const {user} = useAuth();
    const {isOnline} = useConnectivity();

    const {addObservation} = useSectionB();

    const [useClipboard,setUseClipboard] = React.useState(false)
    const [availableAssessments,setAvailableAssessments] = React.useState([]);
    const [extraAssessments,setExtraAssessments] = React.useState([]);

    const elephant = findModel(elephants,assessment.elephant_id);
    if (elephant === null) throw new Error("Could not locate the specified elephant from the assessment");

    // generate observation ranges
    const days = ["Day 1","Day 2","Day 3"];
    const workdayRanges = workdayTimeRanges(facility.start_of_day, facility.end_of_day);

    const observationWindows = days.reduce((acc,day)=>{
        const temp = workdayRanges.map((cur)=>{
            return day + ": " + cur;
        });
        return acc.concat(temp);
    },[]);

    console.log(observationWindows);


    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (inputData) => {
        console.log(inputData);


        const matchedSectionB = sectionBs.filter((cur)=>{
            return inputData.observation_range === cur.observation_range;
        });

        let sectionBId;
        if (matchedSectionB.length > 1) {
            // bad data state
            throw new Error("An issue exists with the section B and two or more records use the same observation range");
        } else if (matchedSectionB.length === 1) {
            // we have an existing record
            const existingSectionB = matchedSectionB[0];
            sectionBId = existingSectionB.id;

            setSuccess("Started Section B");
            navigate("/assessments/" + assessment.id + "/sectionb/" + existingSectionB.id);

        } else {
            // start a new record

            const data = {...newSectionB,...inputData,observed_on: today(), assessment_id: assessment.id, user_id: user.id};
            console.log(data);

            const saveResult = await saveSectionB(data, isOnline);
            console.log("sectionb save: ", saveResult);
            sectionBId = saveResult.id;

        }


        // if we're using the clipboard feature and we defined additional elephants to start, we create each record and add to the clipboard system
        if (useClipboard && extraAssessments.length > 0) {
            for (let i = 0; i < extraAssessments.length; i++) {
                const assessmentRecord = extraAssessments[i];

                const data = {...newSectionB,...inputData,observed_on: today(), assessment_id: assessmentRecord.id, user_id: user.id};
                console.log(data);
                const saveResult = await saveSectionB(data, isOnline);
                console.log(saveResult);

                const asesessmentElephant = findModel(elephants,assessmentRecord.elephant_id);

                addObservation({
                    id: saveResult.id,
                    assessmentId: assessmentRecord.id,
                    title: asesessmentElephant.name
                });
            }
        }

        if (useClipboard) {
            addObservation({
                id: sectionBId,
                assessmentId: assessment.id,
                title: elephant.name
            });
            setSuccess("Added section B observation(s) to clipboard");
            navigate("/assessments/");
        } else {
            setSuccess("Started Section B");
            navigate("/assessments/" + assessment.id + "/sectionb/" + sectionBId);
        }

    }


    const usedList = sectionBs.filter((cur)=>{
        return cur.submitted_on
    }).map((cur)=>cur.observation_range);

    console.log("usedList:", usedList);

    const openAssessments = assessments.filter((cur)=>{
        return isInProgress(cur);
    });

    console.log("open assessments: ", openAssessments);

    const handleChange = async (data) => {
        console.log("change", data);

        const sectionBsWithRange = await listSectionBsThatStartedObservationRange(data.observation_range);
        console.log("section Bs with range", sectionBsWithRange);

        const compareAssessmentIds = sectionBsWithRange.map((cur)=>{
            return cur.assessment_id;
        });

        const assessmentsWithoutObservartionStarted = openAssessments.filter((cur)=>{
            return compareAssessmentIds.includes(cur.id) === false && cur.id !== assessment.id;
        });

        console.log('available assessmenets', assessmentsWithoutObservartionStarted);
        setAvailableAssessments(assessmentsWithoutObservartionStarted);
    }

    const toggleExtraAssessment = (obj) => {
        console.log("toggle",obj);
        if (extraAssessments.includes(obj) === false) {
            setExtraAssessments([...extraAssessments,obj]);
        } else {
            setExtraAssessments(extraAssessments.filter((cur)=>cur.id !== obj.id));
        }
    }

    React.useEffect(()=>{
        console.log("extra Assessments", extraAssessments);
    },[extraAssessments]);

    const toggleClipboard = () => {
        setUseClipboard(!useClipboard);
    }


    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(`/assessments/${assessment.id}`)} />
            <Header label={title} />
            <Subtitle>{elephant.name}</Subtitle>

            <FormProvider onChange={handleChange} onSubmit={onSubmit} defaultValues={{observation_range: ''}} >
                <FormOnChange>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12} sx={{justifyContent: "center", alignItems: "center"}}> 
                            <FormDropDownAndUsed name="observation_range" label="Observation Timeframe" list={observationWindows.map((cur)=>{ return {label: cur}; })} required usedList={usedList} />
                        </Grid>


                        <Grid xs={12} sm={12}> 
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onClick={toggleClipboard} />} label="Add this observation to the clipboard" />
                            </FormGroup>
                        </Grid>

                        <Grid xs={12} sm={12} > 
                            <Typography>In addition for these elephants, create observation at same timeframe and add to clipboard</Typography>
                            {availableAssessments.map((assessment)=>{

                                const elephant = findModel(elephants,assessment.elephant_id);

                                return(
                                    <Grid key={elephant.id} xs={12}>
                                        <FormControlLabel disabled={!useClipboard} control={<Checkbox onClick={()=> { toggleExtraAssessment(assessment); }} />} label={elephant.name} />
                                    </Grid>
                                );
                            })}

                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormButton>Start</FormButton>
                        </Grid>

                    </Grid>

                </FormOnChange>
            </FormProvider>
        </Container>
    );
}