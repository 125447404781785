
import * as React from 'react';
import { useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import {Link} from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';


export default function BottomBar({menu,...props}) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    menu = menu || [];

    const list = menu.map((cur)=>{
        let newIcon = cur.icon;
        if (typeof cur.icon === "string") newIcon = theme.icons[cur.icon];
        if (typeof cur.icon === "function") newIcon = cur.icon();
        if (!('icon' in cur)) newIcon = <LinkIcon />;

        return {...cur,icon: newIcon, label: cur.text, to: cur.path};
    });


    return (

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
            {list.map((cur)=>{
                console.log(cur);
                if ('onClick' in cur) {
                  return (<BottomNavigationAction key={cur.id} {...cur} />)
                } else {
                  return (<BottomNavigationAction key={cur.id} {...cur} component={Link} />)
                }
            })}
        </BottomNavigation>
      </Paper>
    );
}