import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import ComponentMultiSelectChips from '../components/form/ComponentMultiSelectChips';


const list = [
    {
        value: "item1",
        label: "Item 1",
    },
    {
        value: "item2",
        label: "Item 2",
    },
    {
        value: "item3",
        label: "Item 3",
    },
    {
        value: "item4",
        label: "Item 4",
    },
    {
        value: "item5",
        label: "Item 5",
    },
];

const values1 = [
    "item1",
    "item2",
    "item3",
];

const values2 = [
    "item4",
    "item5",
];

const values3 = [];

export default function TestForm2Page({title = "EWP",...props}) {

    const [values,setValues] = React.useState(values1);

    return (
        <>
            <Typography variant="h4">Test 2</Typography>

            <Grid container spacing={2}>
                <Grid xs={12}> 
                    <ComponentMultiSelectChips 
                        name="control1" 
                        label="control1" 
                        list={list} 
                        value={values}
                        onChange={(value)=>{
                            console.log("changed value: ", value);
                            setValues(value);
                        }}
                    />   
                </Grid>

                <Grid xs={12}> 
                    {values.join(", ")}
                </Grid>

                <Grid xs={4}> 
                    <Button onClick={()=>{
                        setValues(values1);
                    }}>Values 1</Button>
                </Grid>

                <Grid xs={4}> 
                    <Button onClick={()=>{
                        setValues(values2);
                    }}>Values 2</Button>
                </Grid>

                <Grid xs={4}> 
                    <Button onClick={()=>{
                        setValues(values3);
                    }}>Values 3</Button>
                </Grid>

            </Grid>
        </>
    );
}