import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Fab from '@mui/material/Fab';

export default function EwpFab({children,...props}) {

    const theme = useTheme();
    const smAndLarger = useMediaQuery(theme.mobileBreakPoint);

    return ( 
        <Fab color="primary" sx={{position: "fixed", right: theme.spacing(2), bottom: (smAndLarger) ? theme.spacing(2) : theme.spacing(9)}} {...props}>
            {children}
        </Fab>
    );
}