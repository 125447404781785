import { getFacilityLiveQuery } from '../models/facility'; 

import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { useLiveQuery } from 'dexie-react-hooks';

export default function CheckFacilityConfiguration() {
    const navigate = useNavigate();
    const facility = useLiveQuery(getFacilityLiveQuery);
    const validationMessages = [];

    console.log("dexie facility", facility);

    if (facility) {

        if (!!facility?.poc_name === false) {
            validationMessages.push("Point of contact is not set");
        }

        if (!!facility?.start_of_day === false) {
            validationMessages.push("Start of day is not set");
        }

        if (!!facility?.end_of_day === false) {
            validationMessages.push("End of day is not set");
        }

        console.log(validationMessages);
    }

    return (
        <>
        {facility && validationMessages.length > 0 && 
            <Alert 
                severity="warning"
                action={
                    <Button variant="contained" onClick={()=>{
                        navigate("/institution/details");
                    }}>
                        Configure
                    </Button>
                }
            >
                <div>Your facility is not configured yet:</div>
                {validationMessages.map((cur)=>{
                    return (
                        <div>{cur}</div>
                    );
                })}
            </Alert>
        }
        </>
    )
}