import * as React from 'react';

import {useParams, useLoaderData, useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';


import Subheader from "../components/Subheader";
import UserDetails from "../components/user/UserDetails";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import {getUser} from '../models/users';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    const user = await getUser(params.userId);
    return {user};
}


export default function UserDashboardPage({title = "EWP",...props}) {
    const params = useParams();
    const navigate = useNavigate();
    console.log(params);

    // used to read the loader data that is returned from the function above
    const {user} = useLoaderData(); 
    console.log(user)

    const [anchorEl,setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleEditClick = () => {
        navigate("/users/form/" + user.id);
    }

    const handleChangePasswordClick = () => {
        //navigate("/users/" + user.id + "/change_password");
    }

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Card>
                <CardContent>
                    <CardHeader 
                        title={user.name} 
                        action={
                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        }
                    />
                    <Subheader label="Details" />
                    <UserDetails user={user} />
                </CardContent>
            </Card>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                <MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
            </Menu>
        </Container>
    );
}