import * as React from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';

import Header from "../components/Header";

import { useServiceWorker } from '../hooks/useServiceWorker';

export default function ServiceWorkerReload({onClick,...props}) {

    const { reloadPage } = useServiceWorker();

    return (
        <Container component="main" maxWidth="sm">
            <Header label="Application Update" />

            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid xs={12}> 
                    <Typography variant="body1">A new version of the application is ready. Reload the page to update to the new version.</Typography>    
                </Grid>

                <Grid xs={12} sm={12}> 
                    <Button variant="contained" fullWidth onClick={reloadPage}>Reload</Button>
                </Grid>

            </Grid>
        </Container>
    );
}