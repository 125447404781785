import * as React from 'react';

import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
//import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea } from '@mui/material';

import Avatar from '@mui/material/Avatar';

import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ElephantIcon from '../icons/ElephantIcon';

import { useQuery } from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles';

import {categoryList, typeList, statusList} from '../../models/categories';

import {date} from "../../utils/datetime";
import { listLabel } from '../../utils/lists';

import Tooltip from '@mui/material/Tooltip';

import { listElephants } from '../../models/elephants';

import { lookup as makeLookup } from '../../utils/lists';

const TooltipTitle = ({change, categories, types, statuses, ...props}) => {

    return (
        <>
            <Typography variant="body2" >Category: {listLabel(change.category,categories)}</Typography>
            <Typography variant="body2" >Change Type: {listLabel(change.change_type,types)}</Typography>
            <Typography variant="body2" >Status: {listLabel(change.status,statuses)}</Typography>
        </>
    );
}

export default function ChangeSummaryCard({change,elephantLookup = null,onActionAreaClick,...props}) {

    const queryParams = (elephantLookup !== null) ? {
        queryKey: ['categories'],
        queryFn: async() => {
            return {
                categories: await categoryList(),
                types: await typeList(),
                statuses: await statusList(),
            };
        },
        networkMode: "offlineFirst",
    } : {
        queryKey: ['categories_elephants'],
        queryFn: async() => {
            return {
                categories: await categoryList(),
                types: await typeList(),
                statuses: await statusList(),
                elephants: await listElephants(),
            };
        },
        networkMode: "offlineFirst",
    };

    const theme = useTheme();
    const {isLoading, data} = useQuery(queryParams);

    const timeframe = (change.start && change.end) ? date(change.start) + " - " + date(change.end) : "Started " + date(change.start);

    let lookup = elephantLookup || {};
    if (isLoading === false && data.elephants) lookup = makeLookup(data.elephants);

    const elephants = change.elephants.map((id)=>{
        return lookup[id];
    });


    const maxWords = 7;
    const descriptionWords = change.description.split(/\s+/);
    const summary = descriptionWords.slice(0,maxWords).join(' ') + ((descriptionWords.length > maxWords) ? '...' : '');

                    //title={listLabel(change.category,data.categories)}  

    const avatarColor = (change.is_resolved) ? theme.palette.completedchange.main : theme.palette.activechange.main;

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Card sx={{height: 100}}>
            {isLoading && <div>Loading</div>}
            {!isLoading && 
            <CardActionArea onClick={onActionAreaClick}>
                <CardHeader 
                    title={summary}
                    subheader={<><div>{timeframe}</div></>}
                    titleTypographyProps={{variant: "body1"}} 
                    avatar={
                        <Tooltip title={<TooltipTitle change={change} categories={data.categories} statuses={data.statuses} types={data.types} />}>
                            <Avatar sx={{ bgcolor: avatarColor }}>{change.category.charAt(0).toUpperCase()}</Avatar>
                        </Tooltip>
                    }
                    action={
                        <Box sx={{padding: 2}}>
                            <Badge badgeContent={elephants.length} color="badge">
                                <ElephantIcon />
                            </Badge>
                        </Box>
                    }
                />
            </CardActionArea>
            }
        </Card> 
    );
}