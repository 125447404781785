import * as React from 'react';

import {useLoaderData, useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import { useAuth } from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDateTimePicker from '../components/form/FormDateTimePicker';
import FormMultiSelectChips from '../components/form/FormMultiSelectChips';


import Header from "../components/Header";

import { getAssessment } from '../models/assessments';
import { listElephants } from '../models/elephants';
import { newSectionCIoA, saveSectionCIoA} from '../models/sectioncioa';

import { useConnectivity } from '../context/ConnectivityProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    const assessment = await getAssessment(params.assessmentId);
    const elephants = await listElephants();
    const sectioncioa = newSectionCIoA();
    return {assessment, elephants, sectioncioa};
}


export default function AssessmentSectionCIncidentsOfAggressionFormPage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const navigate = useNavigate();
    const {user} = useAuth();
    const {isOnline} = useConnectivity();

    // used to read the loader data that is returned from the function above
    const {assessment, elephants, sectioncioa} = useLoaderData(); 
    console.log("elephants",elephants);
    console.log("assessment",assessment);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (data) => {
        try {
            const payload = {...data,assessment_id: assessment.id, user_id: user.id};
            console.log(payload);

            const saveResult = await saveSectionCIoA(payload,isOnline);
            console.log("saveResult", saveResult);
            setSuccess("Incident Saved");

            navigate("/assessments/" + assessment.id + "/sectionc/incidents_of_aggression");
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />


            <FormProvider onSubmit={onSubmit} defaultValues={sectioncioa} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={12}> 
                            <FormDateTimePicker name="incident_time" label="Incident Time" required />
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="behavior_seen" label="Behavior Seen" required />
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="circumstances" label="Circumstances" required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormMultiSelectChips enableSelectAll name="aggressor_elephants" label="Aggressors" list={elephants.map((cur)=>{ return {label: cur.name, value: cur.id} })} required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormMultiSelectChips enableSelectAll name="recipient_elephants" label="Recipients" list={elephants.map((cur)=>{ return {label: cur.name, value: cur.id} })} required />
                        </Grid>


                        <Grid xs={12}>
                            <FormButton>Submit</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>


        </Container>
    );
}