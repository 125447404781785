import {useForm} from './FormProvider';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';


export default function FormSwitch({name,label,required,rules = {},...props}) {

    const {register, defaultRules, watch } = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    const watchValue = watch(name);

    return (
        <FormGroup>
            <FormControlLabel control={<Switch checked={watchValue} {...register(name,rules)}  />} label={label} />
        </FormGroup>
    );
};