import Dexie from 'dexie';
import syncstorage from './lib/syncstorage';

export const db = () => {
  const facilityId = syncstorage.getFacility();
  const db = new Dexie('ewp_' + facilityId);
  db.version(15).stores({
    changes: 'id, sync', // Primary key and indexed props
    elephants: 'id, sync',
    facility: 'id, sync',
    users: 'id, sync',
    assessments: 'id, elephant_id, started_on, submitted_on, sync',
    sectionb: 'id, assessment_id, observation_range, user_id, sync',
    sectionc: 'id, assessment_id, user_id, sync',
    sectiona: 'id, assessment_id, user_id, sync',
    sectioncioa: 'id, assessment_id, user_id, sync',
    finalevaluations: 'id,user_id,sync'
  });
  return db;
};
/*
db.open().catch(function (e) {
    console.error("Open failed: " + e.stack);
})
*/