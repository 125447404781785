import * as React from 'react';

import {useParams, useLoaderData, useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


import Header from "../components/Header";
import IncidentOfAggressionCard from '../components/cards/IncidentOfAggressionCard';

import AddIcon from '@mui/icons-material/Add';
import EwpFab from "../components/EwpFab";
import GoBack from "../components/GoBack";

import { getAssessment } from '../models/assessments';
import { listElephants } from '../models/elephants';
import { listSectionCIoAsByAssessment } from '../models/sectioncioa';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    const assessment = await getAssessment(params.assessmentId);
    const elephants = await listElephants();
    const sectionCIoAs = await listSectionCIoAsByAssessment(assessment.id);
    return {assessment,elephants,sectionCIoAs};
}


export default function AssessmentSectionCIncidentsOfAggressionListPage({title = "EWP",...props}) {
    const params = useParams();
    const navigate = useNavigate();

    // used to read the loader data that is returned from the function above
    const {assessment, elephants, sectionCIoAs} = useLoaderData(); 

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(`/assessments/${assessment.id}`)} />
            <Header label={title} />

            {sectionCIoAs?.length === 0 && <Typography>No incidents of aggression recorded</Typography>}
            {sectionCIoAs.map((cur)=>(<IncidentOfAggressionCard key={cur.id} record={cur} elephants={elephants} cardProps={{sx: {marginTop: 2, marginBottom: 2}}}/>))}

            <EwpFab onClick={()=>navigate("/assessments/" + params.assessmentId + "/sectionc/incidents_of_aggression/form")}>
                <AddIcon />
            </EwpFab>
        </Container>
    );
}