import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import BottomBar from './BottomBar';

import Image from 'mui-image';
import logo from "../../assets/logo.png";

import useMediaQuery from '@mui/material/useMediaQuery';

import {Outlet} from 'react-router-dom';

import DrawerList from './DrawerList';

import MessageNotification from '../MessageNotification';
 
import ChangesModal from "../ChangeModal";

import CheckFacilityConfiguration from '../CheckFacilityConfiguration';


const LayoutContext = React.createContext({
  isNavigationShown: true,
  hideNavigation: ()=>{},
  showNavigation: ()=>{},
  openChangesModal: ()=>{},
  smAndLarger: false,
});


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== "isMobile",
})(({ theme, open, isMobile }) => ({
  zIndex: (isMobile) ? theme.zIndex.appBar : theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export const useLayout = () => {
  const context = React.useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
}

export default function Layout({menu,bottomMenu,...props}) {
  const theme = useTheme();

  const smAndLarger = useMediaQuery(theme.mobileBreakPoint);

  const [changesProps, setChangesProps] = React.useState({});
  const [openChangesModal, setOpenChangesModal] = React.useState(false);
  const handleChangesModalOpen = (params) => {
    setChangesProps({...params})
    setOpenChangesModal(true);
  }

  // apply the handleChangesModalOpen method to the onClick property of report_changes
  // for menu
  const reportChangeMenuItem = menu.filter((cur)=>{
    return cur.id === "report_change";
  })[0] || null;
  if (reportChangeMenuItem) {
    reportChangeMenuItem.onClick = handleChangesModalOpen;
  }
  // for bottomMenu
  const reportChangeBottomMenuItem = bottomMenu.filter((cur)=>{
    return cur.id === "report_change";
  })[0] || null;
  if (reportChangeBottomMenuItem) {
    reportChangeBottomMenuItem.onClick = handleChangesModalOpen;
  }



  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window.document.body : undefined;


 const [isNavigationShown,setIsNavigationShown] = React.useState(true); 

 const hideNavigation = () => {
  console.log("hide nav");
  handleDrawerClose();
  setIsNavigationShown(false);
 }
 const showNavigation = () => {
  console.log("show nav");
  setIsNavigationShown(true);
 }


  return (
    <LayoutContext.Provider value={{
      isNavigationShown,
      hideNavigation,
      showNavigation,
      openChangesModal: handleChangesModalOpen,
      smAndLarger,
    }}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} isMobile={!smAndLarger}>
        <Toolbar sx={{justifyContent: "space-between"}}>


          {smAndLarger && isNavigationShown && <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>}

          {!smAndLarger && isNavigationShown && <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>}

          {!isNavigationShown && <Typography>&nbsp;</Typography>}

          <Typography variant="h6" noWrap component="div">
           EWA 
          </Typography>
          <Image src={logo} width={48}/>
        </Toolbar>
      </AppBar>


      {smAndLarger && isNavigationShown && <CustomDrawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        
        <DrawerList menu={menu} open={open} />

      </CustomDrawer>}


      {!smAndLarger && isNavigationShown && <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          <DrawerList open={true} menu={menu} closeOnClick={handleDrawerToggle} />
        </Drawer>}


      <Box component="main" sx={{ flexGrow: 1, p: 3, mb: ((!smAndLarger) ? "55px" : "auto") }}>
        <DrawerHeader />
        <CheckFacilityConfiguration />
        {(props.children) ? (props.children) : (<Outlet />)}

      </Box>

      <MessageNotification sx={{...(!smAndLarger && {paddingBottom: "55px"})}} />
      {!smAndLarger && isNavigationShown && <BottomBar menu={bottomMenu}/>}
      <ChangesModal open={openChangesModal} dispatch={setOpenChangesModal} defaults={changesProps} />
    </Box>
    </LayoutContext.Provider>
  );
}