import * as React from 'react';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea } from '@mui/material';
import { Divider } from "@mui/material";

import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';

import LastAssessmentAt from '../LastAssessmentAt';
import ElephantChangeSummary from '../ElephantChangeSummary';


export default function ElephantCard({elephant,lastAssessment = undefined, changes = [], onActionAreaClick,...props}) {

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Card>
            <CardActionArea onClick={onActionAreaClick}>
                <CardHeader 
                    title={elephant.name}  
                    titleTypographyProps={{variant: "h5"}} 
                    subheader={elephant.studbook_number}
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }}>{elephant.name.charAt(0).toUpperCase()}</Avatar>
                    }
                />
                <Divider />
                <CardContent>
                    {lastAssessment !== undefined && 

                    <Typography variant="body1" sx={{marginY: 1}}>
                        Last/Active Assessment: <LastAssessmentAt assessment={lastAssessment} />
                    </Typography>
                    }

                    <ElephantChangeSummary elephant={elephant} changes={changes} containerProps={{sx: {marginY: 1}}}/>
                </CardContent>
            </CardActionArea>
        </Card> 
    );
}