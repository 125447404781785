import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import { today, syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        assessment_id: null,
        user_id: null,
        incident_time: null,
        completed_on: null,
        behavior_seen: null,
        circumstances: null,
        aggressor_elephants: [],
        recipient_elephants: [],
    };

    return {...record};
}


const fromSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['incident_time'] = dateFromStorage(temp['incident_time']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}

const toSupabase = (inputRecord) => {
    const temp = {...inputRecord};
    temp['incident_time'] = dateToStorage(temp['incident_time']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return {sectioncioa: temp};
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['incident_time'] = dateFromStorage(temp['incident_time']);
    temp['completed_on'] = dateFromStorage(temp['completed_on']);
    return temp;
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['incident_time'] = dateToStorage(temp['incident_time']);
    temp['completed_on'] = dateToStorage(temp['completed_on']);
    return temp;
}


const newSectionCIoA = () =>  {
    return template();
};


const listSectionCIoAsByAssessment = async(assessmentId) => {
    try {

        const temp = await db().sectioncioa.where("assessment_id").equals(assessmentId).sortBy('incident_time');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionCIoAs = async() => {
    try {

        const temp = await db().sectioncioa.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listSectionCIoAsBySync = async(lastSync) => {
    try {

        const temp = await db().sectioncioa.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionCIoAs = async() => {
    try {
        const fetchResults = await supabase
            .from('section_c_incidents_of_aggression')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchSectionCIoAsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('section_c_incidents_of_aggression')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return fromSupabase(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getSectionCIoA = async(id) => {
    try {

        const temp = await db().sectioncioa.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchSectionCIoA = async(id) => {

    try {
        const fetchResults = await supabase
            .from('section_c_incidents_of_aggression')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(errorMessage(fetchResults.error));
        if (fetchResults.data !== null) {
            return fromSupabase(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeSectionCIoAToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().sectioncioa.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/*
const writeNewSectionCIoAToSupabase = async(inputRecord, facilityId) => {
    try {
        const {assessment} = toSupabase(inputRecord);
        assessment.facility_id = facilityId;
        const assessmentResult = await supabase.from("assessments").insert(assessment);
        if (assessmentResult.error) throw new Error(`Error ${assessmentResult.error.code}: ${assessmentResult.error.message}`);
        return {assessmentResult, id: assessment.id};


    } catch (error) {
        console.error(error);
        throw error;
    }
}
*/
const writeSectionCIoAToSupabase = async(inputRecord) => {
    try {
        const {sectioncioa} = toSupabase(inputRecord);

        console.log(sectioncioa);

        const upsertResult = await supabase.from("section_c_incidents_of_aggression").upsert(sectioncioa);
        if (upsertResult.error) throw new Error(errorMessage(upsertResult.error));
        return {upsertResult, id: sectioncioa.id};

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveSectionCIoA = async(inputRecord,isOnline) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionCIoAToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeSectionCIoAToSupabase(inputRecord) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const saveSectionCIoALocal = async(inputRecord) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.sync = syncValue;

    const dexieResults = await writeSectionCIoAToDexie(inputRecord);
    return {dexieResults, id: inputRecord.id};
}

const completeSectionCIoA = async(assessmentId,isOnline) => {
    try {

        const completedOn = today();
        const list = await listSectionCIoAsByAssessment(assessmentId);

        const results = await Promise.all(list.map((cur)=>{
            cur.completed_on = completedOn;
            return saveSectionCIoA(cur,isOnline);
        }));

        return results;

    } catch (error) {
        throw error;
    }
}

const sync = async({lastSync = null}) => {
    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeSectionCIoAToSupabase, 
        localWriteFunc: writeSectionCIoAToDexie, 
    });
    return results;
}

const syncFullRecordSets = async () => {
    const remoteData = await fetchSectionCIoAs() || [];
    const localData = await listSectionCIoAs() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchSectionCIoAsBySync(lastSync) || [];
    const localData = await listSectionCIoAsBySync(lastSync) || [];

    return {remoteData, localData};
};



const truncateDexie = async() => {
    return await db().sectiona.clear();
}


export {newSectionCIoA, listSectionCIoAs, listSectionCIoAsByAssessment, getSectionCIoA, fetchSectionCIoAs, fetchSectionCIoA, saveSectionCIoA, saveSectionCIoALocal, completeSectionCIoA, sync, truncateDexie};
