import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import MuiAlert from '@mui/material/Alert';

import {useMessage} from '../context/MessageProvider';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MessageNotification({...props}) {


  const {clear, message, severity, hasNotification, notificationProps} = useMessage();
  const {actionButtonValue,actionOnClick,alertProps,...snackbarProps} = (notificationProps || {});

  const action = (
    <React.Fragment>
      {actionButtonValue && 
        <Button color="inherit" size="small" onClick={actionOnClick}>
          {actionButtonValue}
        </Button>
      }
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={clear}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={hasNotification}
        autoHideDuration={6000}
        onClose={clear}
        {...props}
        {...snackbarProps}
      >
          <Alert severity={severity} sx={{width: '100%'}} action={action} {...alertProps}>{message}</Alert>
      </Snackbar>
  );
}