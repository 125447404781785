import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';


import ElephantNameList from '../ElephantNameList';


import {datetime} from "../../utils/datetime";
import { lookup } from '../../utils/lists';

import DetailsHeader from '../DetailsHeader';
import DetailsContent from '../DetailsContent';


export default function IncidentOfAggressionCard({record,elephants,cardProps = {},...props}) {


    const niceDate = datetime(record.incident_time);

    const elephantLookup = lookup(elephants);

    const aggressorElephants = record.aggressor_elephants.map((id)=>{
        return elephantLookup[id];
    });

    const recipientElephants = record.recipient_elephants.map((id)=>{
        return elephantLookup[id];
    });

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Card {...cardProps}>
            <CardHeader 
                title={niceDate}
                titleTypographyProps={{variant: "h5"}} 
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6}> 
                        <DetailsHeader>Behavior Seen</DetailsHeader>
                        <DetailsContent>{record.behavior_seen}</DetailsContent>
                    </Grid>
                    <Grid xs={12} sm={6}> 
                        <DetailsHeader>Circumstances</DetailsHeader>
                        <DetailsContent>{record.circumstances}</DetailsContent>
                    </Grid>
                    <Grid xs={6}> 
                        <DetailsHeader>Aggressors</DetailsHeader>
                        <DetailsContent>
                            <ElephantNameList elephants={aggressorElephants} />
                        </DetailsContent>
                    </Grid>
                    <Grid xs={6}> 
                        <DetailsHeader>Recipients</DetailsHeader>
                        <DetailsContent>
                            <ElephantNameList elephants={recipientElephants} />
                        </DetailsContent>
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 
    );
}