import * as React from 'react';

import { useNavigate} from 'react-router-dom';

import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";

import Subheader from "../components/Subheader";

export default function IndexConfigurePage() {
	const navigate = useNavigate();
	return (
	<Container component="main">
		<Subheader label="Configuration Required" />
		<Typography>Before you can start using the application, you need to configure your Institution Details.</Typography>
		<Button variant="contained" onClick={()=>{ navigate("/institution/details"); }}>Configure</Button>
	</Container>
	);
}