import * as React from 'react';

const TimerContext = React.createContext({
    secondsRemaining: 0,
    startingSeconds: 0,
    isRunning: false,
    isDone: false,
    startTimer: ()=>{},
    endTimer: ()=>{},
    pauseTimer: ()=>{},
});

export const useTimer = () => {
    const context = React.useContext(TimerContext);
    if (!context) {
        throw new Error('useTimer must be used within an TimerProvider');
    }
    return context;
};

export const TimerProvider = props => {

    const [state, dispatch] = React.useReducer(reducer, {
        secondsRemaining: 0,
        startingSeconds: 0,
        isRunning: false,
        isDone: false,
    });


    React.useEffect(() => {
        console.log('in useEffect');
        if (state.isRunning && state.secondsRemaining > 0) {
            const timer = setTimeout(()=>{
                dispatch({type: 'TICK', secondsRemaining: state.secondsRemaining - 1});
            },1000);

            return () => clearTimeout(timer);
        } else if (state.isRunning && state.secondsRemaining <= 0) {
            //if (typeof onCompletion === "function") onCompletion();
            // do something when done
            dispatch({type: "END"});
        }
    },[state]);

    const startTimer = (startingSeconds) => {
        console.log('before dispatch');
        dispatch({type: "START", startingSeconds});
    };

    const endTimer = () => {
        dispatch({type: "END"});
    };

    const pauseTimer = () => {
        dispatch({type: "PAUSE"});
    };

    return (
        <TimerContext.Provider value={{
            ...state,
            startTimer,
            endTimer,
            pauseTimer,
        }}>
            {props.children} 
        </TimerContext.Provider>
    );
}

const reducer = (prevState,action) => {
    switch (action.type) {
        case "START":
            return {
                ...prevState,
                startingSeconds: action.startingSeconds,
                secondsRemaining: action.startingSeconds,
                isRunning: true,
                isDone: false,
            }
        case "PAUSE":
            return {
                ...prevState,
                isRunning: (prevState.isDone) ? false : !prevState.isRunning,
            }

        case "END":
            return {
                ...prevState,
                isRunning: false,
                isDone: true,
            }
        case "TICK":
            return {
                ...prevState,
                secondsRemaining: action.secondsRemaining
            }

        // no default
    }
}