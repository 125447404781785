import * as React from 'react';

import {useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Header from "../components/Header";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PolicyIcon from '@mui/icons-material/Policy';
import GavelIcon from '@mui/icons-material/Gavel';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

export default function MoreInfoPage({title = "More Info",...props}) {
    const navigate = useNavigate();

    const handleNavigate = (href) => {
        navigate(href);
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />
            <nav aria-label="more information links">
                <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={()=>handleNavigate("/info/help-tips")}>
                    <ListItemIcon>
                        <HelpCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help/Tips" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={()=>handleNavigate("/info/privacy-policy")}>
                    <ListItemIcon>
                        <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={()=>handleNavigate("/info/terms-and-conditions")}>
                    <ListItemIcon>
                        <GavelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms and Conditions" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={()=>handleNavigate("/info/app-license-details")}>
                    <ListItemIcon>
                        <PhoneIphoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="App License Details" />
                    </ListItemButton>
                </ListItem>
                </List>
            </nav>
        </Container>
    );
}