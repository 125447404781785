import * as React from 'react';

import { supabase } from '../lib/supabase'
import asyncstorage from '../lib/asyncstorage';

import {fetchUser} from "../models/users";
import { getFacility } from '../models/facility'; 

import {useMessage} from './MessageProvider.js';
import {useConnectivity} from './ConnectivityProvider';

import queryString from 'query-string'


const AuthContext = React.createContext({
    isLoading: true,
    isSignout: false,
    session: null,
    user: null,
    profile: null,
    facilityId: null,
    facility: null,
    signIn: async () => {},
    signOut: async () => {},
});

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = props => {
    const {isOnline} = useConnectivity();
    const {setMessage, setError} = useMessage();


    const [state, dispatch] = React.useReducer(reducer, {
        isLoading: true,
        isSignout: false,
        session: null,
        user: null,
        profile: null,
        facilityId: null,
        facility: null,
    });



    React.useEffect(() => {

        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {

            // After restoring token, we may need to validate it in production apps
            // peform any kind of renewal of the token here

            console.log("bootstrap in auth");

            try {
                if (isOnline) {

                    // this will check to see if we have any access/refresh tokens passed and set up the session, based on those
                    console.log("auth provider location: ", global.location.href);
                    const qs = global.location.search.replace(/^\?/,"") || global.location.hash.replace(/^#/,"") || "";
                    const queryStringValues = queryString.parse(qs) || {}
                    console.log("qs values: ", queryStringValues);

                    if ("access_token" in queryStringValues && "refresh_token" in queryStringValues) {
                        const setSessionResults = await supabase.auth.setSession({
                            access_token: queryStringValues.access_token,
                            refresh_token: queryStringValues.refresh_token
                        });
                        console.log("setSessionResults: ", setSessionResults);
                    }



                    console.log("isOnline ")
                    const sessionResults = await supabase.auth.getSession();
                    const userResults = await supabase.auth.getUser()

                    console.log("getSession Results: ",sessionResults);
                    console.log("getUser Results: ",userResults);

                    if (sessionResults.error) throw sessionResults.error;
                    if (userResults.error) throw userResults.error;


                    const profile = await fetchUser(userResults.data.user.id);
                    const facility = await getFacility();
                    const facilityId = profile.facility_id
                    await asyncstorage.setItem("facility_id",facilityId);
                    //handleSync(facilityId,userResults.data.user.id);
                    console.log("Before dispatch");
                    dispatch({ type: 'RESTORE_TOKEN', session: sessionResults.data.session, user: userResults.data.user, profile, facilityId, facility});
                } else {
                    const cachedSession = await asyncstorage.getItem("session");
                    const cachedUser = await asyncstorage.getItem("user");
                    const facility = await getFacility();
                    const cachedProfile = await asyncstorage.getItem("profile");
                    if (cachedSession && cachedUser && cachedProfile && facility) {
                        console.log("logging in with cached session");
                        //syncstorage.setFacility(cachedProfile.facility_id);
                        await asyncstorage.setItem("facility_id",cachedProfile.facility_id);
                        dispatch({ type: 'RESTORE_TOKEN', session: cachedSession, user: cachedUser, profile: cachedProfile, facilityId: cachedProfile.facility_id, facility});
                    } else {
                        throw Error("Is offline and no cached login");
                    }
                }

            } catch (error) {
                console.error(error);
                setError(error.message);
                await asyncstorage.removeItem("session");
                await asyncstorage.removeItem("user");
                await asyncstorage.removeItem("profile");
                dispatch({type: "SIGNOUT"})
            }


            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            //await timeout(1000); // this is temporary, so we can see the loading page come up
        };

        bootstrapAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actions = React.useMemo(
        () => ({
            signIn: async (data) => {
                // In a production app, we need to send some data (usually username, password) to server and get a token
                // We will also need to handle errors if sign in failed
                // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
                // In the example, we'll use a dummy token
                console.log("signIn called");

                try {
                    const results = await supabase.auth.signInWithPassword({
                        email: data.email.toLowerCase(),
                        password: data.password,
                    });

                    console.log(results);

                    if (results.data.session) {
                        await asyncstorage.setItem("session",results.data.session);
                        await asyncstorage.setItem("user",results.data.user);
                        console.log(results.data.user.id);
                        const profile = await fetchUser(results.data.user.id);
                        const facility = await getFacility();
                        console.log("profile", profile)
                        await asyncstorage.setItem("profile",profile);
                        const facilityId = profile.facility_id;
                        await asyncstorage.setItem("facility_id",facilityId);


                        dispatch({ type: 'SIGN_IN', session: results.data.session, user: results.data.user, profile: profile, facilityId, facility});
                    } else {
                        console.error(results.error);
                        if (results.error.name === "AuthApiError") {
                            setMessage("Login credentials incorrect");
                        } else {
                            setMessage(results.error.message);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    setMessage(error.message);
                }

            },
            signInWithOtp: async (data) => {
                try {
                    console.log("signInWithOtp data:", data);
                    const redirectUrl = process.env.REACT_APP_BASE_URL;
                    let loginResults = await supabase.auth.signInWithOtp({
                        email: data.email,
                        options: {
                            emailRedirectTo: redirectUrl
                        }
                    });
                    if (loginResults.error) throw loginResults.error;
                    console.log("otp login results", loginResults);
                    setMessage("Check your email for sign in link");
                } catch (error) {
                    console.log(error);
                    setError(error.message);
                }
            },
            sendForgotPassword: async (data) => {
                try {

                    const redirectUrl = process.env.REACT_APP_BASE_URL + "/profile/change_password";

                    console.log("sendForgotPassword data:", data);
                    let loginResults = await supabase.auth.resetPasswordForEmail(data.email,{
                        redirectTo: redirectUrl
                    });
                    if (loginResults.error) throw loginResults.error;
                    console.log("forgot password results", loginResults);
                    setMessage("Check your email for the forgot password link");
                } catch (error) {
                    console.log(error);
                    setError(error.message);
                }
            },
            signOut: async () => {
                console.log("signOut called");
                const { error } = await supabase.auth.signOut();
                console.log(error);
                await asyncstorage.removeItem("session");
                await asyncstorage.removeItem("user");
                await asyncstorage.removeItem("profile");
                //syncstorage.setFacility(null);
                await asyncstorage.setItem("facility_id",null);
                dispatch({ type: 'SIGN_OUT' });
            },
            signUp: async (data) => {
                // In a production app, we need to send user data to server and get a token
                // We will also need to handle errors if sign up failed
                // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
                // In the example, we'll use a dummy token

                dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
            },
            updateFacility: async(facility) => {
                dispatch({type: "UPDATE_FACILITY", facility});
            }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state,dispatch]
    );


    return (
        <AuthContext.Provider value={{...state,...actions}}>
            {props.children} 
        </AuthContext.Provider>
    );
}

const reducer = (prevState, action) => {
    switch (action.type) {
        case 'UPDATE_FACILITY':
            return {
                ...prevState,
                facility: action.facility
            };
        case 'RESTORE_TOKEN':
            return {
                ...prevState,
                session: action.session,
                user: action.user,
                profile: action.profile,
                facilityId: action.facilityId,
                facility: action.facility,
                isLoading: false,
            };
        case 'SIGN_IN':
            return {
                ...prevState,
                isSignout: false,
                session: action.session,
                user: action.user,
                profile: action.profile,
                facilityId: action.facilityId,
                facility: action.facility,
                isLoading: false,
            };
        case 'SIGN_OUT':
            return {
                ...prevState,
                isSignout: true,
                session: null,
                user: null,
                profile: null,
                facilityId: null,
                isLoading: false,
            };
        // no default
    }
};