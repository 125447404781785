import * as React from 'react';

import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import {useForm} from './FormProvider';

import { Controller } from "react-hook-form"

/*
const EwpSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-markLabel': {
    transform: "translateX(0%)",
  },
}));
*/

export default function FormSlider({name,label,marks,labelProps = {},containerProps = {},rules = {},required,...props}) {

    const {control, defaultRules } = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    const labelComp = (typeof label === "string") ? <Typography variant="body1" {...labelProps}>{label}</Typography> : label;

    return (

        <Controller
            render={({field: {onChange, value, onBlur}}) =>(
                <Box sx={{width: "90%"}} {...containerProps}>
                    {labelComp}
                    <Slider 
                        aria-label={label} 
                        marks={marks} 
                        className="EwpSlider"
                        valueLabelDisplay="off"  
                        value={value}
                        onChange={onChange}
                        {...props} 
                    />
                </Box>

            )}
            name={name}
            control={control}
            rules={rules}
        />



    )
    
};