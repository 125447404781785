import * as React from 'react';

import {useNavigate} from 'react-router-dom';
import { useMessage } from '../context/MessageProvider';
import {useLoaderData} from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {FormProvider} from '../components/form/FormProvider';
import FormOnChange from '../components/form/FormOnChange';
import Form from '../components/form/Form';
import FormButton from '../components/form/FormButton';
import FormSlider from '../components/form/FormSlider';
import FormRadioGroup from '../components/form/FormRadioGroup';
import FormTextField from '../components/form/FormTextField';
import FormConditionCheck from '../components/form/FormConditionCheck';
import FormChildDataGrid from '../components/form/FormChildDataGrid';
import FormDropDown from '../components/form/FormDropDown';
import FormLabel from '../components/form/FormLabel';

import Header from "../components/Header";
import Subtitle from "../components/Subtitle";

import { stereotypyFrequency, stereotypiesInterruptBehaviorFrequency, yesNoUnknown } from '../models/categories';

import CustomTooltip from '../components/tooltip/CustomTooltip';
import HelpIcon from '@mui/icons-material/Help';

import { getAssessment } from '../models/assessments';
import { getFinalEvaluation, newFinalEvaluation, saveFinalEvaluation } from '../models/finalevaluation';

import { useTheme } from '@mui/material/styles';

import { v4 as uuidv4 } from 'uuid';

import {today} from '../utils/datetime';

import { useAuth } from '../context/AuthProvider';
import { useConnectivity } from '../context/ConnectivityProvider';

const CustomSlider = ({name,label,leftLabel,rightLabel,tooltipKey,...props}) => {
    const theme = useTheme();

    const leftLabelComponent = (typeof leftLabel === "string") ?  <Typography variant="body2" sx={{color: theme.palette.text.secondary}}>{leftLabel}</Typography> : leftLabel;
    const rightLabelComponent = (typeof rightLabel === "string") ?  <Typography variant="body2" sx={{color: theme.palette.text.secondary}}>{rightLabel}</Typography> : rightLabel;

    return (
        <Grid container spacing={0} columnSpacing={3}>
            <Grid xs={12}>
                <FormSlider 
                    containerProps={{sx: {width: '100%'}}}
                    name={name} 
                    label={<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Typography>{label}</Typography>
                            <CustomTooltip keyString={tooltipKey}>
                                <HelpIcon />
                            </CustomTooltip>
                        </Stack>}
                
                    min={0} 
                    max={10} 
                    step={0.1} 
                    marks={[]}
                {...props}
                />
            </Grid>
            <Grid xs={6}>
                {leftLabelComponent}
            </Grid>
            <Grid xs={6} sx={{textAlign: 'right'}}>
                {rightLabelComponent}
            </Grid>
        </Grid>
    );
}

export async function loader({params}) {
    return {
        stereotypyFrequency: await stereotypyFrequency(),
        stereotypiesInterruptBehaviorFrequency: await stereotypiesInterruptBehaviorFrequency(),
        yesNoUnknown: await yesNoUnknown(),
        assessment: await getAssessment(params.assessmentId),
        finalevaluation: await getFinalEvaluation(params.assessmentId) || newFinalEvaluation(),
    };
}

export default function AssessmentFinalEvaluationFormPage({title = "EWP",...props}) {

    const {setSuccess, setError} = useMessage();
    const navigate = useNavigate();

    const {isOnline} = useConnectivity();
    const {user} = useAuth();

    const {stereotypyFrequency, stereotypiesInterruptBehaviorFrequency, yesNoUnknown, assessment, finalevaluation} = useLoaderData(); 
    console.log("stereotypeFrequency: ", stereotypyFrequency);
    console.log("stereotypiesInterruptBehaviorFrequency: ", stereotypiesInterruptBehaviorFrequency);
    console.log("yesNoUnknown: ", yesNoUnknown);
    console.log("finalevaluation",finalevaluation);


    const save = async(inputData) => {
        if (inputData.id === null) inputData.id = assessment.id;
        const payload = {...inputData};
        console.log("final evaluation payload: ", payload);
        const saveResult = await saveFinalEvaluation(payload,user.id,isOnline);
        console.log("final evaluation save:", saveResult);
        return saveResult;
    }


    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (inputData) => {
        try {

            // make sure that the id set is the assessment ID, since there is a 1-1 relationship
            console.log("onSubmit write", inputData);
            const payload = {...inputData, completed_on: today()};
            await save(payload);

            setSuccess("Saved Final Evaluation");
            navigate("/assessments/" + assessment.id + "/submit");
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    const onChange = async (inputData) => {
        console.log("onChange write", inputData);
        await save(inputData);
    }


    // handles the form steps in the UI
    const [page,setPage] = React.useState(1);
    const handleNext = () => {
        setPage(page + 1);
    }
    const handlePrev = () => {
        setPage(page - 1);
    }


    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />
            <Subtitle>Step {page} of 9</Subtitle>

            <Grid container spacing={2}>
                <Grid xs={6} sm={6}> 
                </Grid>
            </Grid>

            <FormProvider onChange={onChange} onSubmit={onSubmit} defaultValues={finalevaluation} >
                <FormOnChange>
                    <Grid container spacing={2}>

                        {page === 1 && <>
                        <Grid xs={12} sm={12}>

                            <FormChildDataGrid 
                                name="stereotypies" 
                                label="Since your last assessment, have you seen this elephant show any stereotypies? Please give a breakdown of the stereotypies seen, their approximate frequency and the approximate time of day they occurred, their location, and any other context you can provide." 
                                newRow={() => {
                                    return {
                                        id: uuidv4(),
                                        description: '',
                                        frequency: '',
                                        time_of_day: '',
                                        location: ''
                                    };
                                }}
                                columns={[
                                    { 
                                        field: 'description', 
                                        headerName: 'Description', 
                                    },
                                ]}
                                formRender={(defaultValues,onSubmit,onCancel)=>{
                                    return (
                                    <FormProvider defaultValues={defaultValues} >
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="description" label="Description" required />
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormDropDown name="frequency" label="Frequency" list={stereotypyFrequency} required />
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="time_of_day" label="Time Of Day" required />
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="location" label="Location" required />
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                                                </Grid>
                                            </Grid>
              
                                        </Form>
                                    </FormProvider>
                                    );
                                }}
                            />

                        </Grid>

                        <Grid xs={12}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}


                        {page === 2 && <>
                        <Grid xs={12} sm={12}>
                            <FormRadioGroup
                                name="stereotypies_interfere_with_behavior"
                                label="Do the stereotypies this elephant performs ever interfere with their behaviour?"
                                list={stereotypiesInterruptBehaviorFrequency}
                                required={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>

                        </>}

                        {page === 3 && <>
                        <Grid xs={12} sm={12}>
                            <FormRadioGroup
                                name="can_stereotypies_be_interrupted"
                                label="Can the stereotypies this elephant performs be interrupted (eg. is there anything that can stop the stereotypy)?"
                                list={yesNoUnknown}
                                required={true}
                                fullWidth
                            />
                        </Grid>

                        <FormConditionCheck name="can_stereotypies_be_interrupted" value="yes">
                        <Grid xs={12} sm={12}>
                            <FormTextField
                                name="can_stereotypies_be_interrupted_description"
                                label="Please describe what they are interrupted by"
                                required={true}
                                fullWidth
                            />
                        </Grid>
                        </FormConditionCheck>

                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>

                        </>}



                        {page === 4 && <>
                        <Grid xs={12} sm={12}>
                            <CustomSlider name="feeding_foraging_score" label="Since the last assessment, and including the current one, I have seen this elephant..." leftLabel="Rarely forage and/or only feed at scheduled feed times" rightLabel="Forage for food all the time it is free to do so" tooltipKey="final_evaluation_feeding" />
                        </Grid>

                        <Grid xs={12} sm={12}>
                            <CustomSlider name="activity_frequency_score" label="Since the last assessment, and including the current one, I have seen this elephant..." leftLabel="Spend most of its day waiting for scheduled events" rightLabel="Engaging in activities completely independent of the scheduled events" tooltipKey="final_evaluation_activity" />
                        </Grid>

                        <Grid xs={12} sm={12}>
                            <CustomSlider 
                                name="social_elephant_score" 
                                label="Since the last assessment, and including the current one, I have seen this elephant..." 
                                leftLabel="Avoid other elephants every time it is free to do so" 
                                rightLabel="Spend time near or approach other elephants every time it is free to do so, but not seeking or engaging in aggressive or agonisitic interactions with those elephants" 
                                tooltipKey="final_evaluation_social_environment_score" 
                            />
                        </Grid>


                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}

                        {page === 5 && <>

                        <Grid xs={12} sm={12}>
                            <FormLabel>Please provide details of any other observations you have made of this animal in the last 3 days which you believe are of importance</FormLabel>
                            <FormTextField
                                name="other_observations"
                                label=""
                                required={false}
                                fullWidth
                                multiline
                                minRows={5}
                            />
                        </Grid>

                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}

                        {page === 6 && <>

                        <Grid xs={12} sm={12}>
                            <FormLabel>Since the last assessment, and including this one, have you seen this elephant come across a new or unexpected situation? What was the situation and what was their reaction?</FormLabel>
                            <FormTextField
                                name="unexpected_situations"
                                label=""
                                required={false}
                                fullWidth
                                multiline
                                minRows={5}
                            />
                        </Grid>

                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}


                        {page === 7 && <>
                        <Grid xs={12} sm={12}>

                            <FormChildDataGrid 
                                name="vocalisations" 
                                label="Since the last time you did an assessment, and including this one, please describe below any vocalisations this individual has made and the contexts in which they occurred" 
                                newRow={() => {
                                    return {
                                        id: uuidv4(),
                                        vocalisation: '',
                                        context: '',
                                    };
                                }}
                                columns={[
                                    { 
                                        field: 'vocalisation', 
                                        headerName: 'Vocalisation', 
                                    },
                                ]}
                                formRender={(defaultValues,onSubmit,onCancel)=>{
                                    return (
                                    <FormProvider defaultValues={defaultValues} >
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="vocalisation" label="Vocalisation" required />
                                                </Grid>
                                                <Grid xs={12} sm={12}> 
                                                    <FormTextField name="context" label="Context" required />
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onSubmit={onSubmit} fullWidth>Save</FormButton>
                                                </Grid>
                                                <Grid xs={6} sm={6}> 
                                                    <FormButton onClick={onCancel} fullWidth>Cancel</FormButton>
                                                </Grid>
                                            </Grid>
              
                                        </Form>
                                    </FormProvider>
                                    );
                                }}
                            />

                        </Grid>

                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}



                        {page === 8 && <>


                        <Grid xs={12} sm={12}>
                            <Typography>At this moment in time, what is your overall evaluation, of the different aspects of welfare, for this elephant?</Typography>
                        </Grid>

                        <Grid xs={12} sm={12}>
                            <CustomSlider 
                                name="mental_health" 
                                label="Mental Health" 
                                leftLabel="Worst imaginable" 
                                rightLabel="Best imaginable for any elephant anywhere" 
                                tooltipKey="final_evaluation_mental_health" />
                        </Grid>

                        <Grid xs={12} sm={12}>
                            <CustomSlider 
                                name="physical_health" 
                                label="Physical Health" 
                                leftLabel="Worst imaginable" 
                                rightLabel="Best imaginable for any elephant anywhere" 
                                tooltipKey="final_evaluation_physical_health" />
                        </Grid>

                        <Grid xs={12} sm={12}>
                            <CustomSlider 
                                name="overall_welfare" 
                                label="Overall Welfare" 
                                leftLabel="Worst imaginable" 
                                rightLabel="Best imaginable for any elephant anywhere" 
                                tooltipKey="final_evaluation_overall_welfare" />
                        </Grid>


                        <Grid xs={6}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>
                        <Grid xs={6}> 
                            <FormButton onClick={handleNext}>Next</FormButton>
                        </Grid>
                        </>}

                        {page === 9 && <>

                        <Grid xs={12} sm={12}>
                            <Typography>Please provide any other information relating to this elephant, or your assessment of it, that wasn't covered elsewhere.</Typography>
                            <FormTextField
                                name="final_comments"
                                label=""
                                required={false}
                                fullWidth
                                multiline
                                minRows={5}
                            />
                        </Grid>

                        <Grid xs={12}> 
                            <FormButton onClick={handlePrev}>Prev</FormButton>
                        </Grid>

                        <Grid xs={12}> 
                            <FormButton>Submit/Close Assessment</FormButton>
                        </Grid>
                        </>}



                    </Grid>

                </FormOnChange>
            </FormProvider>
        </Container>
    );
}