import { Box } from '@mui/material';
import React from 'react'

export default function TabPanel(props) {
    const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
  )
}
