import * as React from 'react';

const SectionBContext = React.createContext({
    current: undefined,
    nextRecord: undefined,
    prevRecord: undefined,
    index: undefined,
    nextIndex: undefined,
    prevIndex: undefined,
    inProgressObservations: [],
    isClipboardMode: false,
    addObservation: () => {},
    removeObservation: () => {},
    nextObservation: () => {},
    prevObservation: () => {},
    setObservation: () => {},
    clearObservation: () => {},
    startClipboardMode: () => {},
    stopClipboardMode: () => {},
});

export const useSectionB = () => {
    const context = React.useContext(SectionBContext);
    if (!context) {
        throw new Error('useSectionB must be used within an SectionBProvider');
    }
    return context;
};

export const SectionBProvider = props => {

    const [state, dispatch] = React.useReducer(reducer, {
        current: undefined,
        nextRecord: undefined,
        prevRecord: undefined,
        index: undefined,
        nextIndex: undefined,
        prevIndex: undefined,
        isClipboardMode: false,
        inProgressObservations: [],
    });

    const addObservation = (record) => {
        dispatch({type: "ADD", record});
    };

    const removeObservation = (record) => {
        dispatch({type: "REMOVE", record});
    };

    const nextObservation = () => {
        dispatch({type: "NEXT"});
    };

    const prevObservation = () => {
        dispatch({type: "PREV"});
    };

    const clearObservations = () => {
        dispatch({type: "CLEAR"});
    }

    const startClipboardMode = () => {
        dispatch({type: "START_CLIPBOARD_MODE"});
    }

    const stopClipboardMode = () => {
        dispatch({type: "STOP_CLIPBOARD_MODE"});
    }

    const setObservation = (inputIndex) => {
        dispatch({type: "SET", index: inputIndex});
    }

    return (
        <SectionBContext.Provider value={{
            ...state,
            addObservation,
            removeObservation,
            nextObservation,
            prevObservation,
            setObservation,
            clearObservations,
            startClipboardMode,
            stopClipboardMode,
            
        }}>
            {props.children} 
        </SectionBContext.Provider>
    );
}

const reducer = (prevState,action) => {
    switch (action.type) {

        case "SET":
            
            //const nextIndex = (prevState.index === prevState.inProgressObservations.length - 1) ? 0 : prevState.index + 1;

            const newIndex5 = action.index;
            const newNextIndex5 = (newIndex5 + 1) % prevState.inProgressObservations.length;
            const newPrevIndex5 = (newIndex5 - 1 + prevState.inProgressObservations.length) % prevState.inProgressObservations.length;
            
            return {
                ...prevState,
                current: prevState.inProgressObservations[newIndex5],
                index: newIndex5,
                nextIndex: newNextIndex5,
                prevIndex: newPrevIndex5,
                nextRecord: prevState.inProgressObservations[newNextIndex5],
                prevRecord: prevState.inProgressObservations[newPrevIndex5],
            }


        case "NEXT":
            
            //const nextIndex = (prevState.index === prevState.inProgressObservations.length - 1) ? 0 : prevState.index + 1;

            const newIndex1 = (prevState.index + 1) % prevState.inProgressObservations.length;
            const newNextIndex1 = (newIndex1 + 1) % prevState.inProgressObservations.length;
            const newPrevIndex1 = (newIndex1 - 1 + prevState.inProgressObservations.length) % prevState.inProgressObservations.length;
            
            return {
                ...prevState,
                current: prevState.inProgressObservations[newIndex1],
                index: newIndex1,
                nextIndex: newNextIndex1,
                prevIndex: newPrevIndex1,
                nextRecord: prevState.inProgressObservations[newNextIndex1],
                prevRecord: prevState.inProgressObservations[newPrevIndex1],
            }

        case "PREV":

            //const prevIndex = (prevState.index === 0) ? prevState.inProgressObservations.length - 1 : prevState.index - 1;

            const newIndex2 = (prevState.index - 1 + prevState.inProgressObservations.length) % prevState.inProgressObservations.length;
            const newNextIndex2 = (newIndex2 + 1) % prevState.inProgressObservations.length;
            const newPrevIndex2 = (newIndex2 - 1 + prevState.inProgressObservations.length) % prevState.inProgressObservations.length;

            return {
                ...prevState,
                current: prevState.inProgressObservations[newIndex2],
                index: newIndex2,
                nextIndex: newNextIndex2,
                prevIndex: newPrevIndex2,
                nextRecord: prevState.inProgressObservations[newNextIndex2],
                prevRecord: prevState.inProgressObservations[newPrevIndex2],
            }

        case "ADD":

            const id = action.record.id;
            const inObservations = prevState.inProgressObservations.filter((cur)=>{
                return cur.id === id;
            });

            const newList = (inObservations.length === 0) ? [...prevState.inProgressObservations,action.record] : [...prevState.inProgressObservations];
             // set current to 0 and calc prev and next

            const newNextIndex3 = (0 + 1) % newList.length;
            const newPrevIndex3 = (0 - 1 + newList.length) % newList.length;

            return {
                ...prevState,
                current: newList[0],
                index: 0,
                inProgressObservations: newList,
                nextIndex: newNextIndex3,
                prevIndex: newPrevIndex3,
                nextRecord: newList[newNextIndex3],
                prevRecord: newList[newPrevIndex3],
                isClipboardMode: true,
            }
        
        case "REMOVE":

            const removeId = action.record.id;

            const newList4 = prevState.inProgressObservations.filter((cur)=>{
                return cur.id !== removeId;
            });

            const newNextIndex4 = (0 + 1) % newList4.length;
            const newPrevIndex4 = (0 - 1 + newList4.length) % newList4.length;

            
            if (newList4.length > 0) {

                return {
                    ...prevState,
                    inProgressObservations: newList4,
                    current: newList4[0],
                    index: 0,
                    nextIndex: newNextIndex4,
                    prevIndex: newPrevIndex4,
                    nextRecord: newList4[newNextIndex4],
                    prevRecord: newList4[newPrevIndex4],
                }

            } else {

                return {
                    ...prevState,
                    current: undefined,
                    nextRecord: undefined,
                    prevRecord: undefined,
                    index: undefined,
                    nextIndex: undefined,
                    prevIndex: undefined,
                    inProgressObservations: []
                }

            }


        
        case "CLEAR":
            return {
                ...prevState,
                current: undefined,
                nextRecord: undefined,
                prevRecord: undefined,
                index: undefined,
                nextIndex: undefined,
                prevIndex: undefined,
                isClipboardMode: false,
                inProgressObservations: []
            }
            
        case "START_CLIPBOARD_MODE":
            return {
                ...prevState,
                isClipboardMode: true,
            }

        case "STOP_CLIPBOARD_MODE":
            return {
                ...prevState,
                isClipboardMode: false,
            }

        // no default
    }
}