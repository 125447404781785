import * as React from 'react';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

import {useTimer} from '../context/TimerProvider';

const Timer = ({onCompletion,typographyProps = {},...props}) => {

    const {secondsRemaining, isDone } = useTimer();

    if (isDone && typeof onCompletion === "function") onCompletion(); 

    const temp = dayjs.duration(secondsRemaining * 1000);
    const outputTime = dayjs(temp).format("mm:ss");

    return (
        <Typography {...typographyProps}>{outputTime}</Typography>
    )
}

export default Timer;