import * as React from 'react';

import { useLoaderData, useNavigate} from 'react-router-dom';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import BigCardGrid from '../components/BigCardGrid';

import AddIcon from '@mui/icons-material/Add';

import EwpFab from "../components/EwpFab";
import Header from "../components/Header";

import ElephantCard from "../components/cards/ElephantCard";


import { listElephants } from "../models/elephants";
import { listAssessments } from "../models/assessments";
import { listChanges } from "../models/changes";

import { getElephantLastAssessments } from '../utils/assessments';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.

export async function loader() {
	const elephants = await listElephants();
	const assessments = await listAssessments();
	const changes = await listChanges();
	return { elephants, assessments, changes };
}


export default function ElephantListPage({title = "Elephants",...props}) {
    const navigate = useNavigate();
    const {elephants,assessments,changes} = useLoaderData(); 


    // sort A-Z by name
    elephants.sort((a,b)=>{
        return String(a.name).localeCompare(String(b.name));
    });


    const elephantLastAssessments = getElephantLastAssessments(elephants,assessments);

    const navigateToElephant = (id) => {
        console.log(id);
        navigate("/elephants/" + id);
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main">
            <Header label={title} />

            <Grid container spacing={2}>
            {elephants.map((elephant)=>{

                const lastAssessment = elephantLastAssessments[elephant.id].assessment || null;

                return (
                    <BigCardGrid key={elephant.id}> 
                        <ElephantCard elephant={elephant} lastAssessment={lastAssessment} changes={changes} onActionAreaClick={()=>{navigateToElephant(elephant.id)}} />
                    </BigCardGrid>
                );

            })}
            </Grid>
            <EwpFab onClick={()=>navigate("/elephants/form")}>
                <AddIcon />
            </EwpFab>
        </Container>
    );
}