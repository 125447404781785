import * as React from 'react';

const ConnectivityContext = React.createContext({
    isOnline: null,
    setOnline: ()=>{},
    setOffline: ()=>{}
});

export const useConnectivity = () => {
    const context = React.useContext(ConnectivityContext);
    if (!context) {
        throw new Error('useConnectivity must be used within an ConnectivityProvider');
    }
    return context;
};

export const ConnectivityProvider = props => {
    const [state, dispatch] = React.useReducer(reducer, {
        isOnline: (global.navigator?.onLine || false),
    });

    const setOnline = () => {
        dispatch({type: 'ONLINE',isOnline: true});
    }

    const setOffline = () => {
        dispatch({type: 'OFFLINE',isOnline: false});
    }


    React.useEffect(()=>{
        global.addEventListener("online",setOnline);
        global.addEventListener("offline",setOffline);

        return () => {
            global.removeEventListener("online",setOnline);
            global.removeEventListener("offline",setOffline);
        }

    },[state.isOnline]);


    return (
        <ConnectivityContext.Provider value={{
            ...state,
            setOnline,
            setOffline
            }}>
            {props.children} 
        </ConnectivityContext.Provider>
    );
}

const reducer = (prevState,action) => {
    switch (action.type) {
        case "ONLINE":
            return {
                ...prevState,
                isOnline: true,
            }
        case "OFFLINE":
            return {
                ...prevState,
                isOnline: false,
            }
        
        // no default
    }
}