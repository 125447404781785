/*
Used with lists in this format:
[
    {
        label: "Friendly Name",
        value: "ID Name"
    },
    ...
]
Looks through the list finds the value and returns the label, otherwise the original value
*/
const listLabel = (value,list) => {
    return list.filter((cur)=> {
        return value === cur.value;
    })[0]?.label || value;
};

const lookup = (list,field = 'id') =>  {
    return list.reduce((acc,cur)=>{
        acc[cur[field]] = cur;
        return acc;
    },{});
}

const keys = (list,field = 'id') => {
    return list.map((cur)=>{
        return cur[field];
    });
}

const recordsNotInKeyList = (records,keyList) => {
    const missingData = records.filter((cur)=>{
        return keyList.includes(cur.id) === false;
    });
    return missingData;
}

const recordsEqualByField = (list1,list2,field = 'sync', idField = 'id') => {
    const list2Lookup = lookup(list2,idField);

    const equalRecords = list1.filter((cur)=>{
        const compareRecord = list2Lookup[cur[idField]] || null;
        return (compareRecord && cur[field] === compareRecord[field]);
    });

    return equalRecords;
}

export {listLabel, lookup, keys, recordsNotInKeyList, recordsEqualByField};