import React, { useEffect, useState } from 'react';

import Router from "./Router";

import {useAuth} from '../context/AuthProvider';
import { useServiceWorker } from '../hooks/useServiceWorker';
import { useConnectivity } from '../context/ConnectivityProvider';
import { useSync } from '../context/SyncingProvider';
import SplashScreen from './splashScreen/SplashScreen';
import ServiceWorkerReload from './ServiceWorkerReload';

function AppReady() {
    const {isLoading,user} = useAuth();
    const {performSync} = useSync();
    const {isOnline} = useConnectivity();

    const [showSplashScreen, setShowSplashScreen] = useState(true)
    const { showReload } = useServiceWorker();

    React.useEffect(()=>{
      const doSync = async () => {
        await performSync(false);
      }
      if (isOnline && user && !showReload) {
        doSync();
      }

      return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isOnline,user]);


    useEffect(() => {
      if(!isLoading){
        setTimeout(() => {
          setShowSplashScreen(false)
        }, 1200);
      }
    }, [isLoading])
    

    if (isLoading && showSplashScreen) {
      return (
        <SplashScreen loading={isLoading}/>
      )
    } else {
      if (showReload) {
        return (
          <ServiceWorkerReload />
        )
      } else {
        return (
          <Router />
        )
      }
    }
}
export default AppReady;