import * as React from 'react';

import {useLoaderData, useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';

import Header from "../components/Header";

import { createUser, newUser} from '../models/users';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    const user = await newUser();
    return {user};
}

export default function UserCreatePage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const {facilityId, session} = useAuth();
    const navigate = useNavigate();

    console.log("facilityId: ", facilityId);
    // used to read the loader data that is returned from the function above
    const {user} = useLoaderData(); 
    console.log("User Record: ", user);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (data) => {
        try {
            console.log("new user data: ",data);
            const createUserResults = await createUser(data,facilityId, session);
            console.log("createUserResults: ", createUserResults);
            setSuccess("User Created");
            const {password, confirm_password, ...cleanData} = data;
            navigate("/users/form",{state: cleanData});
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }

    const password = React.useRef({});
    console.log("password: ", password);

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            <FormProvider onSubmit={onSubmit} defaultValues={user} >
                <Form>
                    <Grid container spacing={2}>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="name" label="Name" required/>
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="email" label="Email" required/>
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="password" label="Password" type="password" refValue={password} rules={{
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters"
                                  }
                            }} required/>
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="confirm_password" label="Confirm Password" type="password" required rules={{
                                validate: value => value === password.current || 'The passwords do not match'  // JS only: <p>error message</p> TS only support string
                            }}/>
                        </Grid>

                        <Grid xs={12}>
                            <FormButton>Create User</FormButton>
                        </Grid>

                    </Grid>
                </Form>
            </FormProvider>



        </Container>
    );
}