import Typography from '@mui/material/Typography';

export default function Subeader({label,center,...props}) {

    const style = {
        marginTop: 2, 
        marginBottom: 1
    }

    if (center) {
        style['textAlign'] = "center";
    }

    return (<Typography variant="h6" color="secondary" sx={style} {...props}>{label}</Typography>);
}

