import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ConfirmationDialogContext = React.createContext({
    confirmationDialogIsOpen: false,
    confirm: (props) => {}
});

export const useConfirmationDialog = () => {
    const context = React.useContext(ConfirmationDialogContext);
    if (!context) {
        throw new Error("useConfirmationDialog must be used within the ConfirmationDialogProvider");
    }
    return context;
}

const defaults = {
    startingState: false,
    message: "Please confirm this action",
    title: "Confirm",
    confirmButtonLabel: "Confirm",
    cancelButtonLabel: "Cancel",
    onSubmit: undefined,
    onCancel: undefined
}


/*
Example usage:


    const {confirm} = useConfirmationDialog();
    const handleConfirmOpen1 = async () => {
        confirm({
            message: "can you read this?",
            onSubmit: async () => { console.log("onSubmit 1 executed"); },
        });
    };

    const handleConfirmOpen2 = async () => {
        confirm({
            title: "Are you sure?",
            message: "Please confirm",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onSubmit: async () => { console.log("onSubmit 2 executed"); },
            onCancel: async () => { console.log("onCancel 2 executed"); }
        });
    };


*/
export const ConfirmationDialogProvider = ({...props}) => {

    const [open,setOpen] = React.useState(false);

    const [message, setMessage] = React.useState(defaults.message);
    const [title, setTitle] = React.useState(defaults.title)
    const [onSubmit,setOnSubmit] = React.useState(defaults.onSubmit);
    const [onCancel,setOnCancel] = React.useState(defaults.onCancel);
    const [confirmButtonLabel,setConfirmButtonLabel] = React.useState(defaults.confirmButtonLabel);
    const [cancelButtonLabel,setCancelButtonLabel] = React.useState(defaults.cancelButtonLabel);

    const handleCancel = () => {
        if (typeof onCancel === "function") onCancel();
        setOpen(false);
    }

    const handleConfirm = () => {
        if (typeof onSubmit === "function") onSubmit();
        setOpen(false);
    }

    const confirm = (props) => {
        setMessage(props.message || defaults.message);
        setTitle(props.title || defaults.title);
        setConfirmButtonLabel(props.confirmButtonLabel || defaults.confirmButtonLabel);
        setCancelButtonLabel(props.cancelButtonLabel || defaults.cancelButtonLabel);
        if (typeof props.onSubmit === "function") {
            setOnSubmit(()=>props.onSubmit);
        } else {
            setOnSubmit(defaults.onSubmit);
        }

        if (typeof props.onCancel === "function") {
            setOnCancel(()=>props.onCancel);
        } else {
            setOnCancel(defaults.onCancel);
        }

        setOpen(true);
    }

    return (
        <>
        <ConfirmationDialogContext.Provider value={{
            confirmationDialogIsOpen: open,
            confirm
        }}>{props.children}</ConfirmationDialogContext.Provider>
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>{cancelButtonLabel}</Button>
            <Button onClick={handleConfirm} autoFocus>{confirmButtonLabel}</Button>
        </DialogActions>
        </Dialog>
        </>
    );
}