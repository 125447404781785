import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { useMessage } from '../context/MessageProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormDropDownAndUsed from '../components/form/FormDropDownAndUsed';
import FormButton from '../components/form/FormButton';

import Header from "../components/Header";
import Subtitle from "../components/Subtitle";
import GoBack from "../components/GoBack";
import Spinner from "../components/Spinner";

import { newSectionA, saveSectionA, listSectionAsByAssessment } from '../models/sectiona';
import { listElephants } from '../models/elephants';
import { getFacility } from '../models/facility';
import { getAssessment } from '../models/assessments';


import {today, workdayTimeRanges} from "../utils/datetime";
import { findModel } from '../utils/model';

import { useQuery } from '@tanstack/react-query'

import { useConnectivity } from '../context/ConnectivityProvider';


export default function AssessmentSectionASelectPage({title = "EWP",...props}) {

    const {setSuccess} = useMessage();
    //const {facility, elephants, assessment, sectionBs, newSectionB} = useLoaderData(); 
    const navigate = useNavigate();
    const params = useParams();
    const {user} = useAuth();
    const {isOnline} = useConnectivity();

    const {isLoading, data} = useQuery({
        queryKey: ['sectiona',params.assessmentId],
        queryFn: async() => {

            const elephants = await listElephants();
            const assessment = await getAssessment(params.assessmentId);
            return {
                facility: await getFacility(), 
                elephants,
                assessment,
                sectionAs: await listSectionAsByAssessment(params.assessmentId),
                newSectionA: newSectionA(),
                elephant: findModel(elephants,assessment.elephant_id),
            };
        },
        networkMode: "offlineFirst",
    });


    let observationWindows = []
    if (data) {

        const {facility, elephants, sectionAs, newSectionA} = data;

        console.log("section a assessment id: ", params.assessmentId);
        console.log("facility: ", facility, "elephants:", elephants);
        console.log("sectionAs: ", sectionAs);
        console.log("newSectionA", newSectionA);

        observationWindows = workdayTimeRanges(facility.start_of_day, facility.end_of_day);
        console.log(observationWindows);

    }

    const onSubmit = async (inputData) => {
        console.log(inputData);

        const matchedSectionA = data.sectionAs.filter((cur)=>{
            return inputData.observation_range === cur.observation_range;
        });

        if (matchedSectionA.length > 1) {
            // bad data state
            throw new Error("An issue exists with the section A and two or more records use the same observation range");
        } else if (matchedSectionA.length === 1) {
            // we have an existing record
            const existingSectionA = matchedSectionA[0];

            setSuccess("Started Section A");
            navigate("/assessments/" + data.assessment.id + "/sectiona/" + existingSectionA.id);

        } else {
            // start a new record

            const payload = {...data.newSectionA,...inputData,observed_on: today(), assessment_id: data.assessment.id, user_id: user.id};
            console.log("payload: ", payload);

            const saveResult = await saveSectionA(payload,isOnline);
            console.log("sectiona save: ", saveResult);

            setSuccess("Started Section A");
            navigate("/assessments/" + data.assessment.id + "/sectiona/" + saveResult.id);
        }
    }

    const usedList = (data?.sectionAs || []).filter((cur)=>{
        return cur.submitted_on
    }).map((cur)=>cur.observation_range);

    console.log("usedList:", usedList);

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            {isLoading && <Spinner />}
            {!isLoading && <>
                <GoBack onClick={()=>navigate(`/assessments/${data.assessment.id}`)} />
                <Header label={title} />
                <Subtitle>{data?.elephant?.name}</Subtitle>

                <FormProvider onSubmit={onSubmit} defaultValues={{observation_range: ''}} >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} sx={{justifyContent: "center", alignItems: "center"}}> 
                                <FormDropDownAndUsed name="observation_range" label="Observation Timeframe" list={observationWindows.map((cur)=>{ return {label: cur}; })} required usedList={usedList} />
                            </Grid>

                            <Grid xs={12} sm={12}> 
                                <FormButton>Start</FormButton>
                            </Grid>
                        </Grid>

                    </Form>
                </FormProvider>

            </>}
        </Container>
    );
}

/*
                */