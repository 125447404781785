import * as React from 'react';

import {useLoaderData, useNavigate} from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Header from "../components/Header";
import ChangeCard from '../components/cards/ChangeCard';
import SmallCardGrid from '../components/SmallCardGrid';

import {listChanges} from '../models/changes';
import {listElephants} from '../models/elephants';
import {useLiveQuery} from 'dexie-react-hooks';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader() {
    //const changes = await listChanges();
    const elephants = await listElephants();
    //console.log(changes,elephants);
    //return {changes,elephants};
    return {elephants};
}

export default function ChangesPage({title = "Changes",...props}) {
    const navigate = useNavigate();

    // used to read the loader data that is returned from the function above
    //const {changes,elephants} = useLoaderData(); 
    const {elephants} = useLoaderData(); 

    const changes = useLiveQuery(listChanges);
    const sortedChanges = changes ? [...changes] : [];
    sortedChanges.sort((a, b) => {
        if (!a.end && b.end) {
            return -1;
        } else if (a.end && !b.end) {
            return 1;
        } else {
            return new Date(b.start) - new Date(a.start);
        }
    });

    const elephantLookup = elephants.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
    }, {});

    // if you're going to do a form submission, this is your starting point for the submit behavior
    /*
    const onSubmit = async (data) => {
        console.log(data);
    }
    */

    const navigateToChangeDetails = (id) => {
        console.log("Change ID: ", id);

        navigate("/changes/" + id);
    }
    const openChanges = sortedChanges.filter(change => !change.is_resolved);
    const resolvedChanges = sortedChanges.filter(change => !!change.is_resolved);

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main">
            <Header label={title} />
            {openChanges.length > 0 && (
                <>
                    <Typography sx={{m:2}} variant="h6">Open</Typography>
                    <Grid container spacing={2}>
                        {openChanges.map((change) => (
                            <SmallCardGrid key={change.id}>
                                <ChangeCard
                                    change={change}
                                    elephantLookup={elephantLookup}
                                    onActionAreaClick={() => navigateToChangeDetails(change.id)}
                                />
                            </SmallCardGrid>
                        ))}
                    </Grid>
                </>
            )}
            {resolvedChanges.length > 0 && (
                <>
                <div style={{height:1, border:"1px solid #f3f3f3", margin:"20px 0"}}/>
                    <Typography sx={{m:2}} variant="h6">Resolved</Typography>
                    <Grid container spacing={2}>
                        {resolvedChanges.map((change) => (
                            <SmallCardGrid key={change.id}>
                                <ChangeCard
                                    change={change}
                                    elephantLookup={elephantLookup}
                                    onActionAreaClick={() => navigateToChangeDetails(change.id)}
                                />
                            </SmallCardGrid>
                        ))}
                    </Grid>
                </>
            )}
            {openChanges.length === 0 && resolvedChanges.length === 0 && (
                <Typography>No Changes</Typography>
            )}
        </Container>
    );
}