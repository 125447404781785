import React from 'react';
import { RouterProvider } from "react-router-dom";

import {router as unauthenticatedRouter} from '../routes/unauthenticated';
import {router as authenticatedRouter} from '../routes/authenticated';

import {useAuth} from '../context/AuthProvider';

function Router() {
    const {user} = useAuth();

    if (user) {
        return (<RouterProvider router={authenticatedRouter} />);
    } else {
        return (<RouterProvider router={unauthenticatedRouter} />);
    }
}

export default Router;
