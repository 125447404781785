import * as React from 'react';

import {useForm} from './FormProvider';

import { Controller } from "react-hook-form"

import InputLabel from '@mui/material/InputLabel';
import CRUDDataGridModal from '../CRUDDataGridModal';

export default function FormChildDataGrid({name,label,newRow,columns,formRender,required,rules = {},labelProps={},...props}) {

    const {control, defaultRules, watch, setValue} = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    const watchValue = watch(name) || [];

    const [rows, setRows] = React.useState(watchValue);

    React.useEffect(()=>{
      setValue(name,rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rows]);

    const labelId = name + "-label";

    return (
        <Controller
            render={()=>{
              return (
                <>
                  <InputLabel id={labelId} required={required} sx={{whiteSpace: "normal"}} {...labelProps}>{label}</InputLabel>
                  <CRUDDataGridModal 
                    rows={rows}
                    setRows={setRows}
                    columns={columns}
                    makeRow={newRow}
                    formRender={formRender}
                  />
                </>
              );

            }}
            name={name}
            control={control}
            rules={rules}
        />
    );
};