import * as React from "react";
import { useLoaderData, useNavigate} from 'react-router-dom';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcon from "@mui/icons-material/Add";
import Header from "../components/Header";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material";
import { useState } from "react";
import Form from "../components/form/Form";
import FormButton from "../components/form/FormButton";
import FormDatePicker from "../components/form/FormDatePicker";
import { FormProvider } from "../components/form/FormProvider";
import { listElephants } from "../models/elephants";
import { useEffect } from "react";
import { listAssessments, saveAssessment } from "../models/assessments";
import { useAuth } from "../context/AuthProvider";
import {useMessage} from '../context/MessageProvider';
import dayjs from "dayjs";

import { useConnectivity } from "../context/ConnectivityProvider";


export async function loader() {
  const elephants = await listElephants();
  const assessments = await listAssessments();
  return { elephants, assessments };
}

export default function AssessmentsFormPage({ title = "Assessments", ...props }) {
  const {setSuccess, setError} = useMessage();
  const { elephants, assessments } = useLoaderData();

  const [elephantCheckboxes, setElephantCheckboxes] = useState([]);
  //const [selected, setSelected] = useState([]);
  const {facilityId,user} = useAuth();
  const navigate = useNavigate();
  const {isOnline} = useConnectivity();


  const inProgressAssessments = assessments.filter((cur)=>{
    return cur.started_on && !cur.submitted_on;
  });


  const handleCheckboxChange = (elephantId) => {
    setElephantCheckboxes((prevState) =>
      prevState.map((item) =>
        item.id === elephantId ? { ...item, checked: !item.checked } : item
      )
    );
  };


  useEffect(() => {
    if(elephants){
        // FIXME: handle the exclusion of elephants that already have an assessment
        setElephantCheckboxes(elephants.map((el) => ({ id: el.id, checked: false })))
    }
  }, [elephants])
  

  const handleSubmit = async (data) => {
    try{
      console.log(data, elephantCheckboxes);
      const selectedElephants = elephantCheckboxes?.filter(el => el.checked === true);
      
      const savePromises = selectedElephants.map(el => {
        const payloadObj = {
          started_on: data.date,
          elephant_id: el.id
        };
        console.log('operation')
        return saveAssessment(payloadObj, facilityId, user.id, isOnline);
      });
    
      console.log(await Promise.all(savePromises));
      setSuccess("Assessments Started");
      navigate("/assessments");
    }
    catch(e){
      console.error(e)
      setError(e);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Header label={title} />
      <FormProvider onSubmit={handleSubmit} defaultValues={{date:dayjs()}}>
        <Form>

          <Grid rowSpacing={4} container spacing={2} alignItems="center">
            <Grid item xs={12} s={3}>
              <FormDatePicker onChange={(e) => console.log(e)} name="date" label="Start Date" required />
            </Grid>
            <Grid xs={12} s={3} style={{position:"relative", border: "1px solid #b3b3b3", borderRadius: 10 }}>
              <Typography style={{padding:"0 6px",backgroundColor:"white", color:"#00554A", lineHeight:1,position:"absolute", top:-10}} variant="body1">Elephants to Assess</Typography>
              <FormGroup style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setElephantCheckboxes((prevState) =>
                          prevState.map((item) => ({
                            ...item,
                            checked: isChecked,
                          }))
                        );
                      }}
                    />
                  }
                  label="All elephants"
                />
                {elephants.map((el) => (
                  <FormControlLabel
                  //FIXME: the disable logic of the checkboxes should be handled by the status rather than the assessment existence
                  disabled={inProgressAssessments?.find(assessment=>assessment.elephant_id===el.id)}
                    control={
                      <Checkbox
                        checked={
                          !inProgressAssessments?.find(assessment=>assessment.elephant_id===el.id)?
                          elephantCheckboxes.find((item) => item.id === el.id)
                            ?.checked:false
                        }
                        onChange={() => handleCheckboxChange(el.id)}
                      />
                    }
                    label={el.name}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid container xs={12} marginBottom={2} justifyContent="flex-end">
              <Grid>
                <FormButton>
                  <AddIcon />
                  Add Assessment(s)
                </FormButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </FormProvider>
    </Container>
  );
}
