import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Header from "../components/Header";
import GoBack from '../components/GoBack';

export default function GenericPage({title,contentKey}) {

    const navigate = useNavigate();
	return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(-1)} />
            <Header label={title} />
        </Container>
	);
}