import { Tooltip } from "@mui/material";
import React from "react";
import { tooltipStrings } from "../../utils/tooltipStrings";

 const CustomTooltip=({
  keyString,
  override,
  children,
  placement = "top",
  ...props
}) =>{
  // Example of usage:
  // <CustomTooltip keyString="defaultInfo" override="Test message">
  //   <InfoOutlined />
  // </CustomTooltip>;

  //NOTE: the override prop can also be a react children
  return (
    <Tooltip
      className="ewp-tooltip"
      placement={placement}
      arrow
      title={override || tooltipStrings[keyString] || "Please provide a tooltip message using the keyStringProp"}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default CustomTooltip