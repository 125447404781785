import { createBrowserRouter} from "react-router-dom";

import Layout from '../components/layout/Layout';

import Pages from '../pages';
//import {loader as indexLoader} from '../pages/IndexPage';
import {loader as institutionLoader} from '../pages/InstitutionDetailsFormPage';
import {loader as elephantsLoader} from '../pages/ElephantListPage';
import {loader as elephantDashboardLoader} from '../pages/ElephantDashboardPage';
import {loader as elephantFormLoader} from '../pages/ElephantFormPage';
import {loader as changesLoader} from '../pages/ChangesPage';
import {loader as changeDetailsLoader} from '../pages/ChangeDetailsPage';
import {loader as changeResolveLoader} from '../pages/ChangeResolvePage';
import {loader as elephantRelationshipsLoader} from '../pages/ElephantRelationshipsForm';
import {loader as usersLoader} from '../pages/UsersListPage';
import {loader as userFormLoader} from '../pages/UserFormPage';
import {loader as userDashboardLoader} from '../pages/UserDashboardPage';
import {loader as userCreateLoader} from '../pages/UserCreatePage';
import {loader as profileLoader} from '../pages/ProfilePage';
import {loader as assessmentDashboardLoader} from '../pages/AssessmentDashboardPage';
import {loader as assessmentsListLoader} from '../pages/AssessmentsListPage';
import {loader as assessmentsFormLoader} from '../pages/AssessmentsFormPage';
import {loader as sectionAFormLoader} from '../pages/AssessmentSectionAFormPage';
import {loader as sectionBFormLoader} from '../pages/AssessmentSectionBFormPage';
import {loader as sectionCSelectLoader} from '../pages/AssessmentSectionCSelectPage';
import {loader as sectionBSelectLoader} from '../pages/AssessmentSectionBSelectPage';
import {loader as sectionCFormLoader} from '../pages/AssessmentSectionCFormPage';
import {loader as assessmentSubmitLoader} from '../pages/AssessmentSubmitPage';
import {loader as assessmentSectionCIncidentsOfAggressionListLoader} from '../pages/AssessmentSectionCIncidentsOfAggressionListPage';
import {loader as assessmentSectionCIncidentsOfAggressionFormLoader} from '../pages/AssessmentSectionCIncidentsOfAggressionFormPage';
import {loader as assessmentFinalEvaluationFormPageLoader} from '../pages/AssessmentFinalEvaluationFormPage';


import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import SyncIcon from '@mui/icons-material/Sync';
import Divider from '@mui/material/Divider';
import FacilityIcon from "../components/icons/FacilityIcon";

const drawerMenu = [
        {
            path: '/',
            id: "Home",
            text: "Home",
            icon: "HOME",
        },
        {
            path: '/assessments',
            id: "Assessments",
            text: "Assessments",
            icon: "ASSESSMENT",
        },
        {
            path: '/elephants',
            id: "Elephants",
            text: "Elephants",
            icon: "ELEPHANT"
        },
        {
            onClick: ()=>{console.log("report change")},
            id: "report_change",
            text: "Report Change",
            icon: "CHANGE"
        },
        {
            path: '/changes',
            id: "Changes",
            text: "Changes",
            icon: "CHANGELOG"
        },
        {
            id: "div1",
            render: ()=>(<Divider />)
        },
        {
            path: '/users',
            id: "Users",
            text: "Users",
            icon: "USERS",
        },
        {
            path: '/institution/details',
            id: "Institution Details",
            text: "Institution Details",
            icon: ()=>(<FacilityIcon />),
        },
        {
            path: '/profile',
            id: "Profile",
            text: "Profile",
            icon: ()=>(<PersonIcon />)
        },
        {
            path: '/moreinfo',
            id: "More Info",
            text: "More Info",
            icon: ()=>(<InfoIcon />)
        },
        {
            path: '/sync',
            id: "Sync",
            text: "Sync",
            icon: ()=>(<SyncIcon />)
        },
        {
            path: '/diagnostics',
            id: "Diagnostics",
            text: "Diagnostics",
            icon: "DIAGNOSTICS"
        },
];

const bottomBarMenu = [
        {
            path: '/',
            id: "Home",
            text: "Home",
            icon: "HOME",
        },
        {
            path: '/assessments',
            id: "Assessments",
            text: "Assessments",
            icon: "ASSESSMENT",
        },
        {
            path: '/elephants',
            id: "Elephants",
            text: "Elephants",
            icon: "ELEPHANT"
        },
        {
            onClick: ()=>{console.log("report change")},
            id: "report_change",
            text: "Report Change",
            icon: "CHANGE"
        },
        {
            path: '/changes',
            id: "Changes",
            text: "Changes",
            icon: "CHANGELOG"
        },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout menu={drawerMenu} bottomMenu={bottomBarMenu} />,
    errorElement: (<Layout menu={drawerMenu} bottomMenu={bottomBarMenu}><Pages.ErrorPage /></Layout>),
    children: [
      {
        index: true,
        element: <Pages.IndexPage title="Home"/>,
        //loader: indexLoader,
      },
      {
        text: "Changes",
        path: "/changes",
        loader: changesLoader,
        element: <Pages.ChangesPage title="Changes" />
      },
      {
        text: "Change Details",
        path: "/changes/:changeId",
        loader: changeDetailsLoader,
        element: <Pages.ChangeDetailsPage title="Change Details" />
      },
      {
        text: "Resolve Change",
        path: "/changes/:changeId/resolve",
        loader: changeResolveLoader,
        element: <Pages.ChangeResolvePage title="Resolve Change" />
      },
      {
        text: "Diagnostics",
        path: "/diagnostics",
        element: <Pages.DiagnosticsPage title="Diagnostics" />,
      },
      {
        path: "contacts/:contactId",
        element: <Pages.GenericPage />,
      },
      {
        path: "test",
        element: <Pages.TestPage title="Test Page"/>,
      },
      {
        path: "testform",
        element: <Pages.TestFormPage title="Test Form Page"/>,
      },
      {
        path: "testdatagrid",
        element: <Pages.TestDataGrid title="Test Data Grid"/>,
      },
      {
        path: "/institution/details",
        element: <Pages.InstitutionDetailsFormPage title="Institution Details"/>,
        loader: institutionLoader,
      },
      {
        path: "/sync",
        element: <Pages.SyncPage title="Manual Sync"/>,
      },
      {
        path: "/elephants",
        element: <Pages.ElephantListPage title="Elephants"/>,
        loader: elephantsLoader,
      },
      {
        path: "/elephants/form",
        element: <Pages.ElephantFormPage title="Add Elephant"/>,
        loader: elephantFormLoader,
      },
      {
        path: "/elephants/form/:elephantId",
        element: <Pages.ElephantFormPage title="Edit Elephant"/>,
        loader: elephantFormLoader,
      },
      {
        path: "/elephants/:elephantId",
        element: <Pages.ElephantDashboardPage title="Elephant"/>,
        loader: elephantDashboardLoader,
      },
      {
        path: "/elephants/:elephantId/relationships",
        element: <Pages.ElephantRelationshipsForm title="Elephant Relationships"/>,
        loader: elephantRelationshipsLoader,
      },
      {
        path: "/users",
        element: <Pages.UsersListPage title="Users"/>,
        loader: usersLoader,
      },
      {
        path: "/users/:userId",
        element: <Pages.UserDashboardPage title="User"/>,
        loader: userDashboardLoader,
      },
      {
        path: "/users/create",
        element: <Pages.UserCreatePage title="Add User"/>,
        loader: userCreateLoader,
      },
      {
        path: "/users/form",
        element: <Pages.UserFormPage title="Edit User"/>,
        loader: userFormLoader,
      },
      {
        path: "/users/form/:userId",
        element: <Pages.UserFormPage title="Edit User"/>,
        loader: userFormLoader,
      },
      {
        path: "/profile",
        element: <Pages.ProfilePage title="User Profile"/>,
        loader: profileLoader,
      },
      {
        path: "/profile/change_password/*",
        element: <Pages.ProfileChangePasswordPage title="Change Password"/>,
      },
      {
        path: "/assessments",
        element: <Pages.AssessmentsListPage title="Assessments"/>,
        loader: assessmentsListLoader,
      },
      {
        path: "/assessments/new",
        element: <Pages.AssessmentsFormPage title="New Assessments"/>,
        loader: assessmentsFormLoader,
      },
      {
        path: "/assessments/:assessmentId",
        element: <Pages.AssessmentDashboardPage title="Assessment"/>,
        loader: assessmentDashboardLoader,
      },
      {
        path: "/assessments/:assessmentId/submit",
        element: <Pages.AssessmentSubmitPage title="Assessment Submission"/>,
        loader: assessmentSubmitLoader,
      },
      {
        path: "/assessments/:assessmentId/finalevaluation",
        element: <Pages.AssessmentFinalEvaluationFormPage title="Final Evaluation"/>,
        loader: assessmentFinalEvaluationFormPageLoader,
      },
      {
        path: "/assessments/:assessmentId/sectionb",
        element: <Pages.AssessmentSectionBSelectPage title="Section B"/>,
        loader: sectionBSelectLoader,
      },
      {
        path: "/assessments/:assessmentId/sectionb/:sectionBId",
        element: <Pages.AssessmentSectionBFormPage title="Section B"/>,
        loader: sectionBFormLoader,
      },
      {
        path: "/assessments/:assessmentId/sectionc",
        element: <Pages.AssessmentSectionCSelectPage title="Section C"/>,
        loader: sectionCSelectLoader,
      },
      {
        path: "/assessments/:assessmentId/sectionc/:sectionCId",
        element: <Pages.AssessmentSectionCFormPage title="Section C"/>,
        loader: sectionCFormLoader,
      },
      {
        path: "/assessments/:assessmentId/sectiona",
        element: <Pages.AssessmentSectionASelectPage title="Section A"/>,
      },
      {
        path: "/assessments/:assessmentId/sectiona/:sectionAId",
        element: <Pages.AssessmentSectionAFormPage title="Section A"/>,
        loader: sectionAFormLoader,
      },

      {
        path: "/assessments/:assessmentId/sectionc/incidents_of_aggression",
        element: <Pages.AssessmentSectionCIncidentsOfAggressionListPage title="Section C: Incidents Of Aggression"/>,
        loader: assessmentSectionCIncidentsOfAggressionListLoader,
      },
      {
        path: "/assessments/:assessmentId/sectionc/incidents_of_aggression/form",
        element: <Pages.AssessmentSectionCIncidentsOfAggressionFormPage title="Add Section C: Incidents Of Aggression"/>,
        loader: assessmentSectionCIncidentsOfAggressionFormLoader,
      },
      {
        path: "/moreinfo",
        element: <Pages.MoreInfoPage title="More Info"/>,
      },
      {
        path: "/info/help-tips",
        element: <Pages.GenericPage title="Help/Tips"/>,
      },
      {
        path: "/info/privacy-policy",
        element: <Pages.GenericPage title="Privacy Policy"/>,
      },
      {
        path: "/info/terms-and-conditions",
        element: <Pages.GenericPage title="Terms and Conditions"/>,
      },
      {
        path: "/info/app-license-details",
        element: <Pages.GenericPage title="App License Details"/>,
      },
    ]
  },
]);


export {router,drawerMenu,bottomBarMenu};