import * as React from 'react';

import * as rhf from "react-hook-form"

const FormContext = React.createContext({
    control: {},
    handleSubmit: () => {},
    errors: {},
    reset: () => {},
    onSubmit: () => {},
    defaultRules: {}
});

const defaultRules = {
    required: {
        value: true,
        message: "This field is required"
    }
}

export function useForm() {
    const context = React.useContext(FormContext);
    if (!context) {
        throw new Error('useForm must be used within a FormProvider');
    }
    return context;
};

export const FormProvider = ({onSubmit,onChange,defaultValues = {},useFormConfig = {},resetAfterSubmit = false,...props}) => {

    // const {
    //     control,
    //     handleSubmit,
    //     formState,
    //     formState: { errors },
    //     reset

    const methods = rhf.useForm({
        defaultValues,
        ...useFormConfig
    });

    React.useEffect(()=>{
        if (methods.formState.isSubmitSuccessful && resetAfterSubmit) {
            methods.reset(defaultValues);
        }
    },[methods.formState, methods, defaultValues, resetAfterSubmit])


    return (
        <FormContext.Provider value={{onSubmit,onChange,errors: methods.formState.errors, defaultRules, ...props,  ...methods}}>
            {props.children} 
        </FormContext.Provider>
    );
};