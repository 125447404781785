import { Card } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import FormOnChange from "../components/form/FormOnChange";
import FormButton from "../components/form/FormButton";
import { FormProvider } from "../components/form/FormProvider";
import FormTextField from "../components/form/FormTextField";
import FormMultiSelectChips from "../components/form/FormMultiSelectChips";
import { useAuth } from "../context/AuthProvider";
import { useMessage } from "../context/MessageProvider";
import { getAssessment } from "../models/assessments";
import { listElephants } from "../models/elephants";
import { getFacility } from "../models/facility";
import FormRadioGroup from "../components/form/FormRadioGroup";
import {
  listSectionCsByAssessment,
  newSectionC,
  getSectionC,
  saveSectionC,
} from "../models/sectionc";
import { halfHourTimeSlots, today } from "../utils/datetime";
import { ArrowBack } from "@mui/icons-material";

import { useConnectivity } from "../context/ConnectivityProvider";

import CustomTooltip from '../components/tooltip/CustomTooltip';
import HelpIcon from '@mui/icons-material/Help';

import _ from 'lodash';

export async function loader({ params }) {
  const assessment = await getAssessment(params.assessmentId);
  const sectionC = await getSectionC(params.sectionCId);
  const sectionCs = await listSectionCsByAssessment(assessment.id);

  return {
    assessment,
    elephants: await listElephants(),
    facility: await getFacility(),
    sectionC,
    sectionCs,
  };
}

export default function AssessmentSectionCFormPage({ title = "EWP", ...props }) {

  const { setSuccess } = useMessage();
  const navigate = useNavigate();
  const { facility, assessment, sectionC, sectionCs, elephants } = useLoaderData();

  const [showDescription,setShowDescription] = useState(false);
  const [showElephants,setShowElephants] = useState(false);

  const { user } = useAuth();
  const ranges = halfHourTimeSlots(facility.end_of_day, facility.start_of_day);
  const { isOnline } = useConnectivity();

  const [behavior, setBehavior] = useState(null);

  const behaviors={
    "feeding": { 
      label: "Feeding", 
      tooltip: <CustomTooltip keyString="section_c_feeding"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: false,
      hasElephants: false,
    },

    "lying_down_alone": {
      label: "Lying Down Alone",
      tooltip: <CustomTooltip keyString="section_c_lying_down_alone"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: false,
      hasElephants: false,
    },

    "lying_down_with_others": {
      label: "Lying Down With Others",
      tooltip: <CustomTooltip keyString="section_c_lying_down_with_others"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: false,
      hasElephants: true,
      elephantsKey: "lying_down_with_others_elephants",
    },

    "stereotyping": { 
      label: "Stereotyping", 
      tooltip: <CustomTooltip keyString="section_c_stereotyping"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: true,
      hasElephants: false,
      descriptionKey: "stereotyping_description"
    },

    "self_maintenance": {
      label: "Self Maintenance",
      tooltip: <CustomTooltip keyString="section_c_self_maintenance"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: true,
      hasElephants: false,
      descriptionKey: "self_maintenance_description"
    },

    "interaction_with_environment": {
      label: "Interaction with Environment",
      tooltip: <CustomTooltip keyString="section_c_interaction_with_environment"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: true,
      hasElephants: false,
      descriptionKey: "interaction_with_environment_description"
    },

    "social_affiliative": {
      label: "Social Affiliative",
      tooltip: <CustomTooltip keyString="section_c_social_affiliative"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: false,
      hasElephants: true,
      elephantsKey: "social_affiliative_elephants",
    },

    "other": { 
      label: "Other", 
      tooltip: <CustomTooltip keyString="section_c_other"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: true,
      hasElephants: false,
      descriptionKey: "other_description"
    },
    "out_of_view": { 
      label: "Out of View", 
      tooltip: <CustomTooltip keyString="section_c_out_of_view"><HelpIcon sx={{marginLeft: 1}}/></CustomTooltip>,
      hasDescription: false,
      hasElephants: false,
    },
  };


  const behaviorList = Object.keys(behaviors).map((key)=>{
    const record = behaviors[key];
    return {
      label: record.label,
      value: key,
      tooltip: record.tooltip || undefined,
    }
  });

  const elephantList = elephants.map((cur)=>{
    return {
      label: cur.name,
      value: cur.id,
    };
  });

  const save = async(formData,moreData) => {
    moreData = moreData || {};
    let behaviorObj = Object.keys(behaviors).reduce((acc,key)=>{
      const record = behaviors[key];
      acc[key] = false;
      if (record.hasDescription) acc[record.descriptionKey] = null;
      if (record.hasElephants) acc[record.elephantsKey] = [];
      return acc;
    },{});

    if (formData.behavior) {
      behaviorObj[formData.behavior] = true;
      if (behaviors[formData.behavior]['hasDescription']) {
        const key = behaviors[formData.behavior]['descriptionKey'];
        behaviorObj[key] = formData.description;
      }

      if (behaviors[formData.behavior]['hasElephants']) {
        const key = behaviors[formData.behavior]['elephantsKey'];
        behaviorObj[key] = formData.elephants;
      }
    }

    behaviorObj.additional_comments = formData.additional_comments;


    const payload = {...sectionC,...behaviorObj,...moreData};
    console.log("save payload: ", payload);

    await saveSectionC(payload,isOnline);
  };

  const onChange = _.debounce(async (formData) => {
    console.log("onChange formData: ", formData);

    if (formData.behavior && behaviors[formData.behavior]["hasDescription"]) {
      setShowDescription(true);
    } else {
      setShowDescription(false);
    }

    if (formData.behavior && behaviors[formData.behavior]["hasElephants"]) {
      setShowElephants(true);
    } else {
      setShowElephants(false);
    }

    setBehavior(formData.behavior);
    await save(formData);

  },500);

  const onSubmit = async(formData) => {

    await save(formData,{submitted_on: today()});


    // after saving the current record, find the next observation window and switch to it
    const nextObservationRange = determineNextObservationRange();
    if (nextObservationRange) {
      const newRecord = {...newSectionC(),observation_range: nextObservationRange, observed_on: today(), assessment_id: assessment.id, user_id: user.id};
      console.log("newRecord: ", newRecord);

      const saveResult = await saveSectionC(newRecord,isOnline);
      console.log("sectiona save: ", saveResult);

      setSuccess("Started Section C " + nextObservationRange);
      navigate("/assessments/" + assessment.id + "/sectionc/" + saveResult.id);

    } else {
      navigate("/assessments/" + assessment.id);
    }
  };



  const determineNextObservationRange = () => {

    const usedRanges = sectionCs.map((cur)=>{
      return cur.observation_range;
    });

    const remainingRanges = ranges.filter((cur)=>{
      return (usedRanges.includes(cur) === false);
    });

    if (remainingRanges.length > 0) {
      return remainingRanges[0];
    } else {
      return null;
    }
  };


  const formDefaultValues = {
    behavior: null,
    elephants: [],
    description: null,
    additional_comments: null,
  }

  const assignDefaultValues = (sectionC) => {

    const formDefaultValues = {
      behavior: null,
      elephants: [],
      description: null,
      additional_comments: null,
    }
    formDefaultValues.additional_comments = sectionC.additional_comments;

    const keys = Object.keys(behaviors);
    const matchedKeys = keys.filter((key)=>{
      return sectionC[key] === true;
    });

    if (matchedKeys.length === 1) {
      const key = matchedKeys[0];

      formDefaultValues.behavior = key;
      if (behaviors[key]['hasDescription']) {
        formDefaultValues.description = sectionC[behaviors[key]['descriptionKey']];
      }

      if (behaviors[key]['hasElephants']) {
        formDefaultValues.elephants = sectionC[behaviors[key]['elephantsKey']];
      }
    }

    return formDefaultValues;
  }


  //const formDefaultValues = assignDefaultValues(sectionC);
  const updatedValues = assignDefaultValues(sectionC);

  return (
    <Container component="main" maxWidth="sm">
      <ArrowBack
        onClick={() => navigate(`/assessments/${assessment.id}`)}
        sx={{ position: "absolute", "&:hover": { cursor: "pointer" } }}
      />
      <Header
        label={`${title} ${
          sectionC ? "(" + sectionC?.observation_range + ")" : ""
        }`}
      />
        <FormProvider defaultValues={formDefaultValues} useFormConfig={{values: updatedValues}} onSubmit={onSubmit} onChange={onChange}>
          <FormOnChange>
                <Card sx={{ padding: 2 }}>

                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <FormRadioGroup
                        name="behavior"
                        label="Behavior"
                        list={behaviorList}
                      />
                    </Grid>

                    {showElephants && 
                      <Grid xs={12}>
                        <FormMultiSelectChips enableSelectAll name="elephants" label="Elephants" list={elephantList} />

                     </Grid>
                    }

                    {showDescription && 
                      <Grid xs={12}>
                        <FormTextField
                          name="description"
                          multiline
                          rows={4}
                          sx={{mb:2}}
                          //ref={inputRef}
                          label={((behavior) ? behaviors[behavior]['label'] + " " : "") + "Description"}
                        />
                      </Grid>}

                    <Grid xs={12}>
                      <FormTextField
                        name="additional_comments"
                        label="Additional Comments"
                        sx={{mt:2}}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <FormButton>Next</FormButton>
                    </Grid>


                  </Grid>
                </Card>


          </FormOnChange>
        </FormProvider>
    </Container>
  );
}