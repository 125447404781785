import { supabase } from '../lib/supabase'

async function jsonToStorage(fileName, jsonString) {
    const { data, error } = await supabase.storage
      .from(process.env.REACT_APP_SUPABASE_BUCKET_NAME)
      .upload(fileName, jsonString);
  
    if (error) {
      console.error('Error uploading JSON:', error);
    } else {
      console.log('JSON uploaded successfully:', data);
    }
  }


const execute = async(input) => {
    const {data, error} = await input;
    if (error) throw error;
    return data;
}

const executeReturnRow = async(input) => {
    const {data, error} = await input;
    if (error) throw error;
    if (data.length === 0) throw new Error("Could not locate row of data");
    return data[0];
}

const createSignedUrl = async ({bucketName,bucketKey,expiresIn = 300, options = {download: true}}) => {
  console.log(bucketName,bucketKey,expiresIn,options);
  try {
    const results = await execute(supabase.storage.from(bucketName).createSignedUrl(bucketKey,expiresIn,options))	
    return results.signedUrl;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export {jsonToStorage,execute,executeReturnRow,createSignedUrl}