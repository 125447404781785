import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LinkIcon from '@mui/icons-material/Link';

import CloudOnIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import {Link} from 'react-router-dom';

import {useConnectivity} from '../../context/ConnectivityProvider';

const Item = ({open,path,id,onClick,text,icon,render, color, closeOnClick}) => {
    if (path) {
        return (
            <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={path} onClick={closeOnClick} style={{textDecoration: "none", color: color}}>
                <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
                >
                <ListItemIcon
                    sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >{icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </Link>
            </ListItem>
        );
    } else if (onClick) {
        return (
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
                onClick={()=>{
                    onClick();
                    if (typeof closeOnClick === "function") closeOnClick();
                }}
                >
                <ListItemIcon
                    sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    }}
                >{icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        );
    } else {
        return render();
    }
}

export default function AuthenticatedDrawerList({open,menu,closeOnClick,...props}) {
    const theme = useTheme();
    const {isOnline} = useConnectivity();

    console.log(theme);

    menu = menu || [];

    const list = menu.map((cur)=>{
        let newIcon = cur.icon;
        if (typeof cur.icon === "string") newIcon = theme.icons[cur.icon];
        if (typeof cur.icon === "function") newIcon = cur.icon();
        if (!('icon' in cur)) newIcon = <LinkIcon />;
        return {...cur,icon: newIcon};
    });




    return (
        <>
            <Divider />
            <List>
                {list.map((cur)=>( <Item key={cur.id} open={open} color={theme.palette.text.primary} closeOnClick={closeOnClick} {...cur} /> ))}
                <Divider />

                <ListItem disablePadding sx={{ 
                    display: 'flex',
                    py: 1,
                    pl: 2.5
                }}>
                    <ListItemIcon
                        sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        }}
                    >{isOnline && <CloudOnIcon variant="outline" color="success" />}{!isOnline && <CloudOffIcon color="error"/>}
                    </ListItemIcon>
                    <ListItemText primary="Online Status" sx={{ opacity: open ? 1 : 0 }} />
                </ListItem>

            </List>
        </>
    );
}