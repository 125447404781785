import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
  ListItemText,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import FormHelperText from '@mui/material/FormHelperText';

import {listLabel} from '../../utils/lists';
import { useState,useEffect } from "react";


/*
requires the value to be an array on initialization
*/

export default function FormMultiSelectChips({
  name,
  enableSelectAll,
  enableSelectNone,
  label,
  value,
  onChange,
  list,
  required,
  isError = false,
  errorMessage = null,
  fullWidth,
  formControlProps = {},
  labelProps = {},
  menuItemProps = {},
  selectProps = {},
  formHelperProps = {}
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [noOptionsSelected, setNoOptionsSelected] = useState(false)
  const [val, setVal] = useState(value);

  useEffect(()=>{
    setVal(value);
  },[value]);

  //const { control, errors, defaultRules, setValue } = useForm();

  //const requiredDefaultRule = required ? { required: defaultRules['required'] } : {};
  //rules = { ...requiredDefaultRule, ...rules };

  fullWidth = fullWidth === undefined ? true : fullWidth;

  const labelId = name + "-label";

      //render={({ field: { onChange, value, onBlur } }) => {

  return (
    <FormControl fullWidth error={isError} {...formControlProps}>
      <InputLabel id={labelId} required={required} {...labelProps}>
        {label}
      </InputLabel>
      <Select
        multiple
        labelId={labelId}
        value={val}
        onChange={(e) => {
          setNoOptionsSelected(false);
          if(e.target.value.includes('All')){
            if (!selectAll) {
              const allValues = list.map((cur) => cur.value);
              setVal(allValues);
              onChange(allValues,e);
            } else {
              setVal([]);
              onChange([],e);
            }
            setSelectAll(!selectAll);
          }
          else{
            if(e.target.value.includes('None')){
              setNoOptionsSelected(true);
              setVal(['None']);
              onChange(['None']);
            }
            else{
              if (enableSelectAll && e.target.value.length === list.length) {
                setSelectAll(true);
              }
              setVal(e.target.value);
              onChange(e.target.value,e);
            }
          }
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((cur) => (
              <Chip
                key={cur}
                label={listLabel(cur, list)}
                onDelete={() =>{
                    setNoOptionsSelected(false);
                    const newList = val.filter((item) => item !== cur);
                    setVal(newList)
                    onChange(newList)
                }}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
        {...selectProps}
      >
        {enableSelectAll && (
          <MenuItem  key={'All'}
          value={'All'} 
          disabled={noOptionsSelected}
          {...menuItemProps}
          >
            <ListItemText
              primary={val.length===list.length?"Unselect All":"Select all"}
            />
          </MenuItem>
        )}
        {enableSelectNone && (
          <MenuItem  key={'None'}
          value={'None'} 
          {...menuItemProps}
          >
            <ListItemText
              primary="No Elephants Nearby"
            />
          </MenuItem>
        )}
        {list.map((cur) => (
          <MenuItem
            key={cur.value}
            disabled={noOptionsSelected}
            value={cur.value}
            sx={{ justifyContent: "space-between" }}
            {...menuItemProps}
          >
            {cur.label}
            {val.includes(cur.value) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && (
        <FormHelperText {...formHelperProps}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
    );

}
