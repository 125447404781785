import * as React from 'react';

import {useLoaderData, useNavigate} from 'react-router-dom';
import {useMessage} from '../context/MessageProvider';
import {useAuth} from '../context/AuthProvider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';
import FormDropDown from '../components/form/FormDropDown';
import FormDatePicker from '../components/form/FormDatePicker';
import FormRadioGroup from '../components/form/FormRadioGroup';

import Header from "../components/Header";

import { getElephant, newElephant, saveElephant } from '../models/elephants';
import {sex, genus, knownEstimated} from "../models/categories";

import { useConnectivity } from '../context/ConnectivityProvider';

// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader({params}) {
    console.log(params);

    const elephant = (params.elephantId) ? await getElephant(params.elephantId) : await newElephant();

    return {elephant, sex: await sex(), genus: await genus(), knownEstimated: await knownEstimated()};
}


export default function TemplatePage({title = "EWP",...props}) {
    const {setSuccess, setError} = useMessage();
    const {facilityId} = useAuth();
    const navigate = useNavigate();
    const {isOnline} = useConnectivity();

    console.log("facilityId: ", facilityId);
    // used to read the loader data that is returned from the function above
    const {elephant, sex, genus, knownEstimated} = useLoaderData(); 
    console.log("Elephant Record: ", elephant, sex, genus);

    // if you're going to do a form submission, this is your starting point for the submit behavior
    const onSubmit = async (data) => {
        console.log(data);
        try {
            const {id} = await saveElephant(data,facilityId,isOnline);
            setSuccess("Elephant Saved");
            navigate("/elephants/"+ id);
        } catch (error) {
            setError(error);
        }
    }

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            <FormProvider onSubmit={onSubmit} defaultValues={elephant} >
                <Form>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6}> 
                            <FormTextField name="name" label="Elephant Name" required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormTextField name="studbook_number" label="Studbook Number" required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormDropDown name="sex" label="Sex" list={sex} required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormDropDown name="genus" label="Genus" list={genus} required />
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormDatePicker name="born_on" label="Date Born" required/>
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormRadioGroup name="born_on_is_estimated" label="Date Born" list={knownEstimated} required/>
                        </Grid>


                        <Grid xs={12} sm={6}> 
                            <FormDatePicker name="entered_captivity_on" label="Date Entered Captivity" required/>
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormRadioGroup name="entered_captivity_on_is_estimated" label="Date Entered Captivity" list={knownEstimated} required/>
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormDatePicker name="arrived_on" label="Date Arrived At Current Institution" required/>
                        </Grid>

                        <Grid xs={12} sm={6}> 
                            <FormRadioGroup name="arrived_on_is_estimated" label="Date Arrived At Current Institution" list={knownEstimated} required/>
                        </Grid>


                        <Grid xs={12}>
                            <FormButton>Submit</FormButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormProvider>



        </Container>
    );
}