import * as React from 'react';

import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';

import {useAuth} from '../context/AuthProvider';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Header from "../components/Header";
import GoBack from '../components/GoBack';

import { useNavigate } from 'react-router-dom';
import { useMessage} from '../context/MessageProvider';
import { supabase } from '../lib/supabase'

export default function ProfileChangePasswordPage({title = "EWP",...props}) {

    const {setMessage,setError} = useMessage();
    const {user} = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            console.log("submit data: ",data);
            const updateResults = await supabase.auth.updateUser({
                password: data.password
            });
            if (updateResults.error) throw updateResults.error;

            setMessage("Password Updated");
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };

    const password = React.useRef({});
    console.log("password: ", password);

    return (
        <Container component="main" maxWidth="sm">
            <GoBack onClick={()=>navigate(-1)} />
            <Header label={title} />
            <Grid container columnSpacing={2} rowSpacing={2} sx={{marginBottom: 2}}>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">User ID</Typography>    
                    <Typography variant="body2">{user.id}</Typography>    
                </Grid>
                <Grid xs={6} sm={6}> 
                    <Typography variant="body1" color="secondary">Email</Typography>    
                    <Typography variant="body2">{user.email}</Typography>    
                </Grid>
            </Grid>


            <FormProvider onSubmit={onSubmit} defaultValues={{password: null, confirm_password: null}}>
                <Form>
                    <Grid container spacing={2}>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="password" label="Password" type="password" refValue={password} rules={{
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters"
                                  }
                            }} required/>
                        </Grid>

                        <Grid xs={12} sm={12}> 
                            <FormTextField name="confirm_password" label="Confirm Password" type="password" required rules={{
                                validate: value => value === password.current || 'The passwords do not match'  // JS only: <p>error message</p> TS only support string
                            }}/>
                        </Grid>

                        <Grid xs={12}>
                            <FormButton>Update Password</FormButton>
                        </Grid>

                    </Grid>
                </Form>
            </FormProvider>

        </Container>
    );
}