import * as React from 'react';

//import Grid from '@mui/material/Grid';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import logo from '../assets/logo.png';
import Image from 'mui-image';


import {FormProvider} from '../components/form/FormProvider';
import Form from '../components/form/Form';
import FormTextField from '../components/form/FormTextField';
import FormButton from '../components/form/FormButton';

import Header from '../components/Header';

import {useAuth} from '../context/AuthProvider';

export default function SignIn() {

  const {sendForgotPassword} = useAuth();


  const onSubmitForgotPassword = async (data) => {
    await sendForgotPassword(data);
  }

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src={logo} width={128} />

          <Header label="Forgot Password"/>
          <FormProvider onSubmit={onSubmitForgotPassword} defaultValues={{email: ''}}>
            <Form>
                <Grid container spacing={2}>
                    <Grid xs={12}> 
                      <FormTextField name="email" label="Email" required autoComplete="email" autoFocus />
                    </Grid>
                    <Grid xs={12}> 
                      <FormButton fullWidth>Send Email</FormButton>
                    </Grid>
                  </Grid>
            </Form>
          </FormProvider>
        </Box>
      </Container>
  );
}