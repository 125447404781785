import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import { errorMessage } from '../utils/errors';
import { syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets } from '../utils/sync';

const template = () => {
    const record = {
        id: null,
        user_id: null,
        facility_id: null,
        elephant_id: null,
        started_on: true,
        submitted_on: true,
        confirm_section_a_incomplete: false,
        confirm_section_b_incomplete: false,
        confirm_section_c_incomplete: false,
        confirm_section_cioa_incomplete: false,
        no_incidents_of_aggression:false,
        report_path: null,
        report_title: null,
        report_range: null,
    };

    return {...record};
}


const transformFromDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['started_on'] = dateFromStorage(temp['started_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    /*
    temp['report_path'] = temp['report_path'] || "";
    temp['report_title'] = temp['report_title'] || "";
    temp['report_range'] = temp['report_range'] || "";
    */
    return temp;
}

const transformToDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['started_on'] = dateToStorage(temp['started_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on']);
    // we don't want these three fields to be populated from the client at all, just read from the server
    delete temp['report_path'];
    delete temp['report_title'];
    delete temp['report_range'];
    return {assessment: temp};
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['started_on'] = dateToStorage(temp['started_on']);
    temp['submitted_on'] = dateToStorage(temp['submitted_on'])||null;
    return temp;
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['started_on'] = dateFromStorage(temp['started_on']);
    temp['submitted_on'] = dateFromStorage(temp['submitted_on']);
    return temp;
}


const newAssessment = () =>  {
    return template();
};

const listAssessments = async() => {
    try {

        const temp = await db().assessments.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listAssessmentsBySync = async(lastSync) => {
    try {

        const temp = await db().assessments.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listAssessmentsByElephant = async(elephantId) => {
    try {

        //const temp = await db().assessments.where('elephant_id').equals(elephantId).sortBy('started_on').reverse().toArray();
        const temp = await db().assessments.where('elephant_id').equals(elephantId).reverse().sortBy('started_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

/*
const listAssessmentsByOpenStatus = async() => {
    try {

        //const temp = await db().assessments.where('elephant_id').equals(elephantId).sortBy('started_on').reverse().toArray();
        const temp = await db().assessments.where('submitted_on').notEqual("").reverse().sortBy('started_on');
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}
*/

const fetchAssessments = async() => {
    try {
        const fetchResults = await supabase
            .from('assessments')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchAssessmentsBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('assessments')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getAssessment = async(id) => {
    try {

        const temp = await db().assessments.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchAssessment = async(id) => {

    try {
        const fetchResults = await supabase
            .from('assessments')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return transformFromDb(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeAssessmentToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().assessments.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const writeAssessmentToSupabase = async(inputRecord, facilityId) => {
    try {

        inputRecord.facility_id = inputRecord.facility_id || facilityId;

        const {assessment} = transformToDb(inputRecord);

        const assessmentResult = await supabase.from("assessments").upsert(assessment);
        console.log("assessmentResult: ", assessmentResult);
        if (assessmentResult.error) throw new Error(errorMessage(assessmentResult.error));
        return {assessmentResult, id: assessment.id};


    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveAssessment = async(inputRecord, facilityId, userId, isOnline) => {

    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.facility_id = inputRecord.facility_id || facilityId;
    inputRecord.user_id = inputRecord.user_id || userId;
    inputRecord.sync = syncValue;

    const dexieResults = await writeAssessmentToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeAssessmentToSupabase(inputRecord,facilityId) : {status: "offline"};
    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const sync = async({facilityId, lastSync = null}) => {

    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeAssessmentToSupabase, 
        localWriteFunc: writeAssessmentToDexie, 
        remoteWriteFuncArgs: [facilityId]});
    return results;
}

const syncFullRecordSets = async (facilityId) => {
    const remoteData = await fetchAssessments() || [];
    const localData = await listAssessments() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchAssessmentsBySync(lastSync) || [];
    const localData = await listAssessmentsBySync(lastSync) || [];

    return {remoteData, localData};
};


const truncateDexie = async() => {
    return await db().assessments.clear();
}


const noIncidentsOfAggression = async(assessmentId, isOnline) => {
    try {
        const result = await db().assessments.where('id').equals(assessmentId).modify({no_incidents_of_aggression:true});
        return result;
    } catch (error) {
        throw error;
    }
}

export {newAssessment, listAssessments, listAssessmentsByElephant, getAssessment, fetchAssessments, fetchAssessment, saveAssessment, sync, truncateDexie,noIncidentsOfAggression};
