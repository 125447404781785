import React from 'react';
import './SplashScreen.css'; // Import your CSS file for styling
import Logo from '../../assets/logo.png'
import { useEffect } from 'react';
function SplashScreen({loading=true}) {


  useEffect(() => {
    if(!loading){
      setTimeout(() => {
        document.querySelector('.splash-image').classList.add('pulse');
      }, 1000);
    }
  },[loading])
  return (
    <div className={`splash-screen`}>
      <img src={Logo} alt="Splash Screen" className="splash-image" />
    </div>
  );
}

export default SplashScreen;