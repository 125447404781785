import {lookup, keys, recordsNotInKeyList, recordsEqualByField} from "./lists";

const syncRecordsets = async({remoteData,localData,remoteWriteFunc,localWriteFunc,remoteWriteFuncArgs = [], localWriteFuncArgs = [],remoteLookupField = 'id', localLookupField = 'id', remoteKeyField = 'id', localKeyField = 'id'}) => {
    const results = {
        localWrites: [],
        remoteWrites: [],
        syncsEqual: []
    };

    console.log(typeof remoteData);
    console.log(Array.isArray(remoteData));
    if (Array.isArray(remoteData) === false) throw new Error("remoteData needs to be set as an array of records");
    if (Array.isArray(localData) === false) throw new Error("localData needs to be set as an array of records");
    if (typeof remoteWriteFunc !== "function") throw new Error("remoteWriteFunc needs to be set to a function that writes supabase records");
    if (typeof localWriteFunc !== "function") throw new Error("localWriteFunc needs to be set to a function that writes supabase records");

    console.log(remoteData);
    console.log(localData);

    const remoteLookup = lookup(remoteData,remoteLookupField);
    const localLookup = lookup(localData,localLookupField);

    const remoteKeys = keys(remoteData,remoteKeyField);
    const localKeys = keys(localData,localKeyField);
    console.log(remoteKeys);
    console.log(localKeys);
    
    const missingRemoteData = recordsNotInKeyList(remoteData,localKeys);
    const missingLocalData = recordsNotInKeyList(localData,remoteKeys);
    console.log(missingRemoteData);
    console.log(missingLocalData);

    if (missingRemoteData.length > 0) {
        const insertResults = await Promise.all(missingRemoteData.map((cur)=>{
            return localWriteFunc(cur,...localWriteFuncArgs);
        }));
        console.log(insertResults);
        results.localWrites = results.localWrites.concat(insertResults.map((cur)=>cur.id));
    }

    if (missingLocalData.length > 0) {
        const insertResults = await Promise.all(missingLocalData.map((cur)=>{
            return remoteWriteFunc(cur,...remoteWriteFuncArgs);
        }));
        console.log(insertResults);
        results.remoteWrites = results.remoteWrites.concat(insertResults.map((cur)=>cur.id));
    }

    const newerLocalData = localData.filter((cur)=>{
        const record = remoteLookup[cur.id] || null;
        return (record && cur.sync > record.sync);
    });

    const newerRemoteData = remoteData.filter((cur)=>{
        const record = localLookup[cur.id] || null;
        return (record && cur.sync > record.sync);
    });

    if (newerLocalData.length > 0) {
        const updateResults = await Promise.all(newerLocalData.map((cur)=>{
            return remoteWriteFunc(cur,...remoteWriteFuncArgs);
        }));
        results.remoteWrites = results.remoteWrites.concat((updateResults.map((cur)=>cur.id)));
    }

    if (newerRemoteData.length > 0) {
        const updateResults = await Promise.all(newerRemoteData.map((cur)=>{
            return localWriteFunc(cur,...localWriteFuncArgs);
        }));
        results.localWrites = results.localWrites.concat((updateResults.map((cur)=>cur.id)));
    }

    const equalRecords = recordsEqualByField(remoteData,localData);
    console.log("equal records: ", equalRecords);
    results.syncsEqual = results.syncsEqual.concat(equalRecords.map((cur)=>cur.id));

    return results;
}

export {syncRecordsets};