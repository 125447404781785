import * as React from 'react';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';



export default function UserCard({user,onActionAreaClick,...props}) {

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Card>
            <CardActionArea onClick={onActionAreaClick}>
                <CardHeader 
                    title={user.name || 'No Name'}  
                    titleTypographyProps={{variant: "h5"}} 
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }}>{(user.name || '').charAt(0).toUpperCase()}</Avatar>
                    }
                />
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {user.job_title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card> 
    );
}