import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Spinner from '../Spinner';
import { useQuery } from '@tanstack/react-query'

import { relationshipTypeList } from '../../models/categories';

import DetailsHeader from '../DetailsHeader';
import DetailsContent from '../DetailsContent';

import { listLabel } from '../../utils/lists';
import { Link } from 'react-router-dom';

export default function ElephantRelationships({elephant,elephants,...props}) {

    console.log(elephant);

    const {isLoading, data} = useQuery({
        queryKey: ['relationship_types'],
        queryFn: async() => {
            return {
                relationshipTypes: await relationshipTypeList()
            };
        },
        networkMode: "offlineFirst",
    });

    const lookup = elephants.reduce((acc,cur)=>{
        acc[cur.id] = cur;
        return acc;
    },{});

    return (

        <Grid container columnSpacing={2} rowSpacing={2}>
            {isLoading && <Spinner />}
            {!isLoading && <>

            {(Object.keys(elephant).length>0 && elephant.relationships.length > 0) ? (
                <>
                <Grid xs={6} sm={6}> 
                    <DetailsHeader>Elephant</DetailsHeader>    
                </Grid>
                <Grid xs={6} sm={6}> 
                    <DetailsHeader>Relationship</DetailsHeader>    
                </Grid>

                {elephant.relationships.map((relation)=>
                    <React.Fragment key={relation.id}>
                        <Grid xs={6} sm={6}> 
                        <Link style={{color:"#000"}} to={`/elephants/${relation.related_elephant_id}`}><DetailsContent>{lookup[relation.related_elephant_id].name}</DetailsContent></Link>    
                        </Grid>
                        <Grid xs={6} sm={6}> 
                            <DetailsContent>{listLabel(relation.relationship_type,data.relationshipTypes)}</DetailsContent>    
                        </Grid>
                    </React.Fragment>
                )}
                </>
            ) : (
                <Grid xs={12} sm={12}> 
                    <DetailsContent>No Relationships</DetailsContent>    
                </Grid>
            )}

            </>}
        </Grid>
    );
}