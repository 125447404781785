import { supabase } from '../lib/supabase'
import { v4 as uuidv4 } from 'uuid';
import {db} from '../db';

import {syncvalue, dateFromStorage, dateToStorage} from "../utils/datetime";
import { syncRecordsets } from '../utils/sync';


const template = () => {


    const record = {
        email: null,
        password: null,
        role: null,
        is_active: true,
        name: null,
        job_title: null,
        phone: null,
        began_working_with_elephants_at: null,
        began_working_in_facility_at: null,
        departure_date: null
    };

    return {...record};
}


const transformFromDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['began_working_with_elephants_at'] = dateFromStorage(temp['began_working_with_elephants_at']);
    temp['began_working_in_facility_at'] = dateFromStorage(temp['began_working_in_facility_at']);
    temp['departure_date'] = dateFromStorage(temp['departure_date']);
    return temp;
}

const transformToDb = (inputRecord) => {
    const temp = {...inputRecord};
    temp['began_working_with_elephants_at'] = dateToStorage(temp['began_working_with_elephants_at']);
    temp['began_working_in_facility_at'] = dateToStorage(temp['began_working_in_facility_at']);
    temp['departure_date'] = dateToStorage(temp['departure_date']);
    return {user: temp};
}

const toDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['began_working_with_elephants_at'] = dateToStorage(temp['began_working_with_elephants_at']);
    temp['began_working_in_facility_at'] = dateToStorage(temp['began_working_in_facility_at']);
    temp['departure_date'] = dateToStorage(temp['departure_date']);
    return temp;
}

const fromDexie = (inputRecord) => {
    const temp = {...inputRecord};
    temp['began_working_with_elephants_at'] = dateFromStorage(temp['began_working_with_elephants_at']);
    temp['began_working_in_facility_at'] = dateFromStorage(temp['began_working_in_facility_at']);
    temp['departure_date'] = dateFromStorage(temp['departure_date']);
    return temp;
}

const createUser = async (inputRecord, facilityId, session) => {
    try {
        const {email,password, name} = inputRecord;

        console.log(facilityId);

        const createResult = await supabase.auth.signUp({
            email,
            password,
            options: {
                data: {facility_id: facilityId, name}
            }
        });
        if (createResult.error) throw new Error(`${createResult.error.code}: ${createResult.error.message}`);
        console.log(createResult);

        const sessionResult = await supabase.auth.setSession(session);
        const userResult = await supabase.auth.getUser();
        console.log("session result: ",sessionResult);
        console.log("user result: ",  userResult);

        return createResult;

    } catch (error) {
        console.error(error);
        throw error;
    }

};

const newUser = () =>  {
    return template();
};

const listUsers = async() => {
    try {

        const temp = await db().users.toCollection().toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const listUsersBySync = async(lastSync) => {
    try {

        const temp = await db().users.where('sync').aboveOrEqual(lastSync).toArray();
        return temp.map((cur)=>{
            return fromDexie(cur);
        });

    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchUsers = async() => {
    try {
        const fetchResults = await supabase
            .from('profiles')
            .select('*')
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const fetchUsersBySync = async(lastSync) => {
    try {
        const fetchResults = await supabase
            .from('profiles')
            .select('*')
            .gte('sync',lastSync)
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return fetchResults.data.map((cur)=>{
                return transformFromDb(cur);
            });
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const getUser = async(id) => {
    try {

        const temp = await db().users.where('id').equals(id).first();
        console.log(temp);
        return fromDexie(temp);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const fetchUser = async(id) => {

    try {
        const fetchResults = await supabase
            .from('profiles')
            .select('*')
            .eq('id', id);
        console.log(fetchResults);
        if (fetchResults.error) throw new Error(`Error ${fetchResults.error.code}: ${fetchResults.error.message}`);
        if (fetchResults.data !== null) {
            return transformFromDb(fetchResults.data[0]);
        }
  } catch (error) {
        console.error(error);
        throw error
  }
}

const writeUserToDexie = async(inputRecord) => {
    try {
        const cleanRecord = toDexie(inputRecord);
        const id = await db().users.put(cleanRecord)
        console.log('wrote id: ', id);
        return id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const writeUserToSupabase = async(inputRecord, facilityId) => {
    try {
        const {user} = transformToDb(inputRecord);

        if (user.id) {

            user.updated_at = new Date();
            user.facility_id = facilityId;

            console.log("updating user: ", user);
            const userResult = await supabase.from("profiles").update(user).eq("id",user.id);
            console.log(userResult);
            if (userResult.error) throw new Error(`Error ${userResult.error.code}: ${userResult.error.message}`);
            return {userResult, id: user.id};
        } else {

            user.id = uuidv4();
            user.facility_id = facilityId;

            // this is actually different on a new user.
            const userResult = await supabase.from("profiles").insert(user);
            if (userResult.error) throw new Error(`Error ${userResult.error.code}: ${userResult.error.message}`);
            return {userResult, id: user.id};
        }


    } catch (error) {
        console.error(error);
        throw error;
    }
}

const saveUser = async(inputRecord, facilityId, isOnline) => {
    const syncValue = syncvalue();

    inputRecord.id = inputRecord.id || uuidv4();
    inputRecord.facility_id = facilityId;
    inputRecord.sync = syncValue;

    const dexieResults = await writeUserToDexie(inputRecord);
    const supabaseResults = (isOnline) ? await writeUserToSupabase(inputRecord,facilityId) : {status: "offline"};

    return {dexieResults, supabaseResults, id: inputRecord.id};
}

const sync = async({facilityId, lastSync = null}) => {
    const {remoteData, localData} = (lastSync) ? await syncPartialRecordSets(lastSync) : await syncFullRecordSets();
    const results = await syncRecordsets({
        remoteData,
        localData, 
        remoteWriteFunc: writeUserToSupabase, 
        localWriteFunc: writeUserToDexie, 
        remoteWriteFuncArgs: [facilityId]});
    return results;

}

const syncFullRecordSets = async (facilityId) => {
    const remoteData = await fetchUsers() || [];
    const localData = await listUsers() || [];

    return {remoteData, localData};
};

const syncPartialRecordSets = async (lastSync) => {

    const remoteData = await fetchUsersBySync(lastSync) || [];
    const localData = await listUsersBySync(lastSync) || [];

    return {remoteData, localData};
};

const truncateDexie = async () => {
    return await db().users.clear();
}

export {createUser, newUser, listUsers, getUser, fetchUsers, fetchUser, saveUser, sync, truncateDexie};