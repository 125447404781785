import React from "react";
import SyncIcon from "@mui/icons-material/Sync";
import { useSync } from "../context/SyncingProvider";

export default function SyncLoader() {
    const { isSyncing, message } = useSync();

  return (
    isSyncing && (
      <div className="sync-loader">
        <SyncIcon
          sx={{
            animation: "spin 2s linear infinite",
            "@keyframes spin": {
              "0%": {
                transform: "rotate(360deg)",
              },
              "100%": {
                transform: "rotate(0deg)",
              },
            },
          }}
          fontSize="large"
        />
        <label style={{ fontSize: 14 }}>{message}</label>
      </div>
    )
  );
}
