import * as React from 'react';

import {useForm} from './FormProvider';

import FormControl from '@mui/material/FormControl';

import { Controller } from "react-hook-form"

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



export default function FormDatePicker({name,label,required,rules = {},...props}) {

    const {control, defaultRules, watch } = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    const watchValue = watch(name);

    return (
        <Controller
            render={({field})=><LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker label={label} value={watchValue} {...field} />
              </FormControl>
            </LocalizationProvider>}
            name={name}
            control={control}
            rules={rules}
        />
    );
};