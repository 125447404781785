import * as React from 'react';

import {useForm} from './FormProvider';

import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';

import { Controller } from "react-hook-form"


export default function FormDropDownAndUsed({name,shrink=undefined,autoFocus=false,defaultSelectedValue,label,list,usedList,required,rules = {},menuItemProps = {},fullWidth,disableUsed=true,...props}) {

    const {control, errors, defaultRules } = useForm();

    const requiredDefaultRule = (required) ? {required: defaultRules['required']} : {};
    rules = {...requiredDefaultRule,...rules};

    fullWidth = (fullWidth === undefined) ? true : fullWidth;

    usedList = usedList || [];

    const labelId = name + "-label";
    return (
        <Controller
            render={({field: {onChange, value, onBlur}}) =>(
                <FormControl fullWidth={fullWidth} error={(errors[name]) ? true : false}>
                    <InputLabel shrink id={labelId} required={required}>{label}</InputLabel>
                    <Select
                        onBlur={onBlur}
                        labelId={labelId}
                        label={label}
                        required={required}
                        value={defaultSelectedValue}
                        autoFocus={autoFocus}
                        onChange={(event)=>{
                            onChange(event.target.value);
                        }}
                        {...props}
                    >
                        {list.map((cur)=>{
                            const val = cur.value || cur.label;
                            const isDisabled = ('disabled' in cur) ? cur.disabled : false;

                            if (usedList.includes(val)) {
                                return (
                                    <MenuItem key={val} value={val} {...menuItemProps} disabled={disableUsed}><div style={{display:"flex",justifyContent:"space-between", flex:1}}>{cur.label}<CheckIcon /></div></MenuItem>
                                );
                            } else {
                                return (
                                    <MenuItem key={val} value={val} disabled={isDisabled} {...menuItemProps}>{cur.label}</MenuItem>
                                );
                            }
                        })}
                    </Select>
                    {errors[name] && <FormHelperText>{errors[name]?.message}</FormHelperText>}
                </FormControl>
            )}
            name={name}
            control={control}
            rules={rules}
        />
    );
};