import * as React from 'react';
import {useForm} from './FormProvider';

export default function FormConditionCheck({name,value,...props}) {

    const {watch} = useForm();

    const watchedValue = watch(name);

    return (
        <>
        {(watchedValue === value) && <>{props.children}</>}
        </>
    );
};