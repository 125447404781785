import * as React from 'react';

import { useConnectivity } from '../context/ConnectivityProvider';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';


import Header from "../components/Header";

import {listChanges } from '../models/changes';
import {listAssessments} from '../models/assessments';
import { listFinalEvaluations } from '../models/finalevaluation';
import {listElephants} from '../models/elephants';
import {getFacility} from '../models/facility';
import {listUsers} from '../models/users';
import {listSectionAs} from '../models/sectiona';
import {listSectionBs} from '../models/sectionb';
import { jsonToStorage } from '../utils/utils';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { today } from '../utils/datetime';
import { useSync } from '../context/SyncingProvider';
import { useMessage } from '../context/MessageProvider';
import { useAuth } from '../context/AuthProvider';

/*
// if you need to load data, you can use this template, note that the return value needs to be an object with named records in it.
export async function loader() {
    return {objectname: {}};
}
*/


export default function SyncPage({title = "Sync",...props}) {
    const { setSuccess, setError, setMessage} = useMessage();
    const {isOnline} = useConnectivity();
    const { performSync, isSyncing } = useSync();
    const { user } = useAuth();

    const handleSyncClick = async () => {
        if (isOnline) {
            try {
                await performSync();
                setSuccess("Sync completed successfully!");
            } catch (error) {
                // Handle error if needed
                console.error(error);
                setError("Sync failed. Please try again.");
            }
        } else {
            setMessage("Sync is disabled while offline");
        }
    };

    const handleFullSyncClick = async () => {
        if (isOnline) {
            try {
                await performSync(true);
                setSuccess("Sync completed successfully!");
            } catch (error) {
                // Handle error if needed
                console.error(error);
                setError("Sync failed. Please try again.");
            }
        } else {
            setMessage("Sync is disabled while offline");
        }
    };
const onUploadClick = async () => {

 try{
    let elephants = await listElephants();
    let users = await listUsers();
    let assessments = await listAssessments();
    let finalEvaluations = await listFinalEvaluations();
    let changes = await listChanges();
    let facility = await getFacility();
    let sectionA = await listSectionAs();
    let sectionB = await listSectionBs();
    let currentUserName = users.find((cur) => cur.id === user?.id)?.name
    let data = {
      date: today(),
      elephants,
      users,
      assessments,
      finalEvaluations,
      changes,
      facility,
      sectionA,
      sectionB,
    };
    let jsonString=JSON.stringify(data);
    await jsonToStorage(`${facility.name}/${currentUserName}/${today().format('YYYY/MM/DD/YYYYMMDD-HHmmss')}.json`,jsonString);
    setSuccess("Data uploaded to DB");
 }
 catch(e){
    console.error(e);
    setError("Error uploading data to DB");
 }
};

    // note you can play with the container maxwidth to spread out the window content appears in
    return (
        <Container component="main" maxWidth="sm">
            <Header label={title} />

            {isOnline && 
                <Grid container spacing={2}>
                    <Grid xs={12}> 
                        <Button fullWidth variant="contained" onClick={handleSyncClick} disabled={isSyncing}>Sync from last sync point</Button>
                    </Grid>
                    <Grid xs={12}> 
                        <Button fullWidth variant="contained" onClick={handleFullSyncClick} disabled={isSyncing}>Full Sync</Button>
                    </Grid>
                    <Grid xs={12}> 
                        <Button fullWidth variant="contained" onClick={onUploadClick}>Upload data to DB</Button>
                    </Grid>
                </Grid>
            }
            {!isOnline && <Typography>Currenly Offline</Typography>}
        </Container>
    );
}