import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export default function BehaviorChips({ onChipPress, words=[] }) {
  const rows = [[], [], []]; 
  words.forEach((word, index) => {
    const rowIndex = index % 3;
    rows[rowIndex].push(word);
  });
  

  return (
    words.length > 0 &&
    <Box sx={{maxWidth: '75vw', pl:1, pr:1, pt:2, overflowX: 'auto', display: 'flex', flexDirection: 'column', gap: '8px' }}>
    {rows.map((row, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          
          whiteSpace: 'nowrap',
          flexShrink: 0, 
          width: '100%', 
          '&::-webkit-scrollbar': {
            height: '6px' 
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)', 
            borderRadius: '10px' 
          }
        }}
      >
        {row.map((word, wordIndex) => (
          <Chip
            key={wordIndex}
            label={word}
            
            onClick={() => onChipPress(word)}
            sx={{ cursor: 'pointer' }}
          />
        ))}
      </Box>
    ))}
  </Box>
  );
}

