
import Spinner from "./Spinner";
import Typography from "@mui/material/Typography";

import { useQuery } from '@tanstack/react-query'
import {categoryList, typeList, statusList} from '../models/categories';
import { listLabel } from '../utils/lists';
import { dateLocaleFormat } from "../utils/datetime";
import Box from "@mui/material/Box";

import { allOpenElephantChanges, sortDescStart } from "../utils/changes";

export default function ElephantChangeSummary({elephant, changes = [], containerProps = {},typographyProps = {},...props}) {

    const {isLoading, data} = useQuery({
        queryKey: ['categories'],
        queryFn: async() => {
            return {
                categories: await categoryList(),
                types: await typeList(),
                statuses: await statusList(),
            };
        },
        networkMode: "offlineFirst",
    });

    const openChangesForElephant = allOpenElephantChanges(elephant,changes);
    openChangesForElephant.sort(sortDescStart);

    return (
    <Box {...containerProps}>
        {isLoading && <Spinner />}
        {!isLoading && <>
            <Typography {...typographyProps}>Ongoing Changes: {openChangesForElephant.length}</Typography>
            {openChangesForElephant.length > 0 && 
            <Typography {...typographyProps}>Last Change: {listLabel(openChangesForElephant[0].category,(data?.categories || []))} {openChangesForElephant[0].start.format(dateLocaleFormat())}</Typography>
        }
        </>}
    </Box>
    );
}