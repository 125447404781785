import * as React from 'react';

import { useNavigate} from 'react-router-dom';

import { listElephants } from "../models/elephants";
import { listAssessments } from "../models/assessments";
import { listChanges } from "../models/changes";

import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Container from '@mui/material/Container';

import SmallCardGrid from '../components/SmallCardGrid';
import BigCardGrid from '../components/BigCardGrid';
import AssessmentCard from "../components/cards/AssessmentCard";
import ChangeSummaryCard from '../components/cards/ChangeSummaryCard';

import Subheader from "../components/Subheader";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { isInProgress, getElephantLastAssessments } from '../utils/assessments';
import LastAssessmentAt from '../components/LastAssessmentAt';

import { allOpenChanges,sortAscStart } from '../utils/changes';

import { useQuery } from '@tanstack/react-query'

import CustomLink from '../components/CustomLink';

export default function IndexPage() {

	const navigate = useNavigate();

    const {data} = useQuery({
        queryKey: ['index'],
        queryFn: async() => {
            return {
				changes: await listChanges(),
				assessments: await listAssessments(),
                elephants: await listElephants(),
            };
        },
        networkMode: "offlineFirst",
    });

	const { elephants, assessments, changes} = data || {elephants: [], assessments: [], changes: []};


	const elephantLookup = elephants.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
    }, {});


	const elephantLastAssessments = getElephantLastAssessments(elephants,assessments);


	const openChanges = allOpenChanges(changes);
	openChanges.sort(sortAscStart);

	const activeAssessments = assessments.filter((cur)=>{
		return isInProgress(cur);
	});

	activeAssessments.sort((a,b)=>{
		return a.started_on - b.started_on;
	})

	return (
	<Container component="main">
		<Subheader label="Ongoing Changes" />
        <Grid container spacing={2}>
			{openChanges.map((change)=>{
				return (
					<SmallCardGrid key={change.id}>
						<ChangeSummaryCard change={change} elephantLookup={elephantLookup} onActionAreaClick={()=>{navigate("/changes/" + change.id);}} />
					</SmallCardGrid>
				)
			})}
		</Grid>
		<Subheader label="Last Assessment Done" />
		<TableContainer component={Paper} sx={{maxWidth: 'sm'}}>
			<Table aria-label="simple table">
				<TableHead>
				<TableRow>
					<TableCell>Elephant Name</TableCell>
					<TableCell align="right">Assessment Date</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{Object.keys(elephantLastAssessments).map((key) => {
					const row = elephantLastAssessments[key];
					return (
						<TableRow
						key={row.elephant.id}
						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
						<TableCell component="th" scope="row">
							<CustomLink to={"/elephants/"+row.elephant.id}><Typography sx={{color: "black"}}>{row.elephant.name}</Typography></CustomLink>
						</TableCell>
						<TableCell align="right">
							{row?.assessment?.id && 
								<CustomLink to={"/assessments/"+row.assessment.id}><LastAssessmentAt assessment={row.assessment} elephant={row.elephant} inProgressLabelTemplate="In Progress Since %s" /></CustomLink>
							}
							{!row?.assessment?.id && 
								<LastAssessmentAt assessment={row.assessment} elephant={row.elephant} inProgressLabelTemplate="In Progress Since %s" />
							}
						</TableCell>
						</TableRow>
					);
				})}
				</TableBody>
			</Table>
		</TableContainer>


		<Subheader label="In Progress Assessments" />
        <Grid container spacing={2}>
		{activeAssessments.map((assessment)=>{
			return (
				<BigCardGrid key={assessment.id}>
					<AssessmentCard
						elephant={elephants.find((el) => el.id === assessment.elephant_id)}
						assessment={assessment}
						changes={changes}
						onActionAreaClick={() => {
							navigate("/assessments/" + assessment.id);
						}}
					/>
				</BigCardGrid>
			)
		})}
		</Grid>
	</Container>
	);
}